/**
 * selects invitation provider id
 */
export const invProviderIdSelector = ({ persistedData: { auth } }) =>
  auth.invProviderId;

/**
 * selects a logged user token
 */
export const tokenSelector = ({ persistedData: { auth } }) => auth.token;

/**
 * selects a logged user token get time
 */
export const tokenTimeSelector = ({ persistedData: { auth } }) => auth.time;

/**
 * selects is authenticated
 */
export const isAuthenticatedSelector = ({ persistedData: { auth } }) =>
  Boolean(auth.token);

/**
 * selects a temporary user token
 */
export const temporaryTokenSelector = ({ persistedData: { auth } }) =>
  auth.temporaryToken;

/**
 * selects is MFA needed
 */
export const isTemporaryTokenSelector = ({ persistedData: { auth } }) =>
  Boolean(auth.temporaryToken);
