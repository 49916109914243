import { SHOW_OVERFLOW, HIDE_OVERFLOW } from 'redux/consts/overflow';
import { CLIENT_SIDE_LOGOUT } from 'redux/consts/auth';

const initialState = {
  shown: false,
};

const overflowReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_OVERFLOW: {
      return { ...state, shown: true };
    }

    case HIDE_OVERFLOW:
    case CLIENT_SIDE_LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};

export default overflowReducer;
