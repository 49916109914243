import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import { useDispatch } from 'react-redux';

import { showDialog } from 'redux/actions/dialog';
import { confirmAppointment } from 'redux/actions/schedules';
import { CONFIRMATION_DIALOG } from 'config/Dialog/consts';
import Switch from 'components/Switch';

import styles from './styles.module.scss';

function SchedulesList({ schedules, onScheduleChange }) {
  const dispatch = useDispatch();

  const handleSwitch = (scheduleId) => {
    const alertCfg = {
      title: `Confirm appointment`,
      description: `Your appointment will be recorded as confirmed and clinician notified.`,
      actions: [
        {
          label: `Confirm`,
          type: 'primary',
          isCancel: true,
          action: () =>
            dispatch(confirmAppointment(scheduleId)).then(() =>
              onScheduleChange(scheduleId)
            ),
        },
        {
          label: `Cancel`,
          isCancel: true,
          type: 'secondary',
        },
      ],
    };
    dispatch(showDialog(CONFIRMATION_DIALOG, alertCfg));
  };

  return (
    <>
      {schedules.map(({ date, items }, index) => (
        <div className={styles.item} key={index}>
          <div className={styles.title}>
            {moment(date).calendar(null, {
              sameDay: '[Today]',
              nextDay: '[Tomorrow]',
              nextWeek: 'ddd, MMM D',
              lastDay: 'ddd, MMM D',
              lastWeek: 'ddd, MMM D',
              sameElse: 'ddd, MMM D',
            })}
          </div>
          {items.map(
            (
              {
                scheduleId,
                clinicianFirstName,
                clinicianLastName,
                scheduleDate,
                confirmedFlag,
              },
              index
            ) => (
              <div key={index} className={styles.body}>
                <div className={styles.clinicianName}>
                  {`${clinicianFirstName} ${clinicianLastName[0]}.`}
                </div>
                <div className={styles.time}>
                  {moment(scheduleDate).format('h:mm A')}
                </div>
                <div className={styles.action}>
                  <Switch
                    className={cn(confirmedFlag && styles.disabled)}
                    value={confirmedFlag || false}
                    onChange={() => handleSwitch(scheduleId)}
                  />
                </div>
              </div>
            )
          )}
        </div>
      ))}
    </>
  );
}

SchedulesList.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onScheduleChange: PropTypes.func.isRequired,
};

export default React.memo(SchedulesList);
