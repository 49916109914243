import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { isTemporaryTokenSelector } from 'redux/selectors/auth';

const MFARoute = ({ isMFANeeded, ...rest }) => {
  if (!isMFANeeded) {
    return <Redirect to="/login" />;
  }
  return <Route {...rest} />;
};

MFARoute.propTypes = {
  isMFANeeded: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isMFANeeded: isTemporaryTokenSelector(state),
});

export default connect(mapStateToProps)(MFARoute);
