import { getPatientProviderId } from 'utils';

export const extractPatientProviders = (payload) => {
  const { patientInvitations, providers } = payload;

  const getPatientFName = (pi) => pi.patient.firstName;
  const getPatientLName = (pi) => pi.patient.lastName;
  const getProviderName = (pi) =>
    providers.find((p) => p.id === pi.providerId).name;

  const result = patientInvitations
    .filter((i) => i.invitation.status === 'APPROVED')
    .sort((a, b) => {
      const compareFname = getPatientFName(a).localeCompare(getPatientFName(b));
      const compareLname = getPatientLName(a).localeCompare(getPatientLName(b));
      const compareProviderName = getProviderName(a).localeCompare(
        getProviderName(b)
      );
      return compareFname || compareLname || compareProviderName;
    })
    .map((i) => ({
      patient: i.patient,
      providerId: i.providerId,
    }))
    .map((pp) => ({ ...pp, patientProviderId: getPatientProviderId(pp) }));
  return result;
};
