import React from 'react';
import PropTypes from 'prop-types';

import arrowRightImg from './img/arrow-right.png';
import arrowLeftImg from './img/arrow-left.png';
import styles from './styles.module.scss';

const iconCfg = {
  forward: {
    src: arrowRightImg,
    className: styles.arrowIconRight,
  },
  back: {
    src: arrowLeftImg,
    className: styles.arrowIconLeft,
  },
};

class Icon extends React.PureComponent {
  render() {
    const { icon } = this.props;
    const iconProps = iconCfg[icon];
    if (!iconProps) return null;
    return <img {...iconProps} alt={icon} />;
  }
}

Icon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(iconCfg)),
};

export default Icon;
