import { GET_DEVICE_ID } from 'redux/consts/settings';

const initialState = {
  deviceId: null,
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DEVICE_ID:
      return { ...state, deviceId: action.deviceId };

    default:
      return state;
  }
};

export default settingsReducer;
