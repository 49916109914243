import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import LoadingIndicator from './LoadingIndicator';
import Icon from './Icon';

import styles from './styles.module.scss';

class Button extends React.PureComponent {
  render() {
    const {
      text,
      className,
      loaderClassName,
      icon,
      disabled,
      loading,
      secondary,
      ...rest
    } = this.props;
    const Component = rest.to ? Link : React.createElement('button').type;
    const props = { ...rest };
    props.onClick && (disabled || loading) && (props.onClick = () => {});
    return (
      <Component
        className={cn(
          styles.button,
          disabled && styles.disabled,
          secondary && styles.secondary,
          'forward' === icon && styles.left,
          'back' === icon && styles.right,
          className
        )}
        {...props}
      >
        {loading ? <LoadingIndicator className={loaderClassName}/> : text}
        <Icon icon={icon} />
      </Component>
    );
  }
}

Button.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([Link.propTypes.to]),
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  loaderClassName: PropTypes.string,
  icon: Icon.propTypes.icon,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  secondary: PropTypes.bool,
};

export default Button;
