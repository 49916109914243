import { connect } from 'react-redux';
import { parse } from 'query-string';

import { onResetPasswordRequest } from 'redux/actions/resetPassword';
import { IS_RECAPTCHA_OPTIONAL } from 'consts';

import ForgetPasswordPage from './ForgetPasswordPage';

const mapStateToProps = ({ router }) => {
  const { email, captchaDisabled } = parse(router.location.search);
  const disabled =
    captchaDisabled && JSON.parse(captchaDisabled) ? true : false;
  return {
    email,
    isDisabled: Boolean(disabled && IS_RECAPTCHA_OPTIONAL),
  };
};

const connectStore = connect(
  mapStateToProps,
  { onResetPassword: onResetPasswordRequest }
);
export default connectStore(ForgetPasswordPage);
