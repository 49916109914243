import React from 'react';

import bills from './img/bills.svg';

import QuickLinks from './QuickLinks';

const links = [
  {
    icon: bills,
    name: 'Bills',
    to: `/bills`,
  },
];

const QuickLinksContainer = () => {
  return <QuickLinks items={links} />;
};

export default QuickLinksContainer;
