import React from 'react';
import ReactDOM from 'react-dom';
import promiseFinally from 'promise.prototype.finally';
import 'scroll-restoration-polyfill';

import App from 'config/App';

promiseFinally.shim();

window.history.scrollRestoration = 'manual';

ReactDOM.render(<App />, document.getElementById('root'));
