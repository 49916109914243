import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import { hasPendingRequestsSelector } from 'redux/selectors/patientInvitations';

import UserIcon from './UserIcon';

function UserIconContainer(props) {
  const hasPendingRequests = useSelector(
    hasPendingRequestsSelector,
    shallowEqual
  );

  return <UserIcon {...props} hasPendingRequests={hasPendingRequests} />;
}

export default React.memo(UserIconContainer);
