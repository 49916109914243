import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';

import {
  DOWNLOAD_PDF_REQUEST,
  DOWNLOAD_PDF_SUCCESS,
  DOWNLOAD_PDF_FAILURE,
} from 'redux/consts/downloadPdf';

export function downloadPdf(mode, html) {
  return {
    type: DOWNLOAD_PDF_REQUEST,
    html,
    mode,
    [WAIT_FOR_ACTION]: DOWNLOAD_PDF_SUCCESS,
    [ERROR_ACTION]: DOWNLOAD_PDF_FAILURE,
  };
}

export function onDownloadPdfSuccess({ payload }) {
  return {
    type: DOWNLOAD_PDF_SUCCESS,
    payload,
  };
}

export function onDownloadPdfFailure({ error }) {
  return {
    type: DOWNLOAD_PDF_FAILURE,
    error,
  };
}
