import { INITIAL_DATA_SUCCESS } from 'redux/consts/initialData';

const initialState = {};

/**
 *
 * @param {*} state
 * @param {*} action
 * @returns {{[providerId:string] : provider[]}
 */
const providersReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_DATA_SUCCESS: {
      const {
        payload: { providers },
      } = action;
      return providers.reduce((acc, p) => ({ ...acc, [p.id]: p }), {});
    }

    default:
      return state;
  }
};

export default providersReducer;
