import { SHOW_DIALOG, HIDE_DIALOG } from 'redux/consts/dialog';
import { CLIENT_SIDE_LOGOUT } from 'redux/consts/auth';

const initialState = {
  route: null,
  props: null,
};

const dialogReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_DIALOG: {
      const { route, props } = action;
      return { ...state, route, props };
    }

    case HIDE_DIALOG:
    case CLIENT_SIDE_LOGOUT: {
      return initialState;
    }

    default:
      return state;
  }
};

export default dialogReducer;
