import React from 'react';
import PropTypes from 'prop-types';

import SchedulesList from './SchedulesList';
import HidableSchedules from './HidableSchedules';

import styles from './styles.module.scss';

function PatientSchedules({ schedules, onScheduleChange, isMobile }) {
  const Component = isMobile ? HidableSchedules : SchedulesList;

  if (schedules.length === 0) {
    return null;
  }
  return (
    <div id="schedules" className={styles.wrapper}>
      {!isMobile && <div className={styles.header}>Appointments</div>}
      <Component schedules={schedules} onScheduleChange={onScheduleChange} />
    </div>
  );
}

PatientSchedules.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  onScheduleChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
};

export default React.memo(PatientSchedules);
