export const CANCEL_PENDING_PROVIDERS_REQUEST =
  'CANCEL_PENDING_PROVIDERS_REQUEST';
export const CANCEL_PENDING_PROVIDERS_SUCCESS =
  'CANCEL_PENDING_PROVIDERS_SUCCESS';
export const CANCEL_PENDING_PROVIDERS_FAILURE =
  'CANCEL_PENDING_PROVIDERS_FAILURE';

export const CONFIRM_INFORMATION_REQUEST = ' CONFIRM_INFORMATION_REQUEST';
export const CONFIRM_INFORMATION_SUCCESS = ' CONFIRM_INFORMATION_SUCCESS';
export const CONFIRM_INFORMATION_FAILURE = ' CONFIRM_INFORMATION_FAILURE';
