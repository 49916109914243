import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import TextField from 'components/TextField';
import ErrorBar from 'components/ErrorBar';
import AuthLayout from 'layouts/AuthLayout';
import FormWrapper from 'layouts/AuthLayout/FormWrapper';
import Button from 'components/Button';
import Recaptcha from 'components/Recaptcha';
import { scrollToError } from 'utils';

import styles from './styles.module.scss';

const getFormErrors = ({ password }) => {
  const result = {};
  if (password.length === 0) {
    result['password'] = 'Password is required';
  } else if (password.search(/\s/) !== -1) {
    result['password'] = 'Password cannot have spaces';
  } else if (password.length < 8) {
    result['password'] = 'Password must be between 8 and 25 characters';
  } else if (password.search(/[a-zA-Z]/) === -1) {
    result['password'] = 'Password must have at least one letter';
  } else if (password.search(/\d/) === -1) {
    result['password'] = 'Password must have at least one number';
  }
  return Object.keys(result).length === 0 ? undefined : result;
};

const validationErrors = {
  INVALID: `Reset request invalid. Please submit another.`,
  EXPIRED: `Reset request expired. Please submit another.`,
};

class ResetPasswordPage extends React.PureComponent {
  state = {
    form: {
      password: '',
    },
    loading: false,
    errors: {},
    success: false,
  };

  recaptchaRef = React.createRef();

  addError = (name, message) =>
    this.setState((state) => ({
      ...state,
      errors: { ...state.errors, [name]: message },
    }));

  onInputChange = (name) => ({ target: { value } }) =>
    this.setState((state) => ({
      ...state,
      form: { ...state.form, [name]: value },
      errors: { ...state.errors, [name]: undefined },
    }));

  executeRecaptcha = () => {
    this.recaptchaRef.current.execute();
  };

  executeRecoveryPassword = (recaptcha) => {
    const {
      email,
      recoveryToken,
      isDisabled,
      onRecoveryPassword,
      backLogin,
    } = this.props;
    const { form } = this.state;
    return onRecoveryPassword({
      ...form,
      email,
      recoveryToken,
      recaptcha,
      captchaDisabled: isDisabled,
    })
      .then((data) => {
        this.setState({ success: true });
        return data;
      })
      .catch((e) => {
        if (e.details) {
          const { cause } = e.details;
          if (['INVALID', 'EXPIRED'].includes(cause)) {
            return backLogin({
              prevPath: '/recovery-password',
              message: validationErrors[cause],
            });
          }
        }
        this.addError('form', e.message);
      })
      .finally(() => this.setState({ loading: false }));
  };

  onVerifyCaptcha = (recaptcha) => {
    this.setState({ loading: true });
    return this.executeRecoveryPassword(recaptcha);
  };

  onSaveChanges = () => {
    const { isDisabled } = this.props;
    const { form } = this.state;
    const errors = getFormErrors(form);
    if (errors) {
      const [firstError] = Object.keys(errors);
      scrollToError(firstError);
      return this.setState({ errors });
    }
    this.setState({ errors: {}, loading: true });
    if (isDisabled) {
      return this.executeRecoveryPassword();
    }
    this.executeRecaptcha();
  };

  handleEnterPress = ({ key }) => key === 'Enter' && this.onSaveChanges();

  render() {
    const { isDisabled } = this.props;
    const { form, errors, success, loading } = this.state;
    const { password } = form;

    if (success) {
      return <Redirect to="/login" />;
    }

    return (
      <AuthLayout
        title="Reset Password"
        note="Reset your password to access the therapyBOSS patient portal."
      >
        <FormWrapper>
          <div className={styles.note}>
            Choose your password.
            {Boolean(errors.form) && (
              <ErrorBar
                name="form"
                className={styles.errorBar}
                error={errors.form}
              />
            )}
          </div>

          <div>
            <TextField
              name="password"
              value={password}
              onChange={this.onInputChange('password')}
              onKeyDown={this.handleEnterPress}
              error={errors['password']}
              label="Password"
              placeholder="Type your desired password"
              className={styles.input}
              type="password"
              maxLength={25}
            />
          </div>
        </FormWrapper>
        <div className={styles.buttons}>
          <Button
            loading={loading}
            text="Proceed"
            onClick={this.onSaveChanges}
          />
        </div>
        {!isDisabled && (
          <Recaptcha ref={this.recaptchaRef} onVerify={this.onVerifyCaptcha} />
        )}
      </AuthLayout>
    );
  }
}

ResetPasswordPage.propTypes = {
  email: PropTypes.string.isRequired,
  recoveryToken: PropTypes.string.isRequired,
  onRecoveryPassword: PropTypes.func.isRequired,
  backLogin: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};

export default ResetPasswordPage;
