import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const disableStylesRaw = `
  *[href], button, img {
    pointer-events: none !important;
    display: inline-block !important;
  }
`;

class SafesrcDocIframe extends Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    title: PropTypes.string,
    srcDoc: PropTypes.string.isRequired,
    sandbox: PropTypes.string,
    referrerPolicy: PropTypes.string,
    src: PropTypes.string,
    dataTestName: PropTypes.string.isRequired,
    getParsedHTML: PropTypes.func,
  };

  static defaultProps = {
    // set all restrictions for sandbox except same origin
    // to allow us to inject the safe guards.
    sandbox: 'allow-same-origin allow-scripts',
    referrerPolicy: 'no-referrer',
    // will be omitted from props passed to the iframe
    src: '',
  };

  constructor(...args) {
    super(...args);
    this.iframeElement = null;
    this.wrapperElement = null;
    this.disableStyleTag = document.createElement('style');
    this.disableStylesTextNode = document.createTextNode(disableStylesRaw);
    this.disableStyleTag.appendChild(this.disableStylesTextNode);
    this.state = {
      wrapperHeight: 0,
      startWidth: this.props.width,
      startHeight: 0,
      scale: 1,
    };
  }

  componentDidMount() {
    const { getParsedHTML } = this.props;
    if (this.wrapperElement && this.iframeElement) {
      const doResize = () => {
        const scale = this.wrapperElement.clientWidth / this.state.startWidth;
        this.setState({
          scale,
          wrapperHeight: this.state.startHeight * scale,
        });
      };
      window.onresize = doResize;
      this.iframeElement.onload = () => {
        const parsedHTML = this.iframeElement.contentWindow.document.querySelector(
          'html'
        );

        const contentElementHeight = parsedHTML.scrollHeight;
        const frameHeight = contentElementHeight + 48;
        this.applySafeguards();
        this.setState({
          startHeight: frameHeight,
          wrapperHeight: frameHeight,
        });
        doResize();
        if (getParsedHTML) {
          return getParsedHTML(parsedHTML.innerHTML);
        }
      };
    }
  }

  componentWillUnmount() {
    this.disableStylesTextNode = null;
    this.disableStyleTag = null;
  }

  applySafeguards() {
    if (!this.iframeElement.contentDocument) {
      return;
    }
    const [
      iframeBody,
    ] = this.iframeElement.contentDocument.getElementsByTagName('body');
    if (iframeBody) {
      // add safety guards last to ensure they are always applied.
      iframeBody.appendChild(this.disableStyleTag);
    }
  }

  render() {
    const {
      width,
      title,
      referrerPolicy,
      dataTestName,
      sandbox,
      srcDoc,
      src: omit /* eslint-disable-line no-unused-vars */,
    } = this.props;
    const { scale, startHeight, wrapperHeight } = this.state;
    return (
      <div
        className={styles.wrapper}
        ref={(el) => {
          this.wrapperElement = el;
        }}
        style={{ height: `${wrapperHeight}px` }}
      >
        <iframe
          id={'iframe'}
          title={title}
          srcDoc={srcDoc}
          referrerPolicy={referrerPolicy}
          sandbox={sandbox}
          data-test-id={dataTestName}
          ref={(el) => {
            this.iframeElement = el;
          }}
          style={{
            minWidth: `${width}px`,
            height: `${startHeight}px`,
            transform: `scale(${scale})`,
          }}
        />
      </div>
    );
  }
}

export default SafesrcDocIframe;
