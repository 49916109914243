import React from 'react';
import PropTypes from 'prop-types';

import Header from 'layouts/Header';

import styles from './styles.module.scss';

function MainLayout(props) {
  const { children, headerProps = {} } = props;
  return (
    <div className={styles.wrapper}>
      <Header {...headerProps} />
      <div className={styles.content}>
        <div className={styles.innerContent}>{children}</div>
      </div>
    </div>
  );
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  headerProps: PropTypes.shape(Header.propTypes),
};

export default MainLayout;
