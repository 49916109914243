import {
  AUTH_SUCCESS,
  AUTH_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  TEMPORARY_TOKEN,
  CLIENT_SIDE_LOGOUT,
} from 'redux/consts/auth';
import { GET_SIGNUP_DATA_SUCCESS } from 'redux/consts/signup';

const initialState = {
  token: null,
  temporaryToken: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SIGNUP_DATA_SUCCESS:
      return { ...state, invProviderId: action.payload.providerId };
    case AUTH_SUCCESS:
      return {
        ...state,
        token: action.authToken,
        temporaryToken: null,
        time: Date.now(),
      };

    case REFRESH_TOKEN_SUCCESS: {
      if (action.isTemporary) {
        return { ...state, temporaryToken: action.payload };
      } else {
        return { ...state, token: action.payload, time: Date.now() };
      }
    }

    case AUTH_FAILURE:
      return { ...state, token: null };

    case TEMPORARY_TOKEN:
      return { ...state, temporaryToken: action.payload };

    case CLIENT_SIDE_LOGOUT:
      return initialState;

    default:
      return state;
  }
};

export default authReducer;
