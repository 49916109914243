import { connect } from 'react-redux';

import { confirmInformationRequest } from 'redux/actions/providersRequests';

import ConfirmRequestDialog from './ConfirmRequestDialog';

const connectStore = connect(
  null,
  { onConfirmInformation: confirmInformationRequest }
);

export default connectStore(ConfirmRequestDialog);
