import { takeLatest, put } from 'redux-saga/effects';
import {
  SIGN_AGREEMENT_REQUEST,
  GET_TERMS_REQUEST,
  CHECK_PROVIDER_AGREEMENT_REQUEST,
} from 'redux/consts/agreements';
import {
  onSignAgreementSuccess,
  onGetTermsSuccess,
  onGetTermsFailure,
  onCheckProviderAgreementSuccess,
} from 'redux/actions/agreements';
import { SYSTEM_ERROR_MSG } from 'consts';

import {
  createApiSecureRequest,
  apiSecureRequest,
  combineSagas,
} from './utils';

export function* getAgreementsWorker(apiSecureRequest) {
  const url = `/user-profile/agreement`;
  const options = {
    method: 'GET',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    return payload;
  } catch {
    throw new Error(SYSTEM_ERROR_MSG);
  }
}

function* signAgreementWorker({ id }) {
  const url = `/user-profile/agreement?agreementId=${id}`;
  const options = {
    method: 'POST',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    yield put(onSignAgreementSuccess({ payload }));
    return payload;
  } catch (error) {
    if (error && error.message === 'Agreement already signed.') {
      const payload = {};
      yield put(onSignAgreementSuccess({ payload }));
      return payload;
    }
    return false;
  }
}

function* signAgreementSaga() {
  yield takeLatest(SIGN_AGREEMENT_REQUEST, signAgreementWorker);
}

function* getTermsWorker({ authToken }) {
  try {
    const apiSecureRequest = createApiSecureRequest({ token: authToken });
    const payload = yield getAgreementsWorker(apiSecureRequest);
    yield put(onGetTermsSuccess({ payload }));
    return payload;
  } catch (error) {
    yield put(onGetTermsFailure({ error }));
    return false;
  }
}

function* getTermsSaga() {
  yield takeLatest(GET_TERMS_REQUEST, getTermsWorker);
}

export function* checkProviderAgreementWorker({ providerId }) {
  try {
    const response = yield getAgreementsWorker(apiSecureRequest);
    const providerAgreement = response.find((a) => a.providerId === providerId);
    if (!providerAgreement) {
      return false;
    }
    if (
      !providerAgreement.signedOn ||
      new Date(providerAgreement.signedOn).getTime() <
        new Date(providerAgreement.agreementPublishedOn).getTime()
    ) {
      yield put(
        onCheckProviderAgreementSuccess({ payload: providerAgreement })
      );
      return providerAgreement;
    } else {
      return false;
    }
  } catch {
    throw new Error(SYSTEM_ERROR_MSG);
  }
}

function* checkProviderAgreementSaga() {
  yield takeLatest(
    CHECK_PROVIDER_AGREEMENT_REQUEST,
    checkProviderAgreementWorker
  );
}

export default combineSagas([
  signAgreementSaga,
  getTermsSaga,
  checkProviderAgreementSaga,
]);
