import React from 'react';

import styles from './styles.module.scss';

const ToastContent = ({ type, content }) => {
  if (typeof content === 'function') {
    const Component = content;
    return (
      <div className={styles.wrapper}>
        <Component className={styles.text} />
      </div>
    );
  }
  return (
    <div className={styles.wrapper}>
      <span className={styles.text}>{content}</span>
    </div>
  );
};

export default React.memo(ToastContent);
