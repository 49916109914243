import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import styles from './styles.module.scss';

/**
 * Layout for dialogs
 */

const DialogLayout = ({ config, title, children, onClose }) => {
  const { style, removeClose = false, cfgTitle, onCustomClose } = config;
  const dispatch = useDispatch();
  const onCloseHandler = () => {
    if (onCustomClose) {
      dispatch(onCustomClose());
    }
    onClose();
  };
  return (
    <div id="dialog-container" className={styles.shade}>
      <div className={styles.dialog} style={style}>
        <div className={styles.header}>
          <div className={styles.title}>{cfgTitle || title}</div>
          {!removeClose && (
            <button className={styles.closeBtn} onClick={onCloseHandler} />
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

DialogLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  config: PropTypes.object,
  /** container */
  onClose: PropTypes.func.isRequired,
};

export default DialogLayout;
