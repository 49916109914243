import {
  GET_DOCS_SUCCESS,
  GET_DOCS_FAILURE,
  VIEW_DOC_REQUEST,
} from 'redux/consts/docs';
import { LOGOUT_SUCCESS } from 'redux/consts/auth';

import { CHECK_PROVIDER_AGREEMENT_SUCCESS } from 'redux/consts/agreements';

const initialState = {};

/**
 *
 * @param {*} state
 * @param {*} action
 * @returns {{[patientProviderId:string] : doc[]}
 */
const docsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    case GET_DOCS_SUCCESS: {
      const { payload: docs, patientProviderId } = action;
      return { ...state, [patientProviderId]: docs };
    }

    case GET_DOCS_FAILURE: {
      const { patientProviderId } = action;
      return { ...state, [patientProviderId]: [] };
    }

    case CHECK_PROVIDER_AGREEMENT_SUCCESS: {
      return initialState;
    }

    case VIEW_DOC_REQUEST: {
      const { patientProviderId, note } = action;
      const providerDocs = state[patientProviderId] || [];
      return {
        ...state,
        [patientProviderId]: providerDocs.map((doc) => {
          if (doc.noteId === note.noteId && doc.type === note.type) {
            return { ...doc, viewed: true };
          }
          return doc;
        }),
      };
    }

    default:
      return state;
  }
};

export default docsReducer;
