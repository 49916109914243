import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import PopupLayout from 'layouts/PopupLayout';
import { hideDialog } from 'redux/actions/dialog';
import { hidePopup } from 'redux/actions/popup';
import { popupsSelector } from 'redux/selectors/popup';

import routes from './routes';

import styles from './styles.module.scss';

const Popup = () => {
  const dispatch = useDispatch();
  const popups = useSelector(popupsSelector);
  if (!popups) return null;

  const handleClose = (param) => {
    dispatch(hideDialog());
    dispatch(hidePopup(param));
  };

  const handleOutsideClick = () => handleClose();

  return Object.values(popups).map(({ route, props }, idx) => {
    const Component = routes[route];
    return (
      <PopupLayout onOutsideClick={handleOutsideClick} key={route}>
        <div className={styles.wrapper}>
          <div className={styles.container} style={{ zIndex: `${idx}` }}>
            <Component onPopupClose={handleClose} {...props} />
          </div>
        </div>
      </PopupLayout>
    );
  });
};

export default Popup;
