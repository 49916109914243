import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

function NameIcon({ className, text, ...rest }) {
  return (
    <div className={cn(styles.wrapper, className)} {...rest}>
      {text.toUpperCase()}
    </div>
  );
}

NameIcon.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default NameIcon;
