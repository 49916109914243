import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

class LoadingContainer extends React.PureComponent {
  render() {
    const { className } = this.props;
    return (
      <div className={cn(styles.container, className && className)}>
        <div className={styles.wrapper}>
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    );
  }
}

LoadingContainer.propTypes = {
  className: PropTypes.string,
};

export default LoadingContainer;
