import { put, call } from 'redux-saga/effects';

import { httpRequestSuccess, httpRequestError } from 'redux/actions/global';

export default function wrapWithDefaultHandlers(apiRequestFn) {
  return function*(...args) {
    try {
      const payload = yield call(apiRequestFn, ...args);
      yield put(httpRequestSuccess({ ...args, payload }));
      return payload;
    } catch (error) {
      yield put(httpRequestError({ ...args, error }));
      throw error;
    }
  };
}
