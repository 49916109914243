import { push } from 'connected-react-router';
import { stringify } from 'query-string';
import moment from 'moment';

import { take, takeLatest, put, select } from 'redux-saga/effects';
import {
  GET_DOCS_REQUEST,
  VIEW_DOC_REQUEST,
  LOAD_DOC_REQUEST,
} from 'redux/consts/docs';
import {
  onGetDocsSuccess,
  onGetDocsFailure,
  onLoadDocSuccess,
  onLoadDocFailure,
} from 'redux/actions/docs';
import { AGREEMENTS_DIALOG } from 'config/Dialog/consts';
import { SIGN_AGREEMENT_SUCCESS } from 'redux/consts/agreements';
import { showDialog } from 'redux/actions/dialog';
import { patientProviderByIdSelector } from 'redux/selectors/patientProviders';
import { providerInfoSelector } from 'redux/selectors/providerInfo';

import { apiSecureRequest, combineSagas } from './utils';
import { checkProviderAgreementWorker } from './agreements';

const convertDates = (docs) =>
  docs.map((d) => {
    if (d.type === 'CA') {
      const utcDate = moment.utc(d.noteDate).format();
      const noteDate = moment
        .utc(utcDate)
        .local()
        .format();
      return { ...d, noteDate };
    }
    return d;
  });

function* getDocsWorker({ patientProviderId }) {
  const patientProvider = yield select((state) =>
    patientProviderByIdSelector(state, patientProviderId)
  );
  const providerInfo = yield select((state) =>
    providerInfoSelector(state, patientProvider.providerId)
  );
  const url = `/user-documentation/docs?patientId=${
    patientProvider.patient.id
  }&providerId=${patientProvider.providerId}`;
  const options = {
    method: 'GET',
  };
  try {
    const providerAgreement = yield checkProviderAgreementWorker({
      providerId: patientProvider.providerId,
    });
    if (providerAgreement) {
      yield put(
        showDialog(AGREEMENTS_DIALOG, {
          title: `Update to Terms of Use from ${providerInfo.name}`,
          hideTabs: true,
          agreements: [providerAgreement],
        })
      );
      yield take(SIGN_AGREEMENT_SUCCESS);
    }
    const rawPayload = yield apiSecureRequest(url, options);
    const payload = convertDates(rawPayload);
    yield put(onGetDocsSuccess({ payload, patientProviderId }));
    return payload;
  } catch (error) {
    yield put(onGetDocsFailure({ error, patientProviderId }));
    return null;
  }
}

function* getDocsSaga() {
  yield takeLatest(GET_DOCS_REQUEST, getDocsWorker);
}

function* viewDocWorker({ patientProviderId, note }) {
  const patientProvider = yield select((state) =>
    patientProviderByIdSelector(state, patientProviderId)
  );
  const params = {
    noteId: note.noteId,
    patientId: patientProvider.patient.id,
    providerId: patientProvider.providerId,
    noteType: note.noteType,
    type: note.type,
  };

  if (note.noteType !== 'SCAN') {
    yield put(
      push(`/enote?${stringify({ ...params, noteTitle: note.noteTitle })}`)
    );
    return true;
  }

  const url = `/user-documentation/view?${stringify(params)}`;
  const options = {
    method: 'GET',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    window.open(payload.message, '_blank');
    return true;
  } catch (error) {
    return null;
  }
}

function* viewDocSaga() {
  yield takeLatest(VIEW_DOC_REQUEST, viewDocWorker);
}

function* loadDocWorker({ noteParams }) {
  const params = stringify(noteParams);
  const url = `/user-documentation/view?${params}`;
  const options = {
    method: 'GET',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    if (payload) {
      yield put(onLoadDocSuccess({ payload }));
      return payload;
    } else {
      yield put(push(`/not-found`));
    }
  } catch (error) {
    yield put(onLoadDocFailure({ error }));
    yield put(push(`/not-found`));
    return null;
  }
}

function* loadDocSaga() {
  yield takeLatest(LOAD_DOC_REQUEST, loadDocWorker);
}

export default combineSagas([getDocsSaga, viewDocSaga, loadDocSaga]);
