import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { parse } from 'query-string';

import SignupPage from 'views/SignupPage';

function AuthLoadingPage(props) {
  const { location } = props;
  const { token } = parse(location.search);

  if (token) {
    return <SignupPage token={token} />;
  }
  return <Redirect to={{ pathname: `/login` }} />;
}

AuthLoadingPage.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};

export default withRouter(React.memo(AuthLoadingPage));
