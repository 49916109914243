/**
 * selects whether dialog is shown
 */
export const isDialogShownSelector = ({ memoryData: { dialog } }) =>
  Boolean(dialog.route);

/**
 * selects dialog
 */
export const dialogSelector = ({ memoryData: { dialog } }) => ({
  route: dialog.route,
  props: dialog.props,
});
