import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import { PersistGate } from 'redux-persist/integration/react';

import PageLayout from 'layouts/PageLayout';
import Routes from 'config/Routes';
import Dialog from 'config/Dialog';
import Popup from 'config/Popup';
import configureStore from 'redux/store';
import { tokenSelector } from 'redux/selectors/auth';
import { loadInitialData } from 'redux/actions/initialData';
import { ToastContainer } from 'components/Toaster';

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.storeCfg = configureStore();
  }

  onBeforeLift = () => {
    const { store } = this.storeCfg;
    const authToken = tokenSelector(store.getState());
    if (authToken) {
      store.dispatch(loadInitialData(authToken));
    }
  };

  render() {
    const { store, history, persistor } = this.storeCfg;
    return (
      <Provider store={store}>
        <PersistGate
          loading={null}
          persistor={persistor}
          onBeforeLift={this.onBeforeLift}
        >
          <ConnectedRouter history={history}>
            <PageLayout>
              <Routes />
            </PageLayout>
            <Dialog />
            <Popup />
            <ToastContainer />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
