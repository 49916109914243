import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';

import patientProviders from './patientProviders';
import providers from './providers';
import patientInvitations from './patientInvitations';
import docs from './docs';
import bills from './bills';
import selectedDay from './selectedDay';

const rootReducer = combineReducers({
  patientProviders: patientProviders,
  providers,
  patientInvitations,
  docs,
  bills,
  selectedDay,
});

const persistConfig = {
  key: 'tbpp_ss_v1',
  storage: sessionStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
