import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Button from 'components/Button';
import TextField from 'components/TextField';
import { scrollToError } from 'utils';

import styles from './styles.module.scss';

const getFormErrors = ({ dob, zip, relationType, clinicianName }) => {
  const today = moment();
  const yearOfBirth = dob.split('/')[2];
  const result = {};
  if (!dob) {
    result['dob'] = `Patient's DOB is required`;
  } else if (!moment(dob, 'MM/DD/YYYY', true).isValid() || yearOfBirth < 1900) {
    result['dob'] = `Patient's DOB is invalid`;
  } else if (moment(dob).isAfter(today)) {
    result['dob'] = `Patient's DOB can't be in future`;
  }
  if (!zip) {
    result['zip'] = `Patient's zip code is required`;
  } else if (zip.length !== 5) {
    result['zip'] = `Patient's zip code is invalid`;
  }
  if (!relationType) {
    result['relationType'] = 'Your relationship to patient is required';
  }
  if (!clinicianName) {
    result['clinicianName'] = `Any treating clinician's first name is required`;
  }
  return Object.keys(result).length === 0 ? undefined : result;
};

class ConfirmRequestDialog extends React.PureComponent {
  state = {
    form: {
      dob: '',
      zip: '',
      relationType: '',
      clinicianName: '',
    },
    loading: false,
    errors: {},
    error: '',
  };

  addError = (name, message) =>
    this.setState((state) => ({
      ...state,
      errors: { ...state.errors, [name]: message },
    }));

  onInputChange = (name) => ({ target: { value } }) =>
    this.setState((state) => ({
      ...state,
      form: { ...state.form, [name]: value },
      errors: { ...state.errors, [name]: undefined },
    }));

  onConfirmRequest = () => {
    const { form } = this.state;
    const errors = getFormErrors(form);
    if (errors) {
      const [firstError] = Object.keys(errors);
      scrollToError(firstError);
      return this.setState({ errors });
    }
    this.setState({ errors: {}, loading: true });
    const {
      onConfirmInformation,
      onDialogClose,
      token,
      providerId,
    } = this.props;
    return onConfirmInformation(form, token, providerId)
      .then((response) => {
        if (response.status === 'SUCCESS') onDialogClose();
      })
      .catch((error) => {
        if (error.details) {
          if (['EXPIRED'].includes(error.details.cause)) {
            this.setState({
              error: `Request expired. Contact the healthcare provider.`,
            });
          }
        } else this.setState({ error: error.message });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { onDialogClose, patient } = this.props;
    const { form, errors, loading, error } = this.state;
    const { dob, zip, relationType, clinicianName } = form;
    return (
      <>
        {error ? (
          <div className={styles.error}>{error}</div>
        ) : (
          <div className={styles.note}>
            as it pertains to {patient.firstName}
          </div>
        )}
        <div>
          <TextField
            name="dob"
            value={dob}
            onChange={this.onInputChange('dob')}
            error={errors['dob']}
            placeholder="MM/DD/YYYY"
            type="text"
            mask="99/99/9999"
            label="Patient's DOB"
            className={styles.input}
          />
          <TextField
            name="zip"
            value={zip}
            onChange={this.onInputChange('zip')}
            mode="integer"
            error={errors['zip']}
            placeholder="Enter here"
            type="text"
            autoComplete="on"
            label="Patient's zip code"
            className={styles.input}
            maxLength={5}
          />
          <TextField
            name="relationType"
            value={relationType}
            onChange={this.onInputChange('relationType')}
            error={errors['relationType']}
            placeholder="Enter here"
            type="text"
            autoComplete="on"
            label="Your relationship to patient"
            className={styles.input}
            maxLength="25"
          />
          <TextField
            name="clinicianName"
            value={clinicianName}
            onChange={this.onInputChange('clinicianName')}
            error={errors['clinicianName']}
            placeholder="Enter here"
            type="text"
            autoComplete="on"
            label="Treating clinician's first name"
            className={styles.input}
            maxLength="25"
          />
        </div>
        <div className={styles.buttons}>
          <Button
            text="Cancel"
            secondary={true}
            className={styles.closeButton}
            onClick={onDialogClose}
          />
          <Button
            text="Confirm"
            loading={loading}
            className={styles.saveButton}
            onClick={this.onConfirmRequest}
          />
        </div>
      </>
    );
  }
}

ConfirmRequestDialog.propTypes = {
  onDialogClose: PropTypes.func.isRequired,
  checkConfig: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  providerId: PropTypes.string.isRequired,
  patient: PropTypes.shape().isRequired,
  onConfirmInformation: PropTypes.func.isRequired,
};

export default ConfirmRequestDialog;
