import AuthLoadingPage from 'views/AuthLoadingPage';
import LoginPage from 'views/LoginPage';
import ForgetPasswordPage from 'views/ForgetPasswordPage';
import ResetPasswordPage from 'views/ResetPasswordPage';
import HomePage from 'views/HomePage';
import BillsPage from 'views/BillsPage';
import NotFoundPage from 'views/NotFoundPage';
import ProfilePage from 'views/ProfilePage';
import ENotePage from 'views/ENotePage';
import EBillPage from 'views/EBillPage';

import MFAPage from 'views/MFAPage';

import SecureRoute from './SecureRoute';
import AnonymousRoute from './AnonymousRoute';
import MFARoute from './MFARoute';

const routeConfig = [
  {
    path: '/',
    exact: true,
    component: AuthLoadingPage,
    routeComponent: AnonymousRoute,
  },
  {
    path: '/login',
    component: LoginPage,
    routeComponent: AnonymousRoute,
  },
  {
    path: '/mfa',
    component: MFAPage,
    routeComponent: MFARoute,
  },
  {
    path: '/forget-password',
    component: ForgetPasswordPage,
    routeComponent: AnonymousRoute,
  },
  {
    path: '/recovery-password',
    component: ResetPasswordPage,
    routeComponent: AnonymousRoute,
  },
  {
    path: '/home',
    component: HomePage,
    routeComponent: SecureRoute,
  },
  {
    path: '/bills',
    component: BillsPage,
    routeComponent: SecureRoute,
  },
  {
    path: '/enote',
    component: ENotePage,
    routeComponent: SecureRoute,
  },
  {
    path: '/ebill',
    component: EBillPage,
    routeComponent: SecureRoute,
  },
  {
    path: '/profile',
    component: ProfilePage,
    routeComponent: SecureRoute,
  },
  {
    component: NotFoundPage,
  },
];

export default routeConfig;
