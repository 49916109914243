import { createStore, compose, applyMiddleware } from 'redux';
import { createBrowserHistory as createHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import createReduxWaitForMiddleware from 'redux-wait-for-action';
import { persistStore } from 'redux-persist';

import createRootReducer from 'redux/reducers';
import rootSaga from 'redux/sagas';

import scrollMiddleware from './middlewares/scrollMiddleware';
import patchReduxWaitForMiddleware from './middlewares/patchReduxWaitForMiddleware';
import loggedUserIdMiddleware from './middlewares/loggedUserIdMiddleware';
import getDeviceIdMiddleware from './middlewares/getDeviceIdMiddleware';

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  process.env.NODE_ENV === 'production'
    ? compose
    : window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools;

const configureStore = (initialState) => {
  const history = createHistory();
  const rootReducer = createRootReducer(history);
  const middlewares = [
    sagaMiddleware,
    loggedUserIdMiddleware,
    getDeviceIdMiddleware,
    patchReduxWaitForMiddleware,
    createReduxWaitForMiddleware(),
    scrollMiddleware,
    routerMiddleware(history),
  ];
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middlewares))
  );
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { store, persistor, history };
};

export default configureStore;
