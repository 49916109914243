import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

function CheckBox({ onChange, checked, children, className, name, ...rest }) {
  return (
    <label className={cn(styles.wrapper, className)} data-scroll-id={name}>
      <input
        {...rest}
        className={styles.input}
        checked={checked}
        type="checkbox"
        onChange={onChange}
      />
      <span className={styles.label}>{children}</span>
    </label>
  );
}

CheckBox.defaultProps = {
  onChange: (e) => e.preventDefault(),
};

CheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default CheckBox;
