import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';

import styles from './styles.module.scss';

function Day({ day, dates }) {
  const date = day.getDate();
  const dots = dates
    .filter((d) => moment(d.date).isSame(day, 'day'))
    .sort(function(a) {
      return a.viewed ? 1 : -1;
    })
    .filter((_, index) => index < 3);
  return (
    <div className={styles.wrapper}>
      {date}
      {dots.length > 0 && (
        <ul className={styles.dots}>
          {dots.map((d, i) => (
            <li
              key={i}
              className={cn(styles.dot, d.viewed && styles.viewedDot)}
            />
          ))}
        </ul>
      )}
    </div>
  );
}

Day.propTypes = {
  day: PropTypes.object.isRequired,
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.object.isRequired,
      viewed: PropTypes.bool,
    })
  ).isRequired,
};

export default React.memo(Day);
