import { push } from 'connected-react-router';

import { takeLatest, put } from 'redux-saga/effects';
import {
  RESET_PASSWORD_REQUEST,
  RECOVERY_PASSWORD_REQUEST,
} from 'redux/consts/resetPassword';
import {
  onResetPasswordSuccess,
  onRecoveryPasswordSuccess,
} from 'redux/actions/resetPassword';

import { apiRequest, combineSagas } from './utils';

function* resetPasswordWorker(payload) {
  const { data } = payload;
  const { email, recaptcha, captchaDisabled } = data;
  const url = `/recovery`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      ...(captchaDisabled
        ? { captchaDisabled: true }
        : { recaptchaResponse: recaptcha }),
    }),
  };
  try {
    const payload = yield apiRequest(url, options);
    yield put(onResetPasswordSuccess({ payload }));
    return payload;
  } catch (error) {
    return false;
  }
}

function* recoveryPasswordWorker({ data }) {
  const { email, password, recoveryToken, recaptcha, captchaDisabled } = data;
  const url = `/recovery`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email,
      password,
      recoveryToken,
      ...(captchaDisabled
        ? { captchaDisabled: true }
        : { recaptchaResponse: recaptcha }),
    }),
  };
  try {
    const payload = yield apiRequest(url, options);
    yield put(onRecoveryPasswordSuccess({ payload }));
    yield put(push('/login?passwordChanged=true'));
    return payload;
  } catch (error) {
    return false;
  }
}

function* resetPasswordSaga() {
  yield takeLatest(RESET_PASSWORD_REQUEST, resetPasswordWorker);
}
function* recoveryPasswordSaga() {
  yield takeLatest(RECOVERY_PASSWORD_REQUEST, recoveryPasswordWorker);
}
export default combineSagas([resetPasswordSaga, recoveryPasswordSaga]);
