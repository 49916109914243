import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'components/TextField';
import ErrorBar from 'components/ErrorBar';
import CheckBox from 'components/CheckBox';

import styles from '../styles.module.scss';

import ShowTermsButton from './ShowTermsButton';

class Step3 extends React.PureComponent {
  state = {
    checkboxError: '',
  };
  onAgreementResponse = (value) => {
    const { onInputChange } = this.props;
    if (value) this.setState({ checkboxError: '' });
    onInputChange('signedAgreements')({ target: { value } });
  };

  onTermsErrorHandler = (error) => {
    const { onTermsError } = this.props;
    this.setState({ checkboxError: '' });
    onTermsError(error);
  };

  onCheckAgreement = () => {
    const {
      form: { signedAgreements, termsError },
    } = this.props;
    if (!signedAgreements && !termsError) {
      this.setState({
        checkboxError: `Press on "Terms of Use" to agree`,
      });
    }
  };

  render() {
    const {
      form,
      errors,
      onInputChange,
      email,
      authToken,
      signedAgreementError,
    } = this.props;
    const { password, signedAgreements } = form;
    const { checkboxError } = this.state;
    return (
      <>
        <div className={styles.headerWrapper}>
          <div className={styles.headerInfo}>
            You will log in with your email address of <span>{email}</span> and
            the password you choose below.
          </div>
          {Boolean(errors.form) && (
            <ErrorBar
              className={styles.errorBar}
              name="form"
              error={errors.form}
            />
          )}
        </div>
        <div>
          <TextField
            name="password"
            value={password}
            onChange={onInputChange('password')}
            error={errors['password']}
            placeholder="Type your desired password"
            type="password"
            autoComplete="on"
            label="Password"
            className={styles.input}
            maxLength={25}
          />
        </div>
        <div className={styles.options}>
          <CheckBox
            name="signedAgreements"
            onClick={this.onCheckAgreement}
            checked={Boolean(signedAgreements)}
          >
            I agree to the
            <ShowTermsButton
              authToken={authToken}
              onChange={this.onAgreementResponse}
              className={styles.link}
              onTermsError={this.onTermsErrorHandler}
            />
          </CheckBox>
          {!signedAgreementError &&
            (errors['signedAgreements'] || checkboxError) && (
              <div className={styles.errorMsg}>
                {errors['signedAgreements'] || checkboxError}
              </div>
            )}
        </div>
      </>
    );
  }
}

Step3.propTypes = {
  email: PropTypes.string.isRequired,
  authToken: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({
    password: PropTypes.string.isRequired,
    signedAgreements: PropTypes.arrayOf(
      PropTypes.shape({
        agreementId: PropTypes.string.isRequired,
        agreementPublishedOn: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
  errors: PropTypes.shape({}).isRequired,
  signedAgreementError: PropTypes.bool.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onTermsError: PropTypes.func.isRequired,
};

export default Step3;
