import { put, select, takeEvery } from 'redux-saga/effects';

import { LOGOUT_REQUEST } from 'redux/consts/auth';
import {
  onLogoutSuccess,
  onLogoutFailure,
  onClientSideLogout,
} from 'redux/actions/auth';
import { tokenSelector } from 'redux/selectors/auth';
import { createApiSecureRequest } from 'redux/sagas/utils';

function* logoutWorker() {
  const token = yield select(tokenSelector);
  yield put(onClientSideLogout());
  const url = `/oauth/logout`;
  const options = { method: 'POST' };
  try {
    const apiSecureRequest = createApiSecureRequest({ token });
    const payload = yield apiSecureRequest(url, options);
    yield put(onLogoutSuccess({ payload }));
    return payload;
  } catch (error) {
    yield put(onLogoutFailure({ error }));
    return null;
  }
}

function* logoutSaga() {
  yield takeEvery(LOGOUT_REQUEST, logoutWorker);
}
export default logoutSaga;
