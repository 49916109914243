import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';

import {
  GET_BILLS_REQUEST,
  GET_BILLS_SUCCESS,
  GET_BILLS_FAILURE,
  VIEW_BILL_REQUEST,
  LOAD_BILL_REQUEST,
  LOAD_BILL_SUCCESS,
  LOAD_BILL_FAILURE,
} from 'redux/consts/bills';

export function getBills() {
  return {
    type: GET_BILLS_REQUEST,
    [WAIT_FOR_ACTION]: GET_BILLS_SUCCESS,
    [ERROR_ACTION]: GET_BILLS_FAILURE,
  };
}

export function onGetBillsSuccess({ payload }) {
  return {
    type: GET_BILLS_SUCCESS,
    payload,
  };
}

export function onGetBillsFailure({ error }) {
  return {
    type: GET_BILLS_FAILURE,
    error,
  };
}

export function viewBill(params) {
  return {
    type: VIEW_BILL_REQUEST,
    params,
  };
}

export function loadBill(params) {
  return {
    type: LOAD_BILL_REQUEST,
    params,
    [WAIT_FOR_ACTION]: LOAD_BILL_SUCCESS,
    [ERROR_ACTION]: LOAD_BILL_FAILURE,
  };
}
export function onLoadBillSuccess({ payload }) {
  return {
    type: LOAD_BILL_SUCCESS,
    payload,
  };
}

export function onLoadBillFailure({ error }) {
  return {
    type: LOAD_BILL_FAILURE,
    error,
  };
}