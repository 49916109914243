import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import SingleSelect from './SingleSelect';
import MultiSelect from './MultiSelect';

import styles from './styles.module.scss';
import useOnClickOutside from './utils';

function Dropdown({ options, multiSelect, type, onChange }) {
  const [forceClose, setForceClose] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState([options[0].key]);

  const ref = useRef();
  const initialOption = options[0].key;
  const placeholder = value.length ? `${value.join(', ')}` : initialOption;

  const togglePopup = () => {
    setIsOpen(true);
  };
  const hidePopup = () => {
    setIsOpen(false);
    setForceClose(false);
  };

  useOnClickOutside(ref, hidePopup, forceClose);

  const handleMultiSelect = (option) => {
    if (option.key === initialOption) {
      setValue([option.key]);
      onChange(type ? { [type]: [] } : option);
      setForceClose(true);
    } else {
      const filteredValue = value.filter((v) => v !== initialOption);
      const newValue = !filteredValue.includes(option.key)
        ? [...filteredValue, option.key]
        : filteredValue.filter((opt) => opt !== option.key);
      setValue(newValue);
      onChange(type ? { [type]: newValue.length ? newValue : [] } : option);
    }
  };

  const handleSingleSelect = (option) => {
    setValue([option.key]);
    setForceClose(true);
    onChange(
      type
        ? { [type]: option.key !== initialOption ? [option.val] : [] }
        : option
    );

    // hidePopup();
  };

  return (
    <>
      <div ref={ref} className={styles.wrapper} onClick={togglePopup}>
        <div className={styles.placeholder}>{placeholder}</div>
        <span className={cn(styles.chevron, isOpen && styles.isOpen)} />
        {isOpen && (
          <ul className={styles.dropdownPopup}>
            {multiSelect ? (
              <MultiSelect
                options={options}
                onChange={handleMultiSelect}
                value={value}
              />
            ) : (
              <SingleSelect
                options={options}
                onChange={handleSingleSelect}
                value={value}
              />
            )}
          </ul>
        )}
      </div>
    </>
  );
}

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  multiSelect: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(Dropdown);
