import moment from 'moment';

import { take, takeLatest, put, select } from 'redux-saga/effects';
import {
  GET_SCHEDULES_REQUEST,
  CONFIRM_APPOINTMENT_REQUEST,
} from 'redux/consts/schedules';
import { GET_DOCS_SUCCESS } from 'redux/consts/docs';
import {
  onConfirmAppointmentSuccess,
  onConfirmAppointmentFailure,
  onGetSchedulesSuccess,
  onGetSchedulesFailure,
} from 'redux/actions/schedules';
import { patientProviderByIdSelector } from 'redux/selectors/patientProviders';

import { apiSecureRequest, combineSagas } from './utils';

const convertDates = (schedules) =>
  schedules.map((s) => {
    const utcDate = moment.utc(s.scheduleDate).format();
    const scheduleDate = moment
      .utc(utcDate)
      .local()
      .format();
    return { ...s, scheduleDate };
  });

function* getSchedulesWorker({ patientProviderId }) {
  const patientProvider = yield select((state) =>
    patientProviderByIdSelector(state, patientProviderId)
  );
  const url = `/user-documentation/schedules?patientId=${
    patientProvider.patient.id
  }&providerId=${patientProvider.providerId}`;
  const options = {
    method: 'GET',
  };
  try {
    yield take(GET_DOCS_SUCCESS);
    const rawPayload = yield apiSecureRequest(url, options);
    const payload = convertDates(rawPayload);
    yield put(onGetSchedulesSuccess({ payload, patientProviderId }));
    return payload;
  } catch (error) {
    yield put(onGetSchedulesFailure({ error, patientProviderId }));
    return null;
  }
}

function* getSchedulesSaga() {
  yield takeLatest(GET_SCHEDULES_REQUEST, getSchedulesWorker);
}

function* confirmAppointmentSagaWorker({ scheduleId }) {
  const url = `/user-documentation/schedules/confirm-appointment?id=${scheduleId}`;
  const options = {
    method: 'POST',
  };
  try {
    yield apiSecureRequest(url, options);
    yield put(onConfirmAppointmentSuccess());
    return {};
  } catch (error) {
    yield put(onConfirmAppointmentFailure({ error }));
    return null;
  }
}

function* confirmAppointmentSaga() {
  yield takeLatest(CONFIRM_APPOINTMENT_REQUEST, confirmAppointmentSagaWorker);
}

export default combineSagas([getSchedulesSaga, confirmAppointmentSaga]);
