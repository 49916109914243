import { GET_BILLS_SUCCESS, GET_BILLS_FAILURE } from 'redux/consts/bills';
import { LOGOUT_SUCCESS } from 'redux/consts/auth';

const initialState = [];

const billsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    case GET_BILLS_SUCCESS: {
      return action.payload;
    }
    case GET_BILLS_FAILURE: {
      return initialState;
    }
    default:
      return state;
  }
};

export default billsReducer;
