import { SELECT_PATIENT_PROVIDER_ID } from 'redux/consts/patientProviders';

import { WITH_LOGGED_USER_ID } from 'redux/consts/global';

export function selectPatientProviderId(id) {
  return {
    type: SELECT_PATIENT_PROVIDER_ID,
    id,
    [WITH_LOGGED_USER_ID]: true,
  };
}
