import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { scrollToElement } from 'utils';

import SchedulesList from './SchedulesList';

import styles from './styles.module.scss';

function HidableSchedules({ schedules, onScheduleChange }) {
  const [shown, setShown] = useState(false);

  const show = () => {
    setShown(true);
    scrollToElement('#schedules', { duration: 300, topOffset: 120 });
  };
  const hide = () => setShown(false);

  if (!shown) {
    return (
      <div className={styles.btnWrapper}>
        <button className={styles.btn} type="button" onClick={show}>
          Show appointments
        </button>
      </div>
    );
  }

  return (
    <>
      <SchedulesList schedules={schedules} onScheduleChange={onScheduleChange} />
      <div className={styles.bottomBtnWrapper}>
        <button className={styles.btn} type="button" onClick={hide}>
          Hide appointments
        </button>
      </div>
    </>
  );
}

HidableSchedules.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onScheduleChange: PropTypes.func.isRequired,
};

export default React.memo(HidableSchedules);
