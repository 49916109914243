import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function ReadonlyField(props) {
  const { title, text } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.text}>{text || ''}</div>
    </div>
  );
}

ReadonlyField.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
};

export default React.memo(ReadonlyField);
