import { connect } from 'react-redux';

import { onChangeUserPasswordRequest } from 'redux/actions/loggedUser';

import ChangePasswordDialog from './ChangePasswordDialog';

const connectStore = connect(
  null,
  { onChangeUserPassword: onChangeUserPasswordRequest }
);

export default connectStore(ChangePasswordDialog);
