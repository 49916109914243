import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import UserSection from './UserSection';
import PatientProvidersSection from './PatientProvidersSection';

import styles from './styles.module.scss';

function Header({ includePatientsDropdown }) {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.innerContent}>
          <div className={styles.leftBlock}>
            <Link to="/home" className={styles.logoImg} />
            {!isMobile && includePatientsDropdown && (
              <PatientProvidersSection />
            )}
          </div>
          <UserSection />
        </div>
      </div>
      {isMobile && includePatientsDropdown && (
        <div className={styles.wrapperSL}>
          <div className={styles.innerContentSL}>
            <PatientProvidersSection />
          </div>
        </div>
      )}
    </>
  );
}

Header.defaultProps = {
  includePatientsDropdown: true,
};

Header.propTypes = {
  includePatientsDropdown: PropTypes.bool,
};

export default Header;
