import { call } from 'redux-saga/effects';

import { API_ROOT } from 'consts';
import request from 'utils/request';

import wrapWithDefaultHandlers from './wrapWithDefaultHandlers';

export function* rawApiRequest(url, options) {
  const payload = yield call(request, `${API_ROOT}${url}`, options);
  return payload;
}

export default wrapWithDefaultHandlers(rawApiRequest);
