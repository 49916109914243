import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';

import { providerInfoSelector } from 'redux/selectors/providerInfo';

import PatientProviderPreview from './PatientProviderPreview';

function PatientProviderPreviewContainer(props) {
  const { providerId, ...rest } = props;

  const provider = useSelector(
    (state) => providerInfoSelector(state, providerId),
    shallowEqual
  );

  return <PatientProviderPreview provider={provider} {...rest} />;
}

PatientProviderPreviewContainer.propTypes = {
  providerId: PropTypes.string.isRequired,
};

export default React.memo(PatientProviderPreviewContainer);
