import scrollIntoView from 'scroll-into-view';
import moment from 'moment';

function addTokenToOptions(token, options) {
  const newOptions = Object.assign({ headers: {} }, options);
  newOptions.headers['Authorization'] = `Bearer ${(token || {}).access_token}`;
  return newOptions;
}

export function tokenizeRequestParams(token) {
  return function(url, options, ...rest) {
    return [url, addTokenToOptions(token, options), ...rest];
  };
}

export function preventDefault(fn) {
  return function(e) {
    e.preventDefault();
    return fn(e);
  };
}

export function thousandSeparator(number) {
  const [leftPart, rightPart] = number.toString().split('.');
  const parsedRightPart =
    rightPart && rightPart.length === 2
      ? rightPart
      : rightPart && rightPart.length === 1
      ? `${rightPart}0`
      : '00';
  const parsedLeftPart = leftPart
    .split('')
    .reverse()
    .map((item, idx) => (idx % 3 === 0 && idx !== 0 ? `${item},` : item))
    .reverse()
    .join('');
  return [parsedLeftPart, parsedRightPart].join('.');
}

export function fromToDateParser(fromDate, toDate) {
  if (!fromDate && !toDate) return;
  const sameDate = moment(fromDate).isSame(toDate);
  return `${
    toDate && !sameDate
      ? `${moment(fromDate).format('MM/DD')} - ${moment(toDate).format(
          'MM/DD/YY'
        )}`
      : `${moment(fromDate).format('MM/DD/YY')}`
  }`;
}

export function getPatientProviderId({ patient, providerId }) {
  return `${patient.id}_${providerId}`;
}

export function getNoteKey(doc) {
  return `${doc.noteId}`;
}

export const scrollToElement = (
  element,
  { duration = 400, topOffset = 100 } = {}
) => {
  const scrollElement =
    typeof element === 'string' ? document.querySelector(element) : element;
  scrollIntoView(scrollElement, {
    time: duration,
    align: { top: 0, left: 0, topOffset },
  });
};

export const scrollToError = (dataScrollId, opts) =>
  scrollToElement(`[data-scroll-id="${dataScrollId}"]`, opts);

export const scrollToHighlighted = (dataScrollId, opts) =>
  scrollToElement(`[data-id="${dataScrollId}"]`, opts);

export const initPageItems = (id, itemsCount, arr) => {
  const viewedIdx = arr.findIndex((item) => {
    const itemId = item.noteId ? item.noteId : item.invoiceId;
    return itemId === Number(id);
  });
  const pagesCount = Math.round((viewedIdx + 1) / itemsCount);
  return pagesCount === 0 ? itemsCount : itemsCount + itemsCount * pagesCount;
};
