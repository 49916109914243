import { connect } from 'react-redux';

import { showOverflow, hideOverflow } from 'redux/actions/overflow';

import ProviderPopup from './ProviderPopup';

export default connect(
  null,
  { showOverflow, hideOverflow }
)(ProviderPopup);
