import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';

import {
  GET_DOCS_REQUEST,
  GET_DOCS_SUCCESS,
  GET_DOCS_FAILURE,
  VIEW_DOC_REQUEST,
  LOAD_DOC_REQUEST,
  LOAD_DOC_SUCCESS,
  LOAD_DOC_FAILURE,
} from 'redux/consts/docs';

export function getDocs(patientProviderId) {
  return {
    type: GET_DOCS_REQUEST,
    patientProviderId,
    [WAIT_FOR_ACTION]: GET_DOCS_SUCCESS,
    [ERROR_ACTION]: GET_DOCS_FAILURE,
  };
}
export function onGetDocsSuccess({ payload, patientProviderId }) {
  return {
    type: GET_DOCS_SUCCESS,
    patientProviderId,
    payload,
  };
}

export function onGetDocsFailure({ error, patientProviderId }) {
  return {
    type: GET_DOCS_FAILURE,
    patientProviderId,
    error,
  };
}

export function viewDoc({ note, patientProviderId }) {
  return {
    type: VIEW_DOC_REQUEST,
    note,
    patientProviderId,
  };
}

export function loadDoc({ noteParams }) {
  return {
    type: LOAD_DOC_REQUEST,
    noteParams,
    [WAIT_FOR_ACTION]: LOAD_DOC_SUCCESS,
    [ERROR_ACTION]: LOAD_DOC_FAILURE,
  };
}
export function onLoadDocSuccess({ payload }) {
  return {
    type: LOAD_DOC_SUCCESS,
    payload,
  };
}

export function onLoadDocFailure({ error }) {
  return {
    type: LOAD_DOC_FAILURE,
    error,
  };
}
