import React from 'react';
import PropTypes from 'prop-types';

import styles from '../styles.module.scss';

function MultiSelectContainer({ options, value, onChange }) {
  return (
    <>
      {options.map((option) => (
        <li className={styles.popupItem} key={option.key}>
          <input
            id={option.key}
            type="checkbox"
            onChange={() => onChange(option)}
            checked={value.includes(option.key)}
          />
          <label className={styles.checkboxLabel} htmlFor={option.key}>
            {option.key}
          </label>
        </li>
      ))}
    </>
  );
}

MultiSelectContainer.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(MultiSelectContainer);
