import { put, takeLatest } from 'redux-saga/effects';

import { showDialog } from 'redux/actions/dialog';
import {
  signTBAgreement,
  onSignTBAgreementSuccess,
} from 'redux/actions/agreements';
import { DEFAULT_ERROR_MSG } from 'consts';
import { SIGN_TB_AGREEMENT_REQUEST } from 'redux/consts/agreements';
import { AGREEMENTS_DIALOG } from 'config/Dialog/consts';
import { createApiSecureRequest, combineSagas } from 'redux/sagas/utils';
import { getAgreementsWorker } from 'redux/sagas/agreements';
import { backLogin } from 'redux/actions/mfa';

import completeAuth from './auth/completeAuth';

export function* checkTBAgreementWorker(authToken) {
  const apiSecureRequest = createApiSecureRequest({ token: authToken });
  const response = yield getAgreementsWorker(apiSecureRequest);
  if (response.status === 'NOT_FOUND') {
    return;
  }
  if (response.status === 'FAIL') {
    throw new Error(response.message || DEFAULT_ERROR_MSG);
  }
  const tbAgreement = response.find((a) => a.type === 'TB');
  if (
    !tbAgreement.signedOn ||
    new Date(tbAgreement.signedOn).getTime() <
      new Date(tbAgreement.agreementPublishedOn).getTime()
  ) {
    yield put(
      showDialog(AGREEMENTS_DIALOG, {
        title: `Update to Terms of Use`,
        hideTabs: true,
        agreements: [tbAgreement],
        onCustomClose: () =>
          backLogin({
            prevPath: '/mfa',
            message: `Please agree to Terms of Use.`,
          }),
        reduxSignAction: signTBAgreement({
          authToken,
          agreementId: tbAgreement.agreementId,
        }),
      })
    );
    throw new Error(`Please agree to Terms of Use.`);
  }
}

function* signTBAgreementWorker({ authToken, agreementId }) {
  const apiSecureRequest = createApiSecureRequest({ token: authToken });
  const url = `/user-profile/agreement?agreementId=${agreementId}`;
  const options = {
    method: 'POST',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    yield completeAuth({ authToken });
    yield put(onSignTBAgreementSuccess({ payload }));
    return payload;
  } catch (error) {
    if (error && error.message === 'Agreement already signed.') {
      const payload = {};
      yield completeAuth({ authToken });
      yield put(onSignTBAgreementSuccess({ payload }));
      return payload;
    }
    return false;
  }
}

function* signTBAgreementSaga() {
  yield takeLatest(SIGN_TB_AGREEMENT_REQUEST, signTBAgreementWorker);
}

export default combineSagas([signTBAgreementSaga]);
