import { connect } from 'react-redux';

import { loggedUserDataSelector } from 'redux/selectors/loggedUser';
import { updateUserData } from 'redux/actions/loggedUser';
import { loadPaymentMethod, deletePaymentMethod } from 'redux/actions/billsPayment';
import { showDialog } from 'redux/actions/dialog';
import { CANCEL_CHANGES_DIALOG } from 'config/Dialog/consts';

import UserInfoForm from './UserInfoForm';

const mapStateToProps = (state) => ({
  userData: loggedUserDataSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  onUpdateUserData: (userData) => dispatch(updateUserData(userData)),
  loadPaymentMethodData: () => dispatch(loadPaymentMethod()),
  onDeletePaymentMethod: (data) => dispatch(deletePaymentMethod(data)),
  showCancelChangesDialog: (yesFn) =>
    dispatch(showDialog(CANCEL_CHANGES_DIALOG, { yesFn })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInfoForm);
