import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import {
  CANCEL_REQUEST_DIALOG,
  CONFIRM_REQUEST_DIALOG,
} from 'config/Dialog/consts';

import Title from '../Title';

import RequestCard from './RequestCard';
import { formatDate } from './utils';

import styles from './styles.module.scss';

const actionsCfg = {
  CONFIRM: (request) => ({
    caption: `Confirm request`,
    dialogRoute: CONFIRM_REQUEST_DIALOG,
    dialogProps: {
      token: request.invitation.token,
      patient: request.patient,
      providerId: request.providerId,
    },
  }),
  CANCEL: (request) => ({
    caption: `Cancel this request`,
    dialogRoute: CANCEL_REQUEST_DIALOG,
    dialogProps: { token: request.invitation.token },
  }),
};

const statusCfg = {
  NEW: {
    getNote: (request) =>
      `Awaiting your confirmation since ${formatDate(
        request.invitation.statusTime
      )}`,
    getActions: (request) => [
      actionsCfg.CONFIRM(request),
      actionsCfg.CANCEL(request),
    ],
  },
  PENDING: {
    getNote: (request) =>
      `Awaiting provider's approval since ${formatDate(
        request.invitation.statusTime
      )}`,
    getActions: (request) => [actionsCfg.CANCEL(request)],
  },
  APPROVED: {
    getNote: () => `Should not be here`,
    getActions: (request) => [
      actionsCfg.CONFIRM(request),
      actionsCfg.CANCEL(request),
    ],
  },
};

class PendingRequestBlock extends React.PureComponent {
  render() {
    const { pendingRequests, className } = this.props;

    if (pendingRequests.length === 0) {
      return null;
    }

    return (
      <div className={cn(styles.requestBlock, className)}>
        <Title title="Pending request for access" />
        {pendingRequests.map((request) => {
          const cfg = statusCfg[request.invitation.status];
          return (
            <div className={styles.card} key={request.invitation.token}>
              <RequestCard
                patient={request.patient}
                providerId={request.providerId}
                note={cfg.getNote(request)}
                actions={cfg.getActions(request)}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

PendingRequestBlock.propTypes = {
  className: PropTypes.string,
  pendingRequests: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default PendingRequestBlock;
