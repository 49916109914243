export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const BASIC_AUTH_TOKEN = process.env.REACT_APP_BASIC_AUTH_TOKEN;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
export const IS_RECAPTCHA_OPTIONAL = JSON.parse(
  process.env.REACT_APP_RECAPTCHA_OPTIONAL
);

export const DEFAULT_ERROR_MSG = `Something went wrong...`;
export const SYSTEM_ERROR_MSG = `Action can't be performed due to a system problem. Please try again later.`;

export const PAYMENT_COMPLETED_EVENT = `paymentCompleted`;