import { connect } from 'react-redux';

import { loggedUserDataSelector } from 'redux/selectors/loggedUser';
import { logout } from 'redux/actions/auth';

import UserSection from './UserSection';

const mapStateToProps = (state) => ({
  user: loggedUserDataSelector(state),
});

export default connect(
  mapStateToProps,
  { onLogout: logout }
)(UserSection);
