import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import { initStripe } from 'utils/stripeHandler.js'
import StripeCheckoutForm from './StripeCheckoutForm.js';
import { getElementsOptions } from './optionsCfg';

function StripeCheckoutDialog(props) {
  const { payment, clientSecret, checkConfig } = props;
  const { providerInvoiceNumber } = payment;

  const stripePromise = initStripe();
  const options = getElementsOptions({ clientSecret });

  useEffect(() => {
    checkConfig({ style: { padding: '20px 20px 0' }, removeClose: true,
      cfgTitle: `Pay bill #${providerInvoiceNumber}`
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeCheckoutForm {...props} />
    </Elements>
  );
}

StripeCheckoutDialog.propTypes = {
  patientId: PropTypes.number.isRequired,
  payment: PropTypes.object.isRequired,
  clientSecret: PropTypes.string.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  checkConfig: PropTypes.func.isRequired,
};

export default StripeCheckoutDialog;