import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Tooltip from 'components/Tooltip';

import styles from './styles.module.scss';

const tooltipPositionCfg = {
  1: 'left',
  4: 'right',
};

class MultiTabsFormProgress extends React.PureComponent {
  render() {
    const { activeKey, tabs } = this.props;
    const current = activeKey;
    const complete = tabs.map(({ key }) => key).filter((key) => key < current);
    const todo = tabs.map(({ key }) => key).filter((key) => key > current);
    return (
      <div className={styles.wrapper}>
        {tabs.map(({ hint, key }) => (
          <React.Fragment key={key}>
            {current === key ? (
              <div className={styles.tooltipWrapper}>
                <div className={styles.currentCircle} />
                <Tooltip
                  position={tooltipPositionCfg[current] || 'center'}
                  className={styles.tooltip}
                >
                  {hint}
                </Tooltip>
              </div>
            ) : todo.includes(key) ? (
              <div className={styles.todoCircle}>{key}</div>
            ) : (
              <div className={styles.completeCircle} />
            )}
            <span
              className={cn(
                styles.line,
                complete.includes(key) && styles.activeLine
              )}
            />
          </React.Fragment>
        ))}
      </div>
    );
  }
}

MultiTabsFormProgress.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.number.isRequired,
      hint: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  activeKey: PropTypes.number.isRequired,
};

export default MultiTabsFormProgress;
