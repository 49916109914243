import React from 'react';
import { ToastContainer as RawToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CloseIcon from './icons/CloseIcon';
import styles from './styles.module.scss';
import './override.scss';

const ToastContainer = () => (
  <RawToastContainer
    newestOnTop={false}
    transition={Slide}
    closeButton={() => <CloseIcon className={styles.closeIcon} />}
  />
);

export default React.memo(ToastContainer);
