import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import scrollToElement from 'scroll-to-element';
import { useSelector } from 'react-redux';

import { getNoteKey, initPageItems } from 'utils';
import { selectedDaySelector } from 'redux/selectors/selectedDay';
import useInfinityScroll from 'utils/useInfintyScroll';

import ClinicalNoteCard from 'components/ClinicalNoteCard';

import AsideCalendarPanel from './AsideCalendarPanel';
import styles from './styles.module.scss';

const MAX_ITEMS_TO_SHOW = 10;

function PatientDocumentation({
  documentation,
  schedules,
  onScheduleChange,
  patientProviderId,
  showDay,
  isMobile,
  viewed,
}) {
  const selectedDay = useSelector((state) =>
    selectedDaySelector(state, patientProviderId)
  );

  const sortedDocs = (documentation || []).sort(
    (a, b) => new Date(b.noteDate).getTime() - new Date(a.noteDate).getTime()
  );
  const defaultSelectedDay =
    sortedDocs.length > 0 ? new Date(sortedDocs[0].noteDate) : undefined;

  const findPageOffset = (day) =>
    day
      ? Math.max(
          0,
          sortedDocs.findIndex((d) => moment(d.noteDate).isSame(day, 'day'))
        )
      : 0;

  const selectedDays = selectedDay ? new Date(selectedDay) : defaultSelectedDay;
  const [pageOffset, setPageOffset] = useState(findPageOffset(selectedDay));
  const [itemsToShow, setItemsToShow] = useState(
    viewed
      ? initPageItems(viewed, MAX_ITEMS_TO_SHOW, sortedDocs)
      : Math.min(pageOffset + MAX_ITEMS_TO_SHOW, sortedDocs.length)
  );

  const groupedDocs = sortedDocs
    .filter((_, index) => index < itemsToShow)
    .reduce((res, doc) => {
      const group = res.find((r) => moment(doc.noteDate).isSame(r.date, 'day'));
      const groupToChange = group || { date: doc.noteDate, items: [] };
      groupToChange.items.push(doc);
      if (group) {
        return res.map((g) => {
          if (g.date === groupToChange.date) {
            return groupToChange;
          }
          return g;
        });
      }
      return [...res, groupToChange];
    }, []);

  const scrollToDay = (day) => {
    const newPageOffset = findPageOffset(day);
    const itemsToShowWithOffset = Math.min(newPageOffset + itemsToShow, sortedDocs.length);
    setPageOffset(newPageOffset);
    if ( itemsToShowWithOffset > itemsToShow) {
      setItemsToShow(itemsToShowWithOffset);
    }
  };

  const showDayHandler = (day) => {
    showDay(day);
    scrollToDay(day);
  };

  const showNext = () => {
    setItemsToShow(Math.min(itemsToShow + MAX_ITEMS_TO_SHOW, sortedDocs.length));
  };

  useInfinityScroll({
    limit: MAX_ITEMS_TO_SHOW,
    items: groupedDocs,
    onGetData: showNext,
  });

  useEffect(() => {
    const scrollOffset = isMobile ? -155 : -125;
    setTimeout(
      () => scrollToElement(`[data-scroll-id="${pageOffset}"]`, { offset: scrollOffset, duration: 400 }),
      200
    );
  }, [pageOffset]); // eslint-disable-line

  let rowIdx = 0;
  return (
    <div className={styles.wrapper}>
      <div id="leftBlock" className={styles.leftBlock}>
        {groupedDocs.length > 0 && (
          <>
            {groupedDocs.map((g) => (
              <div className={styles.group} key={g.date}>
                <div className={styles.groupTitle}>
                  {moment(g.date).format('DD MMMM YYYY')}
                </div>
                {g.items.map((doc) => (
                  <ClinicalNoteCard
                    key={getNoteKey(doc)}
                    dataId={getNoteKey(doc)}
                    scrollId={rowIdx++}
                    note={doc}
                    patientProviderId={patientProviderId}
                    className={styles.noteCard}
                  />
                ))}
              </div>
            ))}
          </>
        )}
      </div>
      <AsideCalendarPanel
        sortedDocs={sortedDocs}
        schedules={schedules}
        onScheduleChange={onScheduleChange}
        isMobile={isMobile}
        selectedDays={selectedDays}
        showDay={showDayHandler}
      />
    </div>
  );
}

PatientDocumentation.propTypes = {
  documentation: PropTypes.arrayOf(PropTypes.object),
  schedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  onScheduleChange: PropTypes.func.isRequired,
  patientProviderId: PropTypes.string.isRequired,
  showDay: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  viewed: PropTypes.string,
};

export default React.memo(PatientDocumentation);
