import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getTerms } from 'redux/actions/agreements';
import { invProviderIdSelector } from 'redux/selectors/auth';

import ShowTermsButton from './ShowTermsButton';

function ShowTermsButtonContainer(props) {
  const { authToken, ...rest } = props;
  const dispatch = useDispatch();
  const invProviderId = useSelector((state) => invProviderIdSelector(state));

  const filterAgreements = (data) => {
    const agreementsToSign = data.filter(({ signedOn }) => !signedOn);
    const agreements = agreementsToSign.filter(
      ({ providerId }) => providerId === invProviderId || !providerId
    );
    return agreements;
  };
  const loadAgreements = () => dispatch(getTerms({ authToken }));

  return (
    <ShowTermsButton
      loadAgreements={loadAgreements}
      filterAgreements={filterAgreements}
      {...rest}
    />
  );
}

ShowTermsButtonContainer.propTypes = {
  authToken: PropTypes.shape({}).isRequired,
};

export default ShowTermsButtonContainer;
