import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import { useMediaQuery } from 'react-responsive';

import Button from 'components/Button';
import { showErrorToast } from 'components/Toaster';
import ProviderPreview from 'components/ProviderPreview';
import { providerInfoSelector } from 'redux/selectors/providerInfo';
import { viewBill } from 'redux/actions/bills';
import { payBill, createSetupIntent } from 'redux/actions/billsPayment';
import { thousandSeparator } from 'utils';
import { SYSTEM_ERROR_MSG, PAYMENT_COMPLETED_EVENT } from 'consts';
import { subscribe, unsubscribe } from 'utils/event.js';
import {
  STRIPE_CHECKOUT_DIALOG,
  ELECTRONICALY_PAYMENT_DIALOG,
  ELECTRONICALY_PAYMENT_ISSUE_DIALOG
} from 'config/Dialog/consts';
import { showDialog } from 'redux/actions/dialog';

import styles from './styles.module.scss';
import dollar from './img/dollar_sign.svg';
import alertRed from './img/alert_red.svg';

function PatientBillCard({ billCard, scrollId, onRefresh }) {
  const {
    patientId,
    providerId,
    invoiceId,
    invoicePaidFlag,
    invoiceDueDate,
    billNumber,
    createdOn,
    balance,
    paymentAmount,
    status,
  } = billCard;

  const isSmallScreen = useMediaQuery({ maxWidth: 360 });
  const [loading, setLoading] = useState(false);

  const isInvoiceProcessing = status === 'PROCESSING';
  const isPaymentFailed = status === 'PAYMENT_FAILED';

  const provider = useSelector(
    (state) => providerInfoSelector(state, providerId),
    shallowEqual
  );

  const queryParams = new URLSearchParams(window.location.search);
  const urlInvoiceId = queryParams.get('invoiceId');

  useEffect(() => {
    if (urlInvoiceId === invoiceId.toString()) {
	  setLoading(true);
      const listener = (event) => {
        setLoading(false);
        unsubscribe(PAYMENT_COMPLETED_EVENT, listener);
      };
      subscribe(PAYMENT_COMPLETED_EVENT, listener);
      return () => {
        unsubscribe(PAYMENT_COMPLETED_EVENT, listener);
      };
    }
  }, [urlInvoiceId, invoiceId]);

  const dispatch = useDispatch();
  const viewInfo = () => dispatch(viewBill({ patientId, providerId, invoiceId, billNumber }));

  const showStripeCheckoutDialog = (payment, clientSecret, setupIntentId) => {
    setLoading(false);
    dispatch(showDialog(STRIPE_CHECKOUT_DIALOG, { payment, patientId, clientSecret, setupIntentId }));
  };

  const showElectronicallyPaymentDialog = (payment) => {
    setLoading(false);
    dispatch(showDialog(ELECTRONICALY_PAYMENT_DIALOG, { payment, patientId, onRefresh }));
  };

  const showElectronicallyPaymentNotSupported = () => {
    setLoading(false);
    dispatch(
      showDialog(ELECTRONICALY_PAYMENT_ISSUE_DIALOG, {
        message: `Provider doesn't accept electronic payments yet or at the moment. Please contact them.`,
      })
    );
  };

  const handlePayBill = (event) => {
    event.stopPropagation();
    const payBillPromise = dispatch(payBill({ patientId, invoiceId }));

    setLoading(true);
    payBillPromise
      .then((payment) => {
        const {
          tpSupportsElectronicPayments,
          hasPaymentMethod
        } = payment;

        if (!tpSupportsElectronicPayments) {
          showElectronicallyPaymentNotSupported();
        } else if (hasPaymentMethod) {
          showElectronicallyPaymentDialog(payment);
        } else {
          dispatch(createSetupIntent())
            .then(({ clientSecret, setupIntentId }) => {
              showStripeCheckoutDialog(payment, clientSecret, setupIntentId);
            })
            .catch((e) => {
              setLoading(false);
              showErrorToast({ content: e.message || SYSTEM_ERROR_MSG });
            });
        }
      })
      .catch((e) => {
        setLoading(false);
        showErrorToast({ content: e.message || SYSTEM_ERROR_MSG });
      });
  };

  return (
    <div
      className={cn(
          styles.wrapper,
          invoicePaidFlag && styles.paid,
          isInvoiceProcessing && styles.processing
      )}
      data-id={invoiceId}
      data-scroll-id={scrollId}
      onClick={viewInfo}
    >
      <div className={styles.billDataWrapper}>
        <div className={styles.providerWrapper}>
          <ProviderPreview
            provider={provider}
            customCutInfoStyle={styles.providerName}
          />
          <div className={styles.numberDateWrapper}>
            <div className={styles.billNumber}>#{billNumber}</div>
            <div className={styles.billDate}>
              {moment(createdOn).format('MM/DD/YY')}
            </div>
          </div>
        </div>

        <div className={styles.dueDateWrapper}>
          {moment(invoiceDueDate).format(
            isSmallScreen ? 'MM/DD/yyyy' : 'MMM D, YYYY'
          )}
        </div>

        <div className={styles.invoiceTotalWrapper}>
          <div className={styles.invoiceTotal}>
            <img src={dollar} alt={'dollar'} />
            <span>{thousandSeparator(invoicePaidFlag ? paymentAmount : balance)}</span>
            {isPaymentFailed &&
              <img src={alertRed} alt={'payment failed'} className={styles.alertRed}/>
            }
          </div>
        </div>

        {!invoicePaidFlag && !isInvoiceProcessing && (
          <Button
            className={styles.payBtn}
            loaderClassName={styles.payBtnLoader}
            type="button"
            onClick={handlePayBill}
            text="Pay"
            loading={loading}
          />
        )}
      </div>
    </div>
  );
}

PatientBillCard.propTypes = {
  billCard: Proptypes.shape().isRequired,
  scrollId: Proptypes.number.isRequired,
  onRefresh: Proptypes.func.isRequired,
};

export default React.memo(PatientBillCard);
