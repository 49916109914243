import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Button from 'components/Button';
import { signAgreement } from 'redux/actions/agreements';

import Tabs from './Tabs';
import styles from './styles.module.scss';

function AgreementsDialog(props) {
  const {
    agreements,
    onDialogClose,
    checkConfig,
    scrollToTop,
    signFn,
    reduxSignAction,
    declineFn,
    onCustomClose,
    hideTabs,
    hasClose,
  } = props;

  useEffect(() => {
    const removeClose = hasClose || agreements[0].type === 'TB' ? false : true;
    checkConfig({ style: { maxWidth: '810px' }, removeClose, onCustomClose });
    // eslint-disable-next-line
  }, [agreements]);

  const [selectedTabKey, changeTab] = useState(agreements[0].agreementId);
  const [loading, setLoading] = useState(false);
  const [agreementsRead, readAgreement] = useState([]);

  const agreementsToRead = agreements.length;

  const dispatch = useDispatch();
  const agreeAction = () => {
    const newAgreementsRead = [
      ...agreementsRead.filter((a) => a !== selectedTabKey),
      selectedTabKey,
    ];
    readAgreement(newAgreementsRead);
    if (newAgreementsRead.length < agreementsToRead) {
      const firstNotRead = agreements.filter(
        (a) => !newAgreementsRead.includes(a.agreementId)
      )[0].agreementId;
      scrollToTop();
      return changeTab(firstNotRead);
    }
    if (signFn) {
      signFn(
        agreements.map((a) => ({
          agreementId: a.agreementId,
          agreementPublishedOn: a.agreementPublishedOn,
        }))
      );
      return onDialogClose();
    }
    const reduxAction = reduxSignAction || signAgreement(selectedTabKey);
    const promise = dispatch(reduxAction);
    setLoading(true);
    return promise
      .then((data) => {
        onDialogClose();
        return data;
      })
      .finally(() => setLoading(false));
  };

  const declineAction = declineFn
    ? () => {
        declineFn();
        onDialogClose();
      }
    : {};

  return (
    <>
      <div className={styles.dialogContent}>
        <Tabs
          hideTabs={hideTabs ? hideTabs : Boolean(agreementsToRead === 1)}
          items={agreements.map((a) => ({
            content: a.agreementText,
            title: a.type === 'TB' ? 'therapyBOSS' : a.providerName,
            key: a.agreementId,
          }))}
          selectedKey={selectedTabKey}
          onChange={changeTab}
        />
      </div>
      <div className={styles.dialogLine} />
      <div className={styles.dialogButtons}>
        {declineFn && (
          <Button
            text="Decline"
            secondary={true}
            className={styles.leaveButton}
            onClick={declineAction}
          />
        )}
        <Button
          loading={loading}
          text={
            agreementsToRead > 1
              ? `Agree (${agreementsRead.length + 1} / ${agreementsToRead})`
              : `Agree`
          }
          className={styles.agreeButton}
          onClick={agreeAction}
        />
      </div>
    </>
  );
}

AgreementsDialog.propTypes = {
  agreements: PropTypes.arrayOf(
    PropTypes.shape({
      agreementId: PropTypes.string.isRequired,
      agreementText: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['TB', 'PROVIDER']).isRequired,
      providerName: PropTypes.string,
    })
  ).isRequired,
  signFn: PropTypes.func,
  reduxSignAction: PropTypes.object,
  declineFn: PropTypes.func,
  onDialogClose: PropTypes.func.isRequired,
  scrollToTop: PropTypes.func.isRequired,
  checkConfig: PropTypes.func.isRequired,
  onCustomClose: PropTypes.func,
  hideTabs: PropTypes.bool,
  hasClose: PropTypes.bool,
};

export default AgreementsDialog;
