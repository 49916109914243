import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';

import { cancelSetupIntent } from 'redux/actions/billsPayment';

import { BASE_URL } from 'consts';
import Button from 'components/Button';
import ErrorBar from 'components/ErrorBar';

import { paymentElementOptions } from './optionsCfg';
import styles from './styles.module.scss';

function StripeCheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState();

  const { payment, patientId, setupIntentId, onDialogClose } = props;
  const { providerInvoiceId } = payment;

  const handleResponseError = (submitError) => {
    if (submitError) {
      if (submitError.type !== 'validation_error') {
        setError(submitError.message);
      } else {
        setError(undefined);
      }
    }
  };

  const onDialogCancel = () => {
    dispatch(cancelSetupIntent({ setupIntentId }));
    onDialogClose();
  };

  const onDialogSubmit = async (event) => {
    event.preventDefault();
    if (submitting) return;

    const {error: submitError} = await elements.submit();
    if (submitError) {
      setSubmitting(false);
      handleResponseError(submitError);
      return;
    }

    setSubmitting(true);
    stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${BASE_URL}/bills?invoiceId=${providerInvoiceId}&patientId=${patientId}`,
      },
    })
    .then(({ error }) => {
      handleResponseError(error);
    })
    .catch(({ error }) => {
      handleResponseError(error);
    })
    .finally(() => setSubmitting(false));
  };

  return (
    <form>
      <PaymentElement options={paymentElementOptions} />
      {Boolean(error) && (
        <ErrorBar
          name="form"
          className={styles.errorBar}
          error={error}
        />
      )}
      <div className={styles.buttons}>
        <Button
          text="Cancel"
          secondary={true}
          className={styles.cancelButton}
          disabled={submitting}
          onClick={onDialogCancel}
        />
        <Button
          text="Submit"
          className={styles.payNowButton}
          disabled={!stripe || !elements}
          loading={submitting}
          onClick={onDialogSubmit}
        />
      </div>
    </form>
  );
}

StripeCheckoutForm.propTypes = {
  patientId: PropTypes.number.isRequired,
  payment: PropTypes.object.isRequired,
  setupIntentId: PropTypes.string.isRequired,
  onDialogClose: PropTypes.func.isRequired,
};

export default StripeCheckoutForm;
