import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

const convertStyle = (style) => {
  if (style) {
    const result = { ...style };
    if (style.top) {
      result.top = `${style.top}px`;
    }
    if (style.left) {
      result.left = `${style.left}px`;
    }
    return result;
  }
  return style;
};

class ProviderPopup extends React.PureComponent {
  state = {
    style: undefined,
    className: undefined,
  };

  componentDidMount() {
    this.adjustStyle();
    window.addEventListener('resize', this.adjustStyle);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustStyle);
    this.props.hideOverflow();
  }

  wrapper = React.createRef();

  adjustStyle = () => {
    const element = this.wrapper.current;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    //check if fits into viewport
    if (
      viewportWidth < 540 &&
      (viewportWidth <= element.offsetWidth ||
        viewportHeight <= element.offsetHeight)
    ) {
      this.props.showOverflow();
      return this.setState({
        style: undefined,
        className: styles.fullScreenWrapper,
      });
    }
    //adjust position
    const bounding = element.getBoundingClientRect();
    const stateStyle = this.state.style || {};
    const bottomOffset =
      bounding.bottom - (stateStyle.top || 0) - viewportHeight;
    const rightOffset = bounding.right - (stateStyle.left || 0) - viewportWidth;
    const style = {};
    if (bottomOffset > 0) {
      style.top = -bottomOffset;
    }
    if (rightOffset > 0) {
      style.left = -rightOffset;
    }
    return this.setState({ style, className: undefined });
  };

  render() {
    const { provider, onClose } = this.props;
    const { style, className } = this.state;

    return (
      <div
        ref={this.wrapper}
        className={cn(styles.wrapper, className)}
        style={convertStyle(style)}
        onClick={(e) => e.stopPropagation()}
      >
        <button type="button" onClick={onClose} className={styles.closeBtn} />
        <div className={styles.title}>{provider.name}</div>
        <div className={styles.data}>
          {Boolean(provider.address) && (
            <div className={styles.block}>
              <div className={styles.blockTitle}>Address:</div>
              <div className={styles.blockText}>{provider.address}</div>
            </div>
          )}
          {Boolean(provider.phone) && (
            <div className={styles.block}>
              <div className={styles.blockTitle}>Phone:</div>
              <a
                href={`tel:${provider.phone}`}
                className={cn(styles.blockText, styles.link)}
              >
                {provider.phone}
              </a>
            </div>
          )}
          {Boolean(provider.email) && (
            <div className={styles.block}>
              <div className={styles.blockTitle}>Email:</div>
              <a
                href={`mailto:${provider.email}`}
                className={cn(styles.blockText, styles.link)}
              >
                {provider.email}
              </a>
            </div>
          )}
          {Boolean(provider.hours) && (
            <div className={styles.block}>
              <div className={styles.blockTitle}>Hours of operation:</div>
              <div className={styles.blockText}>{provider.hours}</div>
            </div>
          )}
          {Boolean(provider.note) && (
            <div className={styles.block}>
              <div className={styles.blockTitle}>Note:</div>
              <div className={styles.blockText}>{provider.note}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ProviderPopup.propTypes = {
  provider: PropTypes.shape().isRequired,
  onClose: PropTypes.func.isRequired,
  showOverflow: PropTypes.func.isRequired,
  hideOverflow: PropTypes.func.isRequired,
};

export default ProviderPopup;
