import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { isDialogShownSelector } from 'redux/selectors/dialog';

import Dialog from './Dialog';

class DialogContainer extends React.PureComponent {
  render() {
    const { isShown } = this.props;
    if (!isShown) return null;
    return <Dialog />;
  }
}

DialogContainer.propTypes = {
  isShown: PropTypes.bool.isRequired,
};

const connectStore = connect((store) => ({
  isShown: isDialogShownSelector(store),
}));

export default connectStore(DialogContainer);
