import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { showDialog } from 'redux/actions/dialog';

function ShowDialogButton(props) {
  const {
    route,
    routeProps,
    loadInitialData,
    errorMsg,
    initialDataKey,
    onLoad,
    filterInitialData,
    ...rest
  } = props;
  const dispatch = useDispatch();

  const show = loadInitialData
    ? () => {
        return loadInitialData()
          .then((data) => {
            const dataToShow = filterInitialData
              ? filterInitialData(data)
              : data;
            onLoad();
            dispatch(
              showDialog(route, { ...routeProps, [initialDataKey]: dataToShow })
            );
            return data;
          })
          .catch(() => {
            onLoad(errorMsg);
          });
      }
    : () => dispatch(showDialog(route, routeProps));

  return <button onClick={show} {...rest} />;
}

ShowDialogButton.propTypes = {
  route: PropTypes.string.isRequired,
  routeProps: PropTypes.object,
  loadInitialData: PropTypes.func,
  initialDataKey: PropTypes.string,
  onLoad: PropTypes.func,
  filterInitialData: PropTypes.func,
  errorMsg: PropTypes.string,
};

export default ShowDialogButton;
