import React from 'react';
import PropTypes from 'prop-types';
import Reaptcha from 'reaptcha';
import { useMediaQuery } from 'react-responsive';

import { RECAPTCHA_KEY } from 'consts';

import styles from './styles.module.scss';

const Recaptcha = React.forwardRef(function RecaptchaWithoutForward(
  props,
  ref
) {
  const { onVerify, onLoad } = props;
  const isMobile = useMediaQuery({ maxWidth: 739 });

  return (
    <div className={styles.wrapper}>
      <Reaptcha
        ref={ref}
        key={isMobile}
        sitekey={RECAPTCHA_KEY}
        onLoad={onLoad}
        size="invisible"
        onVerify={onVerify}
        badge={isMobile ? 'inline' : 'bottomleft'}
      />
    </div>
  );
});

Recaptcha.propTypes = {
  onVerify: PropTypes.func.isRequired,
  onLoad: PropTypes.func,
};

export default Recaptcha;
