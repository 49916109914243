import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

class ErrorBar extends React.PureComponent {
  render() {
    const { error, name, className } = this.props;
    return (
      <div
        className={cn(styles.wrapper, className)}
        data-scroll-id={name}
        dangerouslySetInnerHTML={{ __html: error }}
      />
    );
  }
}

ErrorBar.propTypes = {
  className: PropTypes.string,
  error: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default ErrorBar;
