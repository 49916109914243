import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from 'components/Dropdown';

import styles from './styles.module.scss';

const STATUS = [
  { key: 'All', val: 'All' },
  { key: 'Paid', val: true },
  { key: 'Unpaid', val: false },
];

const FilteringBar = ({ onChange, years }) => {
  const YEARS_OPTIONS = [
    { key: 'All years', val: 'All years' },
    ...years
      .sort((a, b) => b - a)
      .map((year) => ({ key: `${year}`, val: `${year}` }))
  ];
  return (
    <div className={styles.wrapper}>
      <Dropdown type={'invoicePaidFlag'} options={STATUS} onChange={onChange} />
      <Dropdown
        type={'createdOn'}
        options={YEARS_OPTIONS}
        multiSelect={true}
        onChange={onChange}
      />
    </div>
  );
};

FilteringBar.propTypes = {
  onChange: PropTypes.func.isRequired,
  years: PropTypes.array.isRequired,
};

export default FilteringBar;
