import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'components/TextField';
import AuthLayout from 'layouts/AuthLayout';
import FormWrapper from 'layouts/AuthLayout/FormWrapper';
import Button from 'components/Button';
import Tooltip from 'components/Tooltip';
import ErrorBar from 'components/ErrorBar';
import { DEFAULT_ERROR_MSG } from 'consts';
import { scrollToError } from 'utils';

import styles from './styles.module.scss';

const MAX_RESEND_COUNT = 2;

const getFormErrors = ({ code }) => {
  const result = {};
  if (!code) {
    result['form'] = 'Please enter 6-digit code.';
  } else if (code.length !== 6) {
    result['form'] = 'Please enter valid 6-digit code.';
  }
  return Object.keys(result).length === 0 ? undefined : result;
};

class MFAPage extends React.PureComponent {
  state = {
    form: {
      code: '',
    },
    resendClickedCount: 0,
    loading: false,
    errors: {},
    resendPopupShown: false,
  };

  componentDidMount() {
    const { onSendCode } = this.props;
    onSendCode()
      .catch((e) => {
        this.addError('form', e.message || DEFAULT_ERROR_MSG);
        scrollToError('form');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  addError = (name, message) =>
    this.setState((state) => ({
      ...state,
      errors: { ...state.errors, [name]: message },
    }));

  onInputChange = (name) => ({ target: { value } }) =>
    this.setState((state) => ({
      ...state,
      form: { ...state.form, [name]: value },
      resendPopupShown: false,
      errors: {},
    }));

  returnLogin = (params) => {
    const { backLogin } = this.props;
    backLogin(params);
  };

  onSubmit = () => {
    const { onConfirm } = this.props;
    const { form } = this.state;
    const errors = getFormErrors(form);
    if (errors) {
      return this.setState({ errors }, () => {
        const [firstError] = Object.keys(errors);
        return scrollToError(firstError);
      });
    }
    this.setState({ errors: {}, loading: true });
    return onConfirm(form)
      .catch((e) => {
        let errorMsg = e.message || DEFAULT_ERROR_MSG;
        if (e.details) {
          if (
            ['BLOCKED', 'EXPIRED', 'ACCOUNT_DISABLED'].includes(e.details.cause)
          ) {
            return this.returnLogin({
              prevPath: '/mfa',
              ...(['BLOCKED'].includes(e.details.cause)
                ? { unblock: e.details.unblock }
                : {}),
              ...(['EXPIRED'].includes(e.details.cause)
                ? {
                    message: `Your 6-digit code has expired. Please log in again.`,
                  }
                : {}),
              ...(['ACCOUNT_DISABLED'].includes(e.details.cause)
                ? {
                    message: `Account is suspended. <a href="mailto:support@therapyboss.com">Email support</a>.`,
                  }
                : {}),
            });
          }
          if (['INVALID'].includes(e.details.cause)) {
            errorMsg = `Please enter valid 6-digit code.`;
          }
        }

        this.addError('form', errorMsg);
        scrollToError('form');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onResendCode = () => {
    const { onSendCode } = this.props;
    return this.state.resendClickedCount < MAX_RESEND_COUNT
      ? onSendCode().then(() =>
          this.setState(({ resendClickedCount, resendPopupShown }) => ({
            resendClickedCount: resendClickedCount + 1,
            resendPopupShown: !resendPopupShown,
          }))
        )
      : this.returnLogin({ prevPath: '/mfa' });
  };

  handleEnterPress = ({ key }) => key === 'Enter' && this.onSubmit();

  render() {
    const { form, errors, loading, resendPopupShown } = this.state;
    const { code } = form;
    return (
      <AuthLayout
        title="Login"
        note="Log in to the therapyBOSS patient portal."
      >
        <div className={styles.note}>
          For security, a text message was just sent to your mobile phone
          containing a 6-digit code. Enter this code below to continue.
        </div>
        <FormWrapper>
          {errors.form ? (
            <ErrorBar
              className={styles.errorBar}
              name="form"
              error={errors.form}
            />
          ) : (
            <div className={styles.errorWrapper} />
          )}
          <TextField
            name="code"
            className={styles.input}
            value={code}
            onChange={this.onInputChange('code')}
            onKeyDown={this.handleEnterPress}
            error={errors.code}
            placeholder="Enter here"
            label="6-Digit code"
            maxLength={6}
            type="number"
            mode="integer"
          />
          <div className={styles.hint}>
            Didn't get the text message?
            {!resendPopupShown ? (
              <>
                {' '}
                We can{' '}
                <span className={styles.hintLink} onClick={this.onResendCode}>
                  resend it
                </span>
              </>
            ) : (
              <Tooltip className={styles.tooltipBlock}>
                We sent you another text message
              </Tooltip>
            )}
          </div>
        </FormWrapper>
        <div className={styles.buttons}>
          <Button loading={loading} text="Continue" onClick={this.onSubmit} />
        </div>
      </AuthLayout>
    );
  }
}

MFAPage.propTypes = {
  onSendCode: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  backLogin: PropTypes.func.isRequired,
};

export default MFAPage;
