import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import InputMask from 'react-input-mask';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import styles from './styles.module.scss';

function isNormalInteger(str) {
  if (!str) {
    return false;
  }
  str = str.replace(/^0+/, '') || '0';
  const n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n >= 0;
}

class TextField extends React.Component {
  state = {
    passwordHidden: true,
  };

  toggleShow = () =>
    this.setState({ passwordHidden: !this.state.passwordHidden });

  handleChange = (e) => {
    const { onChange, mode } = this.props;
    if (
      mode === 'integer' &&
      e.target.value &&
      !isNormalInteger(e.target.value)
    ) {
      return;
    }
    return onChange(e);
  };

  handleNumberInputLimit = (e) => {
    if (e.target.value.length >= e.target.maxLength) {
      e.preventDefault();
    }
  };

  render() {
    const {
      type,
      label,
      error,
      mask,
      className,
      readOnly,
      disabled,
      name,
      ...rest
    } = this.props;
    const showHideEnabled = type === 'password';
    const { passwordHidden } = this.state;
    const globalInputProps = {
      readOnly,
      disabled,
      className: cn(
        styles.input,
        showHideEnabled && styles.passwordInput,
        error && styles.errorInput
      ),
      type: showHideEnabled && !passwordHidden ? 'text' : type,
      ...rest,
      onChange: this.handleChange,
      onKeyPress: type === 'number' ? this.handleNumberInputLimit : undefined,
    };

    return (
      <label
        className={cn(styles.wrapper, disabled && styles.disabled, className)}
        data-scroll-id={name}
      >
        <span className={styles.label}>{label}</span>
        {mask ? (
          <InputMask {...globalInputProps} mask={mask} />
        ) : (
          <input {...globalInputProps} />
        )}
        {showHideEnabled && (
          <div className={styles.iconWrapper}>
            <FontAwesomeIcon
              className={styles.passwordIcon}
              icon={passwordHidden ? faEye : faEyeSlash}
              onClick={this.toggleShow}
            />
          </div>
        )}
        {Boolean(error) && <div className={styles.errorMsg}>{error}</div>}
      </label>
    );
  }
}

TextField.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  mask: PropTypes.string,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  mode: PropTypes.oneOf(['integer']),
  name: PropTypes.string.isRequired,
};
export default TextField;
