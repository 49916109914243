import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';

import {
  GET_SCHEDULES_REQUEST,
  GET_SCHEDULES_SUCCESS,
  GET_SCHEDULES_FAILURE,
  CONFIRM_APPOINTMENT_REQUEST,
  CONFIRM_APPOINTMENT_SUCCESS,
  CONFIRM_APPOINTMENT_FAILURE,
} from 'redux/consts/schedules';

export function getSchedules(patientProviderId) {
  return {
    type: GET_SCHEDULES_REQUEST,
    patientProviderId,
    [WAIT_FOR_ACTION]: GET_SCHEDULES_SUCCESS,
    [ERROR_ACTION]: GET_SCHEDULES_FAILURE,
  };
}
export function onGetSchedulesSuccess({ payload, patientProviderId }) {
  return {
    type: GET_SCHEDULES_SUCCESS,
    patientProviderId,
    payload,
  };
}

export function onGetSchedulesFailure({ error, patientProviderId }) {
  return {
    type: GET_SCHEDULES_FAILURE,
    patientProviderId,
    error,
  };
}

export function confirmAppointment(scheduleId) {
  return {
    type: CONFIRM_APPOINTMENT_REQUEST,
    scheduleId,
    [WAIT_FOR_ACTION]: CONFIRM_APPOINTMENT_SUCCESS,
    [ERROR_ACTION]: CONFIRM_APPOINTMENT_FAILURE,
  };
}
export function onConfirmAppointmentSuccess() {
  return {
    type: CONFIRM_APPOINTMENT_SUCCESS,
  };
}

export function onConfirmAppointmentFailure({ error }) {
  return {
    type: CONFIRM_APPOINTMENT_FAILURE,
    error,
  };
}
