import { connect } from 'react-redux';

import { sendMFACode, confirmMFACode, backLogin } from 'redux/actions/mfa';

import MFAPage from './MFAPage';

const connectStore = connect(
  null,
  { onSendCode: sendMFACode, onConfirm: confirmMFACode, backLogin }
);

export default connectStore(MFAPage);
