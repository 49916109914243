import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import persistedDataReducer from './persistedData';
import sessionDataReducer from './sessionData';
import memoryDataReducer from './memoryData';

export default (history) =>
  combineReducers({
    persistedData: persistedDataReducer,
    sessionData: sessionDataReducer,
    memoryData: memoryDataReducer,
    router: connectRouter(history),
  });
