import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

import Popup from 'components/Popup';

import UserPreview from './UserPreview';

import styles from './styles.module.scss';
import QuickLinks from './QuickLinks';

class UserSection extends React.PureComponent {
  state = { popupShown: false };

  toggleMenu = () =>
    this.setState((state) => ({ popupShown: !state.popupShown }));

  hideMenu = () => this.setState({ popupShown: false });

  popupItems = [
    { key: 'profile', title: `My profile`, to: '/profile' },
    {
      key: 'logout',
      separator: true,
      title: `Log out`,
      onClick: this.props.onLogout,
    },
  ];

  render() {
    const { popupShown } = this.state;
    const { user } = this.props;
    return (
      <div className={styles.wrapper}>
        <QuickLinks />
        <UserPreview user={user} onClick={this.toggleMenu} />
        {popupShown && (
          <Popup
            className={styles.popup}
            items={this.popupItems}
            onClick={this.hideMenu}
          />
        )}
      </div>
    );
  }
}

UserSection.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  onLogout: PropTypes.func.isRequired,
};

export default onClickOutside(UserSection, {
  handleClickOutside: ({ hideMenu }) => hideMenu,
});
