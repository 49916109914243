import { API_ROOT } from 'consts';

export default (mode, html, title) => {
  return () => {
    const formExists = document.getElementsByName("pdfForm").length > 0;
    const form = formExists
      ? document.getElementsByName("pdfForm")[0]
      : document.createElement('FORM');
    form.name = 'pdfForm';
    form.method = 'POST';
    form.target = '_blank';
    form.action = `${API_ROOT}/pdf-template/${mode}/${title.toUpperCase()}.pdf`;

    const htmlInput = formExists
      ? document.getElementsByName("htmlToPdf")[0]
      : document.createElement('INPUT');
    htmlInput.setAttribute("type", "hidden");
    htmlInput.setAttribute("name", "htmlToPdf");
    htmlInput.setAttribute("value", html);

    const fileNameInput = formExists
        ? document.getElementsByName("fileName")[0]
        : document.createElement('INPUT');
    fileNameInput.setAttribute("type", "hidden");
    fileNameInput.setAttribute("name", "fileName");
    fileNameInput.setAttribute("value", title.toUpperCase());

    if (!formExists) {
      form.appendChild(htmlInput);
      form.appendChild(fileNameInput);
      document.body.appendChild(form);
    }

    form.submit();
  };
};
