import { WITH_LOGGED_USER_ID } from 'redux/consts/global';
import { loggedUserSelector } from 'redux/selectors/loggedUser';

export default (store) => (next) => (action) => {
  if (action[WITH_LOGGED_USER_ID]) {
    const newAction = {
      ...action,
      loggedUserId: loggedUserSelector(store.getState()),
    };
    return next(newAction);
  }
  return next(action);
};
