import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

const createAction = (isCancel, onClose, action) =>
  isCancel
    ? () => {
        const result = action && action();
        onClose();
        return result;
      }
    : () =>
        action().then((data) => {
          onClose();
          return data;
        });

class InfoDialog extends React.PureComponent {
  componentDidMount() {
    const { checkConfig } = this.props;
    checkConfig({ removeClose: true });
  }
  render() {
    const { description, actions, onDialogClose } = this.props;
    return (
      <div className={styles.wrapper}>
        <div className={styles.description}>{description}</div>
        <div
          className={cn(
            styles.actions,
            actions.length === 2 && styles.horizontalActions
          )}
        >
          {actions.map(({ label, isCancel, src, action, type = 'primary' }) =>
            src ? (
              <a
                href={src}
                key={label}
                target="_blank"
                rel="noopener noreferrer"
                className={cn(styles.btn, styles[`${type}Btn`])}
                onClick={onDialogClose}
              >
                {label}
              </a>
            ) : (
              <button
                key={label}
                className={cn(styles.btn, styles[`${type}Btn`])}
                onClick={createAction(isCancel, onDialogClose, action)}
              >
                {label}
              </button>
            )
          )}
        </div>
      </div>
    );
  }
}

InfoDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      isCancel: PropTypes.bool,
      action: PropTypes.func,
      type: PropTypes.oneOf(['primary', 'danger', 'secondary']),
    })
  ).isRequired,
  onDialogClose: PropTypes.func,
  checkConfig: PropTypes.func.isRequired,
};

export default InfoDialog;
