import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import PatientBillCard from 'components/PatientBillCard';
import { initPageItems } from 'utils';
import useInfinityScroll from 'utils/useInfintyScroll';

import styles from './styles.module.scss';

const MAX_BILLS_TO_SHOW = 10;

const BillsList = ({ bills, viewed, onRefresh }) => {
  const [billsToShow, setBillsToShow] = useState(
    viewed ? initPageItems(viewed, MAX_BILLS_TO_SHOW, bills) : MAX_BILLS_TO_SHOW
  );
  const [pageOffset, setPageOffset] = useState(null);

  const groupedBills = bills
    .filter((_, index) => index >= pageOffset)
    .filter((_, index) => index < billsToShow)
    .reduce((res, bill) => {
      const group = res.find((r) => moment(bill.createdOn).isSame(r.date, 'year'));
      const groupToChange = group || { date: bill.createdOn, bills: [] };
      groupToChange.bills.push(bill);
      if (group) {
        return res.map((g) => {
          if (g.date === groupToChange.date) {
            return groupToChange;
          }
          return g;
        });
      }
      return [...res, groupToChange];
    }, []);

  const showNext = () => {
    const newbillsToShow = Math.min(billsToShow + MAX_BILLS_TO_SHOW, bills.length);
    if (newbillsToShow > bills.length) {
      setPageOffset(pageOffset + (newbillsToShow - bills.length));
    }
    setBillsToShow(Math.min(newbillsToShow, bills.length));
  };

  useInfinityScroll({
    limit: MAX_BILLS_TO_SHOW,
    items: groupedBills,
    onGetData: showNext,
  });

  let rowIdx = 0;
  return (
    <>
      {groupedBills.map((g, index) => (
        <div className={styles.group} key={index}>
          <div className={styles.groupTitle}>
            {moment(g.date).format('YYYY')}
          </div>
          {g.bills.map((bill) => (
            <PatientBillCard
              key={bill.invoiceId}
              billCard={bill}
              scrollId={rowIdx++}
              onRefresh={onRefresh}
            />
          ))}
        </div>
      ))}
    </>
  );
};

BillsList.propTypes = {
  bills: PropTypes.array.isRequired,
  onRefresh: PropTypes.func.isRequired,
  viewed: PropTypes.string,
};

export default React.memo(BillsList);
