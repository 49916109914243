import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import PropTypes from 'prop-types';

import Button from 'components/Button';
import ErrorBar from 'components/ErrorBar'
import PaymentMethod from 'components/PaymentMethod';
import { thousandSeparator } from 'utils';
import { SYSTEM_ERROR_MSG } from 'consts';
import { createAndConfirmPaymentIntent } from 'utils/stripeHandler.js'
import { loadPaymentMethod } from 'redux/actions/billsPayment';

import styles from './styles.module.scss';

function ElectronicallyPaymentDialog(props) {
  const [ error, setError ] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [ paymentMethod, setPaymentMethod ] = useState();
  const { payment, patientId, onRefresh, onDialogClose, checkConfig } = props;
  const {
    providerInvoiceId,
    providerInvoiceNumber,
    providerName,
    invoiceAmount: { totalAmount, paymentFee, paymentDiscount }
  } = payment;

  const dispatch = useDispatch();
  useEffect(() => {
    checkConfig({ style: { padding: '20px 20px 0' }, removeClose: true,
      cfgTitle: `Pay bill #${providerInvoiceNumber}`
    });

    dispatch(loadPaymentMethod())
      .then((data) => {
        setPaymentMethod(data);
      })
      .catch((e) => {
        setError(e.message || SYSTEM_ERROR_MSG);
      })
    // eslint-disable-next-line
  }, []);

  const calculateDiscountAndFee = () => {
    const { earlyPayDiscount } = paymentDiscount;
    const { latePayFee, creditCardPayFee, achPayFee } = paymentFee;

    const discountAndFee = earlyPayDiscount
        ? `$${thousandSeparator(earlyPayDiscount.amount)} pay early discount`
        : latePayFee ? `$${thousandSeparator(latePayFee.amount)} late fee` : undefined;

    const processingFee = creditCardPayFee
        ? `$${thousandSeparator(creditCardPayFee.amount)} processing fee`
        : achPayFee ? `$${thousandSeparator(achPayFee.amount)} processing fee` : undefined;

    if (discountAndFee || processingFee) {
      return ` (includes ${discountAndFee || ''}` +
        `${discountAndFee && processingFee ? ' and ' : ''}` +
        `${processingFee || ''})`;
    }
  };

  const onPayNowHandler = () => {
    if (submitting) return;

    setSubmitting(true);
    const params = { patientId, invoiceId: providerInvoiceId };
    createAndConfirmPaymentIntent(dispatch, params, onRefresh);

    setTimeout(() => {
      setSubmitting(false);
      onDialogClose();
    }, 1000 * 20);
  };

  const canPayNow = paymentMethod && paymentMethod.status === 'SUCCEEDED';
  return (
    <>
      <div className={styles.description}>
        I authorize {providerName} to charge ${thousandSeparator(totalAmount)}{calculateDiscountAndFee()} to my payment method.
      </div>
      {error && (
        <ErrorBar
          className={styles.paymentMethod}
          error={error}
        />
      )}
      {paymentMethod &&
        <PaymentMethod
          className={styles.paymentMethod}
          payment={paymentMethod}
        />
      }
      <div className={styles.buttons}>
        <Button
          text="No"
          secondary={true}
          className={styles.cancelButton}
          onClick={onDialogClose}
        />
        <Button
          text="Pay now"
          className={styles.payNowButton}
          disabled={!canPayNow}
          loading={submitting}
          onClick={onPayNowHandler}
        />
      </div>
    </>
  );
}

ElectronicallyPaymentDialog.propTypes = {
  patientId: PropTypes.number.isRequired,
  payment: PropTypes.shape().isRequired,
  onRefresh: PropTypes.func.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  checkConfig: PropTypes.func.isRequired,
};

export default ElectronicallyPaymentDialog;
