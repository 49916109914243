import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from 'components/Button';

import styles from './styles.module.scss';

class CancelRequestDialog extends React.PureComponent {
  state = { loading: false };

  componentDidMount() {
    const { checkConfig } = this.props;
    checkConfig({ removeClose: true });
  }

  onCancelRequest = () => {
    const { token, onDialogClose, onCancelRequest } = this.props;
    this.setState({ loading: true });
    onCancelRequest(token).finally(() => {
      this.setState({ loading: false });
      onDialogClose();
    });
  };

  render() {
    const { onDialogClose } = this.props;
    const { loading } = this.state;
    return (
      <>
        <div className={styles.note}>
          Canceling your request for patient access will close it with the
          provider and will not be processed by them.
        </div>
        <div className={styles.buttons}>
          <Button
            text="No, leave it"
            secondary={true}
            className={styles.button}
            onClick={onDialogClose}
          />
          <Button
            text="Yes, cancel"
            loading={loading}
            className={cn(styles.button, styles.cancelButton)}
            onClick={this.onCancelRequest}
          />
        </div>
      </>
    );
  }
}

CancelRequestDialog.propTypes = {
  onDialogClose: PropTypes.func.isRequired,
  checkConfig: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  onCancelRequest: PropTypes.func.isRequired,
};

export default CancelRequestDialog;
