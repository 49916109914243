import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import Popup from 'components/Popup';

import PatientProviderItem from '../PatientProviderItem';

import styles from './styles.module.scss';

class PatientProvidersDropdown extends React.PureComponent {
  state = { popupShown: false };

  toggleMenu = () =>
    this.setState((state) => ({ popupShown: !state.popupShown }));

  hideMenu = () => this.setState({ popupShown: false });

  selectPatientProvider = (pp) => {
    const { onSelectPatientProviderId } = this.props;
    return onSelectPatientProviderId(pp.patientProviderId);
  };

  render() {
    const { selectedPatientProvider, patientProviders } = this.props;
    const { popupShown } = this.state;
    return (
      <div className={styles.wrapper}>
        <div className={styles.dropdown} onClick={this.toggleMenu}>
          <FontAwesomeIcon
            className={styles.arrow}
            icon={popupShown ? faChevronUp : faChevronDown}
          />
          <div className={styles.title}>
            <PatientProviderItem patientProvider={selectedPatientProvider} />
          </div>
        </div>
        {popupShown && (
          <Popup
            className={styles.popup}
            items={patientProviders.map((pp) => ({
              key: pp.patientProviderId,
              title: `${pp.patient.firstName} ${pp.patient.lastName} / ${
                pp.providerName
              }`,
              onClick: () => this.selectPatientProvider(pp),
              checked:
                pp.patientProviderId ===
                selectedPatientProvider.patientProviderId,
            }))}
            onClick={this.hideMenu}
          />
        )}
      </div>
    );
  }
}

PatientProvidersDropdown.propTypes = {
  selectedPatientProvider: PropTypes.shape({}).isRequired,
  patientProviders: PropTypes.arrayOf(PropTypes.shape({}).isRequired)
    .isRequired,
  onSelectPatientProviderId: PropTypes.func.isRequired,
};

export default onClickOutside(PatientProvidersDropdown, {
  handleClickOutside: ({ hideMenu }) => hideMenu,
});
