import { connect } from 'react-redux';

import { cancelPendingProvidersRequest } from 'redux/actions/providersRequests';

import CancelRequestDialog from './CancelRequestDialog';

const connectStore = connect(
  null,
  { onCancelRequest: cancelPendingProvidersRequest }
);

export default connectStore(CancelRequestDialog);
