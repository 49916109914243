import React from 'react';
import PropTypes from 'prop-types';
import validator from 'validator';
import cn from 'classnames';

import Recaptcha from 'components/Recaptcha';
import TextField from 'components/TextField';
import AuthLayout from 'layouts/AuthLayout';
import FormWrapper from 'layouts/AuthLayout/FormWrapper';
import Button from 'components/Button';
import { scrollToError } from 'utils';

import styles from './styles.module.scss';

const getFormErrors = ({ email }) => {
  const result = {};
  if (!email) {
    result['email'] = 'Email is required';
  } else if (!validator.isEmail(email)) {
    result['email'] = 'Email is invalid';
  }
  return Object.keys(result).length === 0 ? undefined : result;
};

class ForgetPasswordPage extends React.PureComponent {
  state = {
    form: {
      email: this.props.email || '',
    },
    loading: false,
    errors: {},
    success: false,
  };

  recaptchaRef = React.createRef();

  executeRecaptcha = () => {
    this.recaptchaRef.current.execute();
  };

  addError = (name, message) =>
    this.setState((state) => ({
      ...state,
      errors: { ...state.errors, [name]: message },
    }));

  onInputChange = (name) => ({ target: { value } }) =>
    this.setState((state) => ({
      ...state,
      form: { ...state.form, [name]: value },
      errors: { ...state.errors, [name]: undefined },
    }));

  executeResetPassword = (recaptcha) => {
    const { form } = this.state;
    const { onResetPassword, isDisabled } = this.props;
    return onResetPassword({
      ...form,
      recaptcha,
      captchaDisabled: isDisabled,
    }).finally(() => this.setState({ loading: false, success: true }));
  };

  onVerifyCaptcha = (recaptcha) => {
    this.setState({ loading: true });
    return this.executeResetPassword(recaptcha);
  };

  onSubmit = () => {
    const { isDisabled } = this.props;
    const { form } = this.state;
    const errors = getFormErrors(form);
    if (errors) {
      const [firstError] = Object.keys(errors);
      scrollToError(firstError);
      return this.setState({ errors });
    }
    this.setState({ errors: {} });
    if (isDisabled) {
      return this.executeResetPassword();
    }
    this.executeRecaptcha();
  };

  handleEnterPress = ({ key }) => key === 'Enter' && this.onSubmit();

  render() {
    const { isDisabled } = this.props;
    const { form, errors, success, loading } = this.state;
    const { email } = form;
    return (
      <AuthLayout
        title="Reset Password"
        note="Reset your password to access the therapyBOSS patient portal."
      >
        <FormWrapper>
          <div className={styles.note}>
            <span className={cn(success && styles.noteHidden)}>
              Enter your account's email address. We'll send a reset link. If
              not received within a minute, check your Spam.
            </span>
            {success && <div className={styles.info}>Email was sent.</div>}
          </div>

          <TextField
            name="email"
            value={email}
            onChange={this.onInputChange('email')}
            onKeyDown={this.handleEnterPress}
            error={errors['email']}
            placeholder="Enter here"
            type="email"
            autoComplete="on"
            label="Email"
            maxLength={50}
          />
        </FormWrapper>
        {!success && (
          <div className={styles.buttons}>
            <Button loading={loading} text="Proceed" onClick={this.onSubmit} />
          </div>
        )}
        {!isDisabled && (
          <Recaptcha ref={this.recaptchaRef} onVerify={this.onVerifyCaptcha} />
        )}
      </AuthLayout>
    );
  }
}

ForgetPasswordPage.propTypes = {
  onResetPassword: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  email: PropTypes.string,
};

export default ForgetPasswordPage;
