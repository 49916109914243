import { stringify } from 'query-string';

import { put, select, call } from 'redux-saga/effects';

import {
  onRefreshTokenSuccess,
  onRefreshTokenFailure,
  logout,
} from 'redux/actions/auth';
import { tokenSelector, temporaryTokenSelector } from 'redux/selectors/auth';
import { BASIC_AUTH_TOKEN } from 'consts';

import apiRequest from './apiRequest';

export default function* refreshToken(isTemporary = false) {
  try {
    const token = yield select(
      isTemporary ? temporaryTokenSelector : tokenSelector
    );
    if (!token) {
      const error = { error: 'No token specified' };
      throw error;
    }
    const url = `/oauth/token`;
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
      },
      body: stringify({
        grant_type: 'refresh_token',
        refresh_token: token.refresh_token,
      }),
    };
    const payload = yield call(apiRequest, url, options);
    yield put(onRefreshTokenSuccess({ payload, isTemporary }));
    return payload;
  } catch (error) {
    yield put(logout());
    yield put(onRefreshTokenFailure({ error }));
    return null;
  }
}
