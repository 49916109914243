import { SHOW_POPUP, HIDE_POPUP } from 'redux/consts/popup';

export function showPopup(route, props) {
  return {
    type: SHOW_POPUP,
    route,
    props,
  };
}

export function hidePopup({ closeAll } = {}) {
  return {
    type: HIDE_POPUP,
    closeAll,
  };
}
