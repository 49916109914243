import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

function Tooltip({ className, position, children }) {
  return (
    <div
      className={cn(styles.wrapper, styles[`position-${position}`], className)}
    >
      {children}
    </div>
  );
}

Tooltip.defaultProps = {
  position: 'center',
};

Tooltip.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  position: PropTypes.oneOf(['center', 'left', 'right']),
};

export default Tooltip;
