import { WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RECOVERY_PASSWORD_REQUEST,
  RECOVERY_PASSWORD_SUCCESS,
} from 'redux/consts/resetPassword';

export function onResetPasswordRequest(data) {
  return {
    type: RESET_PASSWORD_REQUEST,
    data,
    [WAIT_FOR_ACTION]: RESET_PASSWORD_SUCCESS,
  };
}

export function onResetPasswordSuccess({ payload }) {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload,
  };
}

export function onRecoveryPasswordRequest(data) {
  return {
    type: RECOVERY_PASSWORD_REQUEST,
    data,
    [WAIT_FOR_ACTION]: RECOVERY_PASSWORD_SUCCESS,
  };
}

export function onRecoveryPasswordSuccess({ payload }) {
  return {
    type: RECOVERY_PASSWORD_SUCCESS,
    payload,
  };
}
