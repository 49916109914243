import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
import moment from 'moment';

import { IS_RECAPTCHA_OPTIONAL } from 'consts';
import { auth } from 'redux/actions/auth';

import LoginPage from './LoginPage';

function LoginPageContainer(props) {
  let error = '';
  const dispatch = useDispatch();
  const onSubmit = (...args) => dispatch(auth(...args));

  const routerSearch = props.location.search || '';
  const prevPath = props.location.prevPath || '';
  const unblock = props.location.unblock || '';
  const message = props.location.message || '';

  const searchParams = parse(routerSearch) || {};
  const { passwordChanged, captchaDisabled } = searchParams;
  const disabled =
    captchaDisabled && JSON.parse(captchaDisabled) ? true : false;

  const firstNote = `You already have an account. Please log in and and go to your profile if needing to confirm access to patient information.`;
  const secondNote = `If not yet registered, contact the healthcare provider to have them invite you. If they already did, check your email for instructions.`;
  const infoNote = prevPath === '/registration' ? firstNote : secondNote;

  if (prevPath === '/recovery-password') error = message;
  if (prevPath === '/mfa') {
    if (unblock) {
      error = `Too many failed attempts. Try again at ${moment()
        .add(parseInt(unblock), 'minutes')
        .format('LT')}.`;
    } else if (message) {
      error = message;
    } else {
      error = 'Please log in again.';
    }
  }

  const info = passwordChanged
    ? 'Please log in using your new password.'
    : undefined;

  return (
    <LoginPage
      infoNote={infoNote}
      error={error}
      info={info}
      captchaDisabled={disabled && IS_RECAPTCHA_OPTIONAL}
      onSubmit={onSubmit}
    />
  );
}

LoginPageContainer.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
    prevPath: PropTypes.string,
    unblock: PropTypes.string,
    message: PropTypes.string,
  }).isRequired,
};

export default withRouter(LoginPageContainer);
