import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';

import { CLINICAL_NOTE } from 'redux/consts/downloadPdf';
import useGeneratePdf from 'utils/useGeneratePdf';
import MainLayout from 'layouts/MainLayout';
import SafesrcDocIframe from 'components/SafesrcDocIframe';
import { loadDoc } from 'redux/actions/docs';
import { getNoteKey } from 'utils';
import GroupedLinkButtons from 'components/GroupedLinkButtons';

import styles from './styles.module.scss';

const headerProps = { includePatientsDropdown: false };

function ENotePage(props) {
  const { noteParams, noteTitle } = props;

  const [html, setHtml] = useState();
  const [parsedHTML, setParsedHTML] = useState();

  const dispatch = useDispatch();
  const getParsedHTML = (value) => setParsedHTML(value);

  const handlePdfDownload = useGeneratePdf(CLINICAL_NOTE, parsedHTML, noteTitle);

  useEffect(() => {
    dispatch(loadDoc({ noteParams })).then((html) => {
      setHtml(html);
      return html;
    });
  }, [noteParams, dispatch]);

  return (
    <>
      {Boolean(noteTitle) && (
        <Helmet>
          <title>{`therapyBOSS | ${noteTitle}`}</title>
        </Helmet>
      )}
      <MainLayout headerProps={headerProps}>
        <div className={styles.wrapper}>
          <GroupedLinkButtons
            linkName={`Back to notes`}
            linkTo={{
              pathname: `/home`,
              search: `highlighted=${getNoteKey(noteParams)}`,
              viewed: getNoteKey(noteParams),
            }}
            buttons={[{ label: 'Download', onClick: handlePdfDownload }]}
          />

          {Boolean(html) && (
            <SafesrcDocIframe
              dataTestName={'noteContent'}
              width={740}
              srcDoc={html}
              getParsedHTML={getParsedHTML}
            />
          )}
        </div>
      </MainLayout>
    </>
  );
}

ENotePage.propTypes = {
  noteParams: PropTypes.shape({}).isRequired,
  noteTitle: PropTypes.string,
};

export default React.memo(ENotePage);
