import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';

import {
  SIGN_AGREEMENT_REQUEST,
  SIGN_AGREEMENT_SUCCESS,
  SIGN_AGREEMENT_FAILURE,
  GET_TERMS_REQUEST,
  GET_TERMS_SUCCESS,
  GET_TERMS_FAILURE,
  SIGN_TB_AGREEMENT_REQUEST,
  SIGN_TB_AGREEMENT_SUCCESS,
  SIGN_TB_AGREEMENT_FAILURE,
  CHECK_PROVIDER_AGREEMENT_REQUEST,
  CHECK_PROVIDER_AGREEMENT_SUCCESS,
  CHECK_PROVIDER_AGREEMENT_FAILURE,
} from 'redux/consts/agreements';

export function signAgreement(id) {
  return {
    type: SIGN_AGREEMENT_REQUEST,
    id,
    [WAIT_FOR_ACTION]: SIGN_AGREEMENT_SUCCESS,
    [ERROR_ACTION]: SIGN_AGREEMENT_FAILURE,
  };
}

export function onSignAgreementSuccess({ payload }) {
  return {
    type: SIGN_AGREEMENT_SUCCESS,
    payload,
  };
}

export function getTerms({ authToken }) {
  return {
    type: GET_TERMS_REQUEST,
    authToken,
    [WAIT_FOR_ACTION]: GET_TERMS_SUCCESS,
    [ERROR_ACTION]: GET_TERMS_FAILURE,
  };
}

export function onGetTermsSuccess({ payload }) {
  return {
    type: GET_TERMS_SUCCESS,
    payload,
  };
}

export function onGetTermsFailure({ error }) {
  return {
    type: GET_TERMS_FAILURE,
    error,
  };
}

export function signTBAgreement({ authToken, agreementId }) {
  return {
    type: SIGN_TB_AGREEMENT_REQUEST,
    authToken,
    agreementId,
    [WAIT_FOR_ACTION]: SIGN_TB_AGREEMENT_SUCCESS,
    [ERROR_ACTION]: SIGN_TB_AGREEMENT_FAILURE,
  };
}

export function onSignTBAgreementSuccess({ payload }) {
  return {
    type: SIGN_TB_AGREEMENT_SUCCESS,
    payload,
  };
}

export function checkProviderAgreement(providerId) {
  return {
    type: CHECK_PROVIDER_AGREEMENT_REQUEST,
    providerId,
    [WAIT_FOR_ACTION]: CHECK_PROVIDER_AGREEMENT_SUCCESS,
    [ERROR_ACTION]: CHECK_PROVIDER_AGREEMENT_FAILURE,
  };
}

export function onCheckProviderAgreementSuccess({ payload }) {
  return {
    type: CHECK_PROVIDER_AGREEMENT_SUCCESS,
    payload,
  };
}

export function onCheckProviderAgreementFailure({ error }) {
  return {
    type: CHECK_PROVIDER_AGREEMENT_FAILURE,
    error,
  };
}
