import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import moment from 'moment';
import { useMediaQuery } from 'react-responsive';

import NameColorIcon from 'components/NameColorIcon';
import { viewDoc } from 'redux/actions/docs';

import useShowPopup from 'utils/useShowPopup';
import { POPUP_VIEW } from 'config/Popup/consts';

import styles from './styles.module.scss';

function ClinicalNoteCard(props) {
  const { patientProviderId, note, className, dataId, scrollId } = props;

  const isSmallScreen = useMediaQuery({ maxWidth: 360 });

  const dispatch = useDispatch();
  const viewNote = () => dispatch(viewDoc({ note, patientProviderId }));

  const clinicianFirstName = note.clinicianFirstName || '';
  const clinicianLastName = note.clinicianLastName || '';
  const clinicianDisciplineCode = note.clinicianDisciplineCode || '';

  const clinician = `${clinicianFirstName} ${clinicianLastName[0]}.`;
  const clinicianInitials = `${clinicianFirstName[0]}${clinicianLastName[0]}`;

  const showClinicianInfoPopup = useShowPopup({
    popup: POPUP_VIEW.popup.clinicianInfoPopup,
  });

  return (
    <div
      className={cn(styles.wrapper, note.viewed && styles.viewed, className)}
      onClick={viewNote}
      data-id={dataId}
      data-scroll-id={scrollId}
    >
      <div className={styles.title}>{note.noteTitle}</div>
      <div className={styles.body}>
        <div className={styles.clinicianWrapper}>
          <NameColorIcon
            className={styles.clinicianIcon}
            text={clinicianInitials}
            onClick={(e) => {
              e.stopPropagation();
              showClinicianInfoPopup({ clinicianFirstName,  clinicianLastName, clinicianDisciplineCode });
            }}
          />
          <div className={cn(styles.clinician, styles.cutInfo)}>
            {clinician}
          </div>
        </div>
        {note.type === 'CA' && (
          <>
            <div className={styles.date}>
              <div>{moment(note.noteDate).format('hh:mm A')}</div>
              {note.noteDuration && !isSmallScreen && (
                <div className={styles.endTime}>
                  {moment(note.noteDate)
                    .add(note.noteDuration, 'minutes')
                    .format('hh:mm A')}
                </div>
              )}
            </div>
            {note.noteDuration && !isSmallScreen && (
              <div className={styles.duration}>
                <div>{`${note.noteDuration} min`}</div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

ClinicalNoteCard.propTypes = {
  note: PropTypes.shape({
    noteTitle: PropTypes.string.isRequired,
    noteDate: PropTypes.string.isRequired,
    clinicianFirstName: PropTypes.string.isRequired,
    clinicianLastName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    viewed: PropTypes.bool,
  }).isRequired,
  patientProviderId: PropTypes.string.isRequired,
  className: PropTypes.string,
  scrollId: PropTypes.number.isRequired,
  dataId: PropTypes.string.isRequired,
};

export default React.memo(ClinicalNoteCard);
