import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import validator from 'validator';
import moment from 'moment';

import AuthLayout from 'layouts/AuthLayout';
import FormWrapper from 'layouts/AuthLayout/FormWrapper';
import CheckBox from 'components/CheckBox';
import Button from 'components/Button';
import TextField from 'components/TextField';
import ErrorBar from 'components/ErrorBar';
import Recaptcha from 'components/Recaptcha';
import { DEFAULT_ERROR_MSG } from 'consts';
import { scrollToError } from 'utils';

import styles from './styles.module.scss';

const getFormErrors = ({ username, password }) => {
  const result = {};
  if (!username) {
    result['username'] = 'Email is required';
  } else if (!validator.isEmail(username)) {
    result['username'] = 'Email is invalid';
  }
  if (!password) {
    result['password'] = 'Password is required';
  }
  return Object.keys(result).length === 0 ? undefined : result;
};

class LoginPage extends React.PureComponent {
  state = {
    form: {
      username: '',
      password: '',
      rememberMe: true,
    },
    loading: false,
    errors: { form: this.props.error },
  };

  recaptchaRef = React.createRef();

  addError = (name, message) =>
    this.setState((state) => ({
      ...state,
      errors: { ...state.errors, [name]: message },
    }));

  onInputChange = (name) => ({ target: { value } }) => {
    if (['username'].includes(name) && value.match(/\s/)) return;
    this.setState((state) => ({
      ...state,
      form: { ...state.form, [name]: value },
      errors: { ...state.errors, form: undefined, [name]: undefined },
    }));
  };

  onRememberMeCheck = ({ target: { checked } }) =>
    this.onInputChange('rememberMe')({ target: { value: checked } });

  executeRecaptcha = () => this.recaptchaRef.current.execute();

  executeSubmit = (recaptcha) => {
    const { onSubmit, captchaDisabled } = this.props;
    const { form } = this.state;
    return onSubmit({ ...form, captchaDisabled, recaptcha }).catch((e) => {
      this.addError('form', e.message || DEFAULT_ERROR_MSG);
      this.setState({ loading: false });
      this.recaptchaRef.current && this.recaptchaRef.current.reset();
    });
  };

  onSubmit = () => {
    const { captchaDisabled } = this.props;
    const setState = (changes) => this.setState({ ...this.state, ...changes });
    const { form } = this.state;
    const errors = getFormErrors(form);
    if (errors) {
      const [firstError] = Object.keys(errors);
      scrollToError(firstError);
      return setState({ errors });
    }
    setState({ errors: {} });
    if (captchaDisabled) {
      return this.executeSubmit();
    } else {
      return this.executeRecaptcha();
    }
  };

  onVerifyCaptcha = (recaptcha) => {
    this.setState({ loading: true });
    return this.executeSubmit(recaptcha);
  };

  handleEnterPress = ({ key }) => key === 'Enter' && this.onSubmit();

  render() {
    const { form, errors, loading } = this.state;
    const { username, password, rememberMe } = form;
    const { infoNote, info, error, captchaDisabled } = this.props;
    const yearNow = moment().year();
    return (
      <AuthLayout
        title={`Login`}
        note={`Log in to the therapyBOSS patient portal.`}
        infoNote={infoNote}
      >
        <FormWrapper>
          {!(Boolean(errors.form) || error) && Boolean(info) && (
            <div className={styles.info}>{info}</div>
          )}
          {(Boolean(errors.form) || error) && (
            <ErrorBar
              className={styles.errorBar}
              name="form"
              error={errors.form || error}
            />
          )}
          <TextField
            name="username"
            value={username}
            onChange={this.onInputChange('username')}
            onKeyDown={this.handleEnterPress}
            error={errors['username']}
            type="email"
            label="Email"
            placeholder="Enter here"
            className={styles.input}
            autoComplete="on"
            maxLength={50}
          />
          <TextField
            name="password"
            value={password}
            onChange={this.onInputChange('password')}
            onKeyDown={this.handleEnterPress}
            error={errors['password']}
            type="password"
            label="Password"
            placeholder="Enter here"
            className={styles.input}
            autoComplete="on"
            maxLength={25}
          />
          <div className={styles.optionsBlock}>
            <CheckBox
              name="rememberMe"
              checked={rememberMe}
              onChange={this.onRememberMeCheck}
            >
              Remember me
            </CheckBox>
            <Link to="/forget-password" className={styles.forgetLink}>
              Forgot password?
            </Link>
          </div>
        </FormWrapper>
        <div className={styles.buttons}>
          <Button loading={loading} text="Log in" onClick={this.onSubmit} />
        </div>
        {!captchaDisabled && (
          <Recaptcha ref={this.recaptchaRef} onVerify={this.onVerifyCaptcha} />
        )}
        <footer className={styles.footerWrapper}>
          {`${yearNow} Pragma-IT, LLC`}
          <span className={styles.separator} />
          <a
            href={`https://www.pragmait.com/therapyboss/patient-portal-terms/`}
            rel="noopener noreferrer"
          >
            Terms
          </a>
          <span className={styles.separator} />
          <a
            href={`https://www.pragmait.com/therapyboss/privacy/`}
            rel="noopener noreferrer"
          >
            Privacy
          </a>
        </footer>
      </AuthLayout>
    );
  }
}

LoginPage.propTypes = {
  infoNote: PropTypes.string,
  error: PropTypes.string,
  info: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  captchaDisabled: PropTypes.bool,
};

export default LoginPage;
