import React from 'react';
import { toast as rawToast } from 'react-toastify';

import Content from './ToastContent';

const DEFAULT_TOAST_ID = 'TOASTIFY_SAME_ID';

export const showSuccessToast = ({
  content,
  autoClose = 5000,
  toastId = DEFAULT_TOAST_ID,
}) => {
  rawToast.dismiss(toastId);
  rawToast.success(<Content content={content} type="success" />, {
    toastId: toastId,
    autoClose: autoClose,
  });
};

export const showErrorToast = ({
  content,
  autoClose = 3000,
  toastId = DEFAULT_TOAST_ID,
}) => {
  rawToast.dismiss(toastId);
  rawToast.error(<Content content={content} type="error" />, {
    toastId: toastId,
    autoClose: autoClose,
  });
};

export const showWarningToast = ({
  content,
  autoClose = 3000,
  toastId = DEFAULT_TOAST_ID,
}) => {
  if (!rawToast.isActive(toastId)) {
    rawToast.dismiss(toastId);
    rawToast.warning(<Content content={content} type="success" />, {
      autoClose: autoClose,
      hideProgressBar: false,
      toastId: toastId,
    });
  }
};

export const hideToast = (toastId = DEFAULT_TOAST_ID) =>
  rawToast.dismiss(toastId);
