import generateDeviceId from 'uuid/v1';

import { GET_DEVICE_ID } from 'redux/consts/settings';
import { deviceIdSelector } from 'redux/selectors/settings';

export default (store) => (next) => (action) => {
  if (action.type === GET_DEVICE_ID) {
    const newAction = {
      ...action,
      deviceId: deviceIdSelector(store.getState()) || generateDeviceId(),
    };
    return next(newAction);
  }
  return next(action);
};
