import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';

import authReducer from './auth';
import byUserIdReducer from './byUserId';
import settingsReducer from './settings';
import loggedUserReducer from './loggedUser';

const rootReducer = combineReducers({
  loggedUser: loggedUserReducer,
  auth: authReducer,
  byUserId: byUserIdReducer,
  settings: settingsReducer,
});

const persistConfig = {
  key: 'tbpp__v1', //change this to clear all persisted data after the build
  storage: localStorage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
