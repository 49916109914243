import { push } from 'connected-react-router';
import { stringify } from 'query-string';

import { takeLatest, put } from 'redux-saga/effects';
import {
  GET_BILLS_REQUEST,
  VIEW_BILL_REQUEST,
  LOAD_BILL_REQUEST,
} from 'redux/consts/bills';
import {
  onGetBillsSuccess,
  onGetBillsFailure,
  onLoadBillSuccess,
  onLoadBillFailure,
} from 'redux/actions/bills';

import { apiSecureRequest, combineSagas } from './utils';

function* getBillsWorker() {
  const url = `/user-bills`;
  const options = {
    method: 'GET',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    yield put(onGetBillsSuccess({ payload }));
    return payload;
  } catch (error) {
    yield put(onGetBillsFailure({ error }));
    return null;
  }
}

function* getBillsSaga() {
  yield takeLatest(GET_BILLS_REQUEST, getBillsWorker);
}

function* viewBillSagaWorker({ params }) {
  try {
    yield put(push(`/ebill?${stringify(params)}`));
    return true;
  } catch (error) {
    return null;
  }
}

function* viewBillSaga() {
  yield takeLatest(VIEW_BILL_REQUEST, viewBillSagaWorker);
}

function* getBillSagaWorker({ params }) {
  const { providerId, patientId, invoiceId } = params;
  const url = `/user-bills/open?providerId=${providerId}&patientId=${patientId}&invoiceId=${invoiceId}`;
  const options = {
    method: 'GET',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    if (payload) {
      yield put(onLoadBillSuccess({ payload }));
      return payload;
    } else {
      yield put(push(`/not-found`));
    }
  } catch (error) {
    yield put(onLoadBillFailure({ error }));
    yield put(push(`/not-found`));
    return null;
  }
}

function* getBillSaga() {
  yield takeLatest(LOAD_BILL_REQUEST, getBillSagaWorker);
}

export default combineSagas([
  getBillsSaga,
  viewBillSaga,
  getBillSaga,
]);
