import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { isAuthenticatedSelector } from 'redux/selectors/auth';

const AnonymousRoute = ({ isLogged, ...rest }) => {
  if (isLogged) {
    return <Redirect to="/home" />;
  }
  return <Route {...rest} />;
};

AnonymousRoute.propTypes = {
  isLogged: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLogged: isAuthenticatedSelector(state),
});

export default connect(mapStateToProps)(AnonymousRoute);
