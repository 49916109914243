import React from 'react';

import logoImg from 'layouts/Header/img/logo@2x.png';
import Button from 'components/Button';

import styles from './styles.module.scss';

function NotFoundPage() {
  return (
    <div className={styles.wrapper}>
      <img className={styles.logoImg} src={logoImg} alt="logo" />
      <div className={styles.title}>Requested page not found</div>
      <div className={styles.content}>
        Sorry, the page you were looking for doesn't exist or has been moved.
      </div>
      <Button text="Home Page" to="/home" className={styles.btn} />
    </div>
  );
}

export default NotFoundPage;
