import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import logoImg from './img/logo@2x.png';
import styles from './styles.module.scss';

function AuthLayout(props) {
  const { title, note, infoNote, providerName, children } = props;

  return (
    <>
      <Helmet>
        <title>{`therapyBOSS | ${title}`}</title>
      </Helmet>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <aside className={styles.aside}>
            <div className={styles.asideContent}>
              <h1 className={styles.title}>{title}</h1>
              {Boolean(note) && <div className={styles.note}>{note}</div>}
              {Boolean(infoNote) && (
                <div className={styles.infoNote}>{infoNote}</div>
              )}
            </div>
          </aside>
          <div className={styles.content}>
            <Link to={'/'} className={styles.logoWrapper}>
              <img className={styles.logoImg} src={logoImg} alt="logo" />
              {Boolean(providerName) && (
                <span className={cn(styles.context, styles.cutInfo)}>
                  {`/ ${providerName}`}
                </span>
              )}
            </Link>
            <div className={styles.innerContent}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
}

AuthLayout.propTypes = {
  title: PropTypes.string,
  note: PropTypes.string,
  infoNote: PropTypes.string,
  children: PropTypes.node,
  providerName: PropTypes.string,
};

export default AuthLayout;
