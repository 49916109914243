import { LOGOUT_SUCCESS } from 'redux/consts/auth';
import { SELECT_DAY } from 'redux/consts/selectedDay';

const initialState = {};

const selectedDayReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_SUCCESS: {
      return initialState;
    }
    case SELECT_DAY: {
      const { patientProviderId, selectedDay } = action;
      return {
        ...state,
        [patientProviderId]: selectedDay,
      };
    }

    default:
      return state;
  }
};

export default selectedDayReducer;
