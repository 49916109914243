import { LOCATION_CHANGE } from 'connected-react-router';
import { parse } from 'query-string';

import { scrollToHighlighted } from 'utils';

export default () => (next) => (action) => {
  const { type } = action;
  if (type === LOCATION_CHANGE) {
    const searhParams = action.payload.location.search;
    const highlighted = searhParams
      ? parse(searhParams).highlighted
      : undefined;
    if (highlighted) {
      setTimeout(() => scrollToHighlighted(highlighted), 1000);
    } else {
      window.scrollTo(0, 0);
    }
  }
  return next(action);
};
