import { push } from 'connected-react-router';

import { takeLatest, put } from 'redux-saga/effects';
import { CHANGE_USER_PASSWORD_REQUEST } from 'redux/consts/loggedUser';
import { onChangeUserPasswordSuccess } from 'redux/actions/loggedUser';
import { onClientSideLogout } from 'redux/actions/auth';

import { apiSecureRequest, combineSagas } from './utils';

function* changeUserPasswordWorker({ newPassword, oldPassword }) {
  const url = `/user-profile/changePassword`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      newPassword,
      oldPassword,
    }),
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    yield put(onChangeUserPasswordSuccess({ payload }));
    yield put(onClientSideLogout());
    yield put(push('/login?passwordChanged=true'));
    return payload;
  } catch (error) {
    return false;
  }
}

function* changeUserPasswordSaga() {
  yield takeLatest(CHANGE_USER_PASSWORD_REQUEST, changeUserPasswordWorker);
}

export default combineSagas([changeUserPasswordSaga]);
