import { takeLatest, put } from 'redux-saga/effects';
import { DOWNLOAD_PDF_REQUEST } from 'redux/consts/downloadPdf';
import {
  onDownloadPdfSuccess,
  onDownloadPdfFailure,
} from 'redux/actions/downloadPdf';

import { apiSecureRequest, combineSagas } from './utils';

function* downloadPdfWorker({ mode, html }) {
  const url = `/pdf-template/${mode}`;
  const formData = new FormData();
  formData.append('htmlToPdf', html);
  const options = {
    method: 'POST',
    body: formData,
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    yield put(onDownloadPdfSuccess({ payload }));
    return payload;
  } catch (error) {
    yield put(onDownloadPdfFailure({ error }));
    return false;
  }
}

function* downloadPdfSaga() {
  yield takeLatest(DOWNLOAD_PDF_REQUEST, downloadPdfWorker);
}

export default combineSagas([downloadPdfSaga]);
