import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';

import EBillPage from './EBillPage';

function EBillPageContainer(props) {
  const { location } = props;
  const billParams = parse(location.search);
  return <EBillPage {...billParams} />;
}

EBillPageContainer.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};

export default withRouter(React.memo(EBillPageContainer));
