import { WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  UPDATE_USER_DATA_REQUEST,
  UPDATE_USER_DATA_SUCCESS,
  CHANGE_USER_PASSWORD_REQUEST,
  CHANGE_USER_PASSWORD_SUCCESS,
  UPDATE_HAS_PAYMENT_METHOD,
} from 'redux/consts/loggedUser';

export function updateUserData({
  firstName,
  lastName,
  phone,
  currentPassword,
}) {
  return {
    type: UPDATE_USER_DATA_REQUEST,
    firstName,
    lastName,
    phone,
    currentPassword,
    [WAIT_FOR_ACTION]: UPDATE_USER_DATA_SUCCESS,
  };
}

export function onUpdateUserDataSuccess({ payload }) {
  return {
    type: UPDATE_USER_DATA_SUCCESS,
    payload,
  };
}

export function onChangeUserPasswordRequest({ newPassword, oldPassword }) {
  return {
    type: CHANGE_USER_PASSWORD_REQUEST,
    newPassword,
    oldPassword,
    [WAIT_FOR_ACTION]: CHANGE_USER_PASSWORD_SUCCESS,
  };
}
export function onChangeUserPasswordSuccess({ payload }) {
  return {
    type: CHANGE_USER_PASSWORD_SUCCESS,
    payload,
  };
}

export function updateHasPaymentMethod(hasPaymentMethod) {
  return {
    type: UPDATE_HAS_PAYMENT_METHOD,
    hasPaymentMethod,
  };
}
