import React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';

import MainLayout from 'layouts/MainLayout';
import { hasConnectedPatientsSelector } from 'redux/selectors/patientProviders';
import NoAvailableData from 'components/NoAvailableData';

import PatientDocumentation from './PatientDocumentation';
import img from './img/doc@2x.png';

function HomePage() {
  const hasConnectedPatients = useSelector(
    hasConnectedPatientsSelector,
    shallowEqual
  );

  const note = (
    <span>
      You can{' '}
      <Link to="/profile" style={{ color: '#8ec03e', fontWeight: 700 }}>
        {' '}
        check your profile
      </Link>{' '}
      to see if there is a pending request for access.
    </span>
  );

  return (
    <>
      <Helmet>
        <title>{`therapyBOSS | Home`}</title>
      </Helmet>
      <MainLayout>
        {!hasConnectedPatients ? (
          <NoAvailableData
            title={`No patient access yet`}
            note={note}
            img={img}
            alt={`No patients`}
          />
        ) : (
          <PatientDocumentation />
        )}
      </MainLayout>
    </>
  );
}

export default React.memo(HomePage);
