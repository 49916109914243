import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ShowDialogButton from 'components/ShowDialogButton';
import PatientProviderPreview from 'components/PatientProviderPreview';

import styles from './styles.module.scss';

class RequestCard extends React.PureComponent {
  render() {
    const { patient, providerId, note, actions } = this.props;
    return (
      <div className={styles.wrapper}>
        <PatientProviderPreview patient={patient} providerId={providerId} />
        <div className={styles.note}>{note}</div>
        <div>
          {actions.map((action) => (
            <ShowDialogButton
              key={action.dialogRoute}
              route={action.dialogRoute}
              routeProps={action.dialogProps}
              className={cn('link', styles.action)}
            >
              {action.caption}
            </ShowDialogButton>
          ))}
        </div>
      </div>
    );
  }
}

RequestCard.propTypes = {
  patient: PropTypes.shape().isRequired,
  providerId: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      caption: PropTypes.string.isRequired,
      dialogRoute: PropTypes.string.isRequired,
      dialogProps: PropTypes.shape(),
    })
  ).isRequired,
};

export default RequestCard;
