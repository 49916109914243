import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import ProviderPreview from 'components/ProviderPreview';

import styles from './styles.module.scss';

class PatientProviderPreview extends React.PureComponent {
  render() {
    const { patient, provider, ...rest } = this.props;
    return (
      <div className={styles.wrapper} {...rest}>
        <div className={cn(styles.patient, styles.cutInfo)}>
          {patient.firstName} {patient.lastName}
        </div>
        <div className={styles.separator}>{'/'}</div>
        <ProviderPreview provider={provider} />
      </div>
    );
  }
}

PatientProviderPreview.propTypes = {
  patient: PropTypes.shape().isRequired,
  provider: PropTypes.shape().isRequired,
};

export default PatientProviderPreview;
