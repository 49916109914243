import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import NameIcon from 'components/NameIcon';

import styles from './styles.module.scss';

function UserIcon(props) {
  const { user, className, hasPendingRequests } = props;
  const firstName = user.firstName || '';
  const lastName = user.lastName || '';
  return (
    <div className={cn(styles.wrapper, className)}>
      <NameIcon
        className={styles.back}
        text={`${firstName[0]}${lastName[0]}`}
      />
      {hasPendingRequests && (
        <div className={styles.iconWrapper}>
          <div className={styles.icon} />
        </div>
      )}
    </div>
  );
}
UserIcon.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
  hasPendingRequests: PropTypes.bool,
};

export default React.memo(UserIcon);
