import React from 'react';
import PropTypes from 'prop-types';

import NoAvailableData from 'components/NoAvailableData';

import PatientSchedules from 'components/PatientSchedules';

import img from '../img/doc@2x.png';

import styles from './styles.module.scss';

function NoDocumentation({ schedules, onScheduleChange, isMobile }) {
  return (
    <div className={styles.wrapper}>
      <NoAvailableData
        title={`No treatment documentation`}
        note={`There are no clinical notes to show.`}
        img={img}
        alt={`No clinical notes`}
      />
      <aside>
        <div className={styles.schedule}>
          <PatientSchedules
            schedules={schedules}
            onScheduleChange={onScheduleChange}
            isMobile={isMobile}
          />
        </div>
      </aside>
    </div>
  );
}

NoDocumentation.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.object).isRequired,
  onScheduleChange: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default React.memo(NoDocumentation);
