import { stringify } from 'query-string';
import moment from 'moment';

import { put, takeLatest } from 'redux-saga/effects';

import { AUTH_REQUEST } from 'redux/consts/auth';
import { onAuthFailure } from 'redux/actions/auth';
import { BASIC_AUTH_TOKEN, SYSTEM_ERROR_MSG } from 'consts';
import { getDeviceId } from 'redux/actions/settings';
import { apiRequest } from 'redux/sagas/utils';

import { checkMFAWorker } from '../mfa';
import { checkTBAgreementWorker } from '../check-agreement';

import completeAuth from './completeAuth';

const validationErrors = (error, { email }) => {
  return {
    bad_credentials: `Please enter valid login.`,
    account_expired: `You haven't logged in for a while. <a href="/forget-password?email=${email}">Please reactive</a>`,
    disabled: `Account is suspended. <a href="mailto:support@therapyboss.com">Email support</a>`,
    blocked: `Too many logins attempted. Try again at ${moment()
      .add(parseInt(error.unblock_after), 'minutes')
      .format('LT')}`,
    recaptcha: `The captcha you entered is incorrect. Please try again.`,
  }[error.error_code];
};

export function* getToken({
  username,
  password,
  rememberMe,
  deviceId,
  captchaDisabled,
  recaptcha,
}) {
  const url = `/oauth/token`;
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Basic ${BASIC_AUTH_TOKEN}`,
    },
    body: stringify({
      username,
      password,
      rememberMe,
      deviceId,
      ...(captchaDisabled
        ? { captchaDisabled: true }
        : { recaptchaResponse: recaptcha }),
      grant_type: 'password',
    }),
  };
  const payload = yield apiRequest(url, options);
  return payload;
}

export function* getExchangeToken({ token, recaptcha, captchaDisabled }) {
  const url = `/exchange`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      token,
      ...(captchaDisabled
        ? { captchaDisabled: true }
        : { recaptchaResponse: recaptcha }),
    }),
  };
  const payload = yield apiRequest(url, options);
  return payload;
}

function* tokenWorker({
  username,
  password,
  rememberMe,
  recaptcha,
  captchaDisabled,
}) {
  const { deviceId } = yield put(getDeviceId());
  const payload = yield getToken({
    username,
    password,
    rememberMe,
    deviceId,
    captchaDisabled,
    recaptcha,
  });
  return payload;
}

function* authWorker({
  username,
  password,
  rememberMe,
  recaptcha,
  captchaDisabled,
}) {
  try {
    const authToken = yield tokenWorker({
      username,
      password,
      rememberMe,
      recaptcha,
      captchaDisabled,
    });
    yield checkMFAWorker(authToken);
    yield checkTBAgreementWorker(authToken);
    yield completeAuth({ authToken });
    return authToken;
  } catch (error) {
    let returnError = error;
    if (error.error_code) {
      returnError = validationErrors(error, {
        email: username,
      });
    } else
      returnError = error.message.includes('Failed')
        ? SYSTEM_ERROR_MSG
        : error.message;

    yield put(onAuthFailure({ error: new Error(returnError) }));
    return null;
  }
}

function* authSaga() {
  yield takeLatest(AUTH_REQUEST, authWorker);
}

export default authSaga;
