import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';

import {
  PAY_BILL_REQUEST,
  PAY_BILL_SUCCESS,
  PAY_BILL_FAILURE,
  PAY_BILL_NOW_REQUEST,
  PAY_BILL_NOW_SUCCESS,
  GET_PAYMENT_METHOD_REQUEST,
  GET_PAYMENT_METHOD_SUCCESS,
  DELETE_PAYMENT_METHOD_REQUEST,
  CREATE_SETUP_INTENT_REQUEST,
  CREATE_SETUP_INTENT_SUCCESS,
  CANCEL_SETUP_INTENT_REQUEST,
  CREATE_PAYMENT_INTENT_REQUEST,
  CREATE_PAYMENT_INTENT_SUCCESS,
  GET_PAYMENT_COMPLETED_REQUEST,
  GET_PAYMENT_COMPLETED_SUCCESS,
} from 'redux/consts/billsPayment';

export function payBill(params) {
  return {
    type: PAY_BILL_REQUEST,
    params,
    [WAIT_FOR_ACTION]: PAY_BILL_SUCCESS,
    [ERROR_ACTION]: PAY_BILL_FAILURE,
  };
}

export function onPayBillSuccess({ payload }) {
  return {
    type: PAY_BILL_SUCCESS,
    payload,
  };
}

export function onPayBillFailure({ error }) {
  return {
    type: PAY_BILL_FAILURE,
    error,
  };
}

export function payBillNow(params) {
  return {
    type: PAY_BILL_NOW_REQUEST,
    params,
    [WAIT_FOR_ACTION]: PAY_BILL_NOW_SUCCESS,
  };
}

export function onPayBillNowSuccess({ payload }) {
  return {
    type: PAY_BILL_NOW_SUCCESS,
    payload,
  };
}

export function loadPaymentMethod() {
  return {
    type: GET_PAYMENT_METHOD_REQUEST,
    [WAIT_FOR_ACTION]: GET_PAYMENT_METHOD_SUCCESS,
  };
}

export function onLoadPaymentMethodSuccess({ payload }) {
  return {
    type: GET_PAYMENT_METHOD_SUCCESS,
    payload,
  };
}

export function deletePaymentMethod(params) {
  return {
    type: DELETE_PAYMENT_METHOD_REQUEST,
    params
  };
}

export function createSetupIntent(params) {
  return {
    type: CREATE_SETUP_INTENT_REQUEST,
    params,
    [WAIT_FOR_ACTION]: CREATE_SETUP_INTENT_SUCCESS,
  };
}

export function onCreateSetupIntentSuccess({ payload }) {
  return {
    type: CREATE_SETUP_INTENT_SUCCESS,
    payload,
  };
}

export function cancelSetupIntent(params) {
  return {
    type: CANCEL_SETUP_INTENT_REQUEST,
    params,
  };
}

export function createPaymentIntent(params) {
  return {
    type: CREATE_PAYMENT_INTENT_REQUEST,
    params,
    [WAIT_FOR_ACTION]: CREATE_PAYMENT_INTENT_SUCCESS,
  };
}

export function onCreatePaymentIntentSuccess({ payload }) {
  return {
    type: CREATE_PAYMENT_INTENT_SUCCESS,
    payload,
  };
}

export function retrievePaymentCompleted(params) {
  return {
    type: GET_PAYMENT_COMPLETED_REQUEST,
    params,
    [WAIT_FOR_ACTION]: GET_PAYMENT_COMPLETED_SUCCESS,
  };
}

export function onRetrievePaymentCompletedSuccess({ payload }) {
  return {
    type: GET_PAYMENT_COMPLETED_SUCCESS,
    payload,
  };
}