import { INITIAL_DATA_SUCCESS } from 'redux/consts/initialData';
import {
  CANCEL_PENDING_PROVIDERS_SUCCESS,
  CANCEL_PENDING_PROVIDERS_FAILURE,
  CONFIRM_INFORMATION_SUCCESS,
} from 'redux/consts/providersRequests';

const initialState = [];

/**
 * [
 * {
 *  invitation: {status, statusTime, token},
 *  providerId,
 *  patient: {id, firstName, lastName, gender, dob}
 * }
 * ]
 */
const patientInvitationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_DATA_SUCCESS: {
      const {
        payload: { patientInvitations = [] },
      } = action;
      return patientInvitations;
    }

    case CANCEL_PENDING_PROVIDERS_SUCCESS:
    case CANCEL_PENDING_PROVIDERS_FAILURE: {
      const { token } = action;
      return state.map((pr) => {
        if (pr.invitation.token === token) {
          return {
            ...pr,
            invitation: { ...pr.invitation, status: 'CANCELED' },
          };
        }
        return pr;
      });
    }

    case CONFIRM_INFORMATION_SUCCESS: {
      const { token } = action;
      return state.map((pr) => {
        if (pr.invitation.token === token) {
          return {
            ...pr,
            invitation: {
              ...pr.invitation,
              status: 'PENDING',
              statusTime: new Date(),
            },
          };
        }
        return pr;
      });
    }

    default:
      return state;
  }
};

export default patientInvitationsReducer;
