import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from 'components/Button';

import styles from './styles.module.scss';

class AlertDialog extends React.PureComponent {
  componentDidMount() {
    const { checkConfig, title } = this.props;
    checkConfig({ removeClose: true, title });
  }

  render() {
    const { message, type, onDialogClose } = this.props;
    const messageStyle = {
      info: styles.info,
      warning: styles.warning,
    }[type];
    return (
      <>
        <div
          className={cn(styles.message, messageStyle)}
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <div className={styles.buttons}>
          <Button text="Ok" className={styles.button} onClick={onDialogClose} />
        </div>
      </>
    );
  }
}

AlertDialog.propTypes = {
  title: PropTypes.string.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['info', 'warning']).isRequired,
  checkConfig: PropTypes.func.isRequired,
};

export default AlertDialog;
