import moment from 'moment';

import { takeLatest, put } from 'redux-saga/effects';

import { SIGNUP_REQUEST, GET_SIGNUP_DATA_REQUEST } from 'redux/consts/signup';
import {
  onSignupSuccess,
  onSignupFailure,
  onGetSignupDataSuccess,
} from 'redux/actions/signup';

import { getExchangeToken } from './authentication';
import { getAgreementsWorker } from './agreements';
import { createApiSecureRequest, combineSagas } from './utils';

function* checkAgreementsRelevantWorker(
  apiSecureRequest,
  { form, invProviderId }
) {
  const { signedAgreements } = form;
  const agreements = yield getAgreementsWorker(apiSecureRequest);
  const notValidAgreements = agreements
    .filter(({ providerId }) => providerId === invProviderId || !providerId)
    .filter(
      (a) =>
        !signedAgreements.find(
          (sa) =>
            sa.agreementId === a.agreementId &&
            sa.agreementPublishedOn === a.agreementPublishedOn
        )
    );
  if (notValidAgreements.length > 0) {
    const agreement =
      notValidAgreements.find((a) => a.type === 'PROVIDER') ||
      notValidAgreements[0];
    throw new Error(
      `${agreement.providerName ||
        'therapyBOSS'} has just updated their Terms of Use`,
      {
        cause: 'signAgreement',
      }
    );
  }
}

function* updateUserInfoWorker(apiSecureRequest, { form, token }) {
  const { firstName, lastName, phone } = form;
  const url = `/user-profile/account`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firstName,
      lastName,
      phone,
      invitationToken: token,
    }),
  };
  const payload = yield apiSecureRequest(url, options);
  return payload;
}

function* confirmRequestWorker(apiSecureRequest, { form, token }) {
  const { dob, zip, relationType, clinicianName } = form;
  const url = `/invitation/confirm`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      token,
      dob: moment(dob).format('YYYY-MM-DD'),
      zip,
      relationType,
      clinicianName,
    }),
  };
  const payload = yield apiSecureRequest(url, options);
  return payload;
}

function* setUserPasswordWorker(apiSecureRequest, { form, token }) {
  const { password } = form;
  const url = `/user-profile/changePassword`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      newPassword: password,
      invitationToken: token,
    }),
  };
  const payload = yield apiSecureRequest(url, options);
  return payload;
}

function* signAgreementWorker(apiSecureRequest, { agreementId }) {
  const url = `/user-profile/agreement?agreementId=${agreementId}`;
  const options = {
    method: 'POST',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    return payload;
  } catch (error) {
    if (error && error.message === 'Agreement already signed.') {
      const payload = {};
      return payload;
    }
    return false;
  }
}

function* signAgreementsWorker(apiSecureRequest, { form }) {
  const { signedAgreements } = form;
  const result = {};
  for (let index = 0; index < signedAgreements.length; index++) {
    const { agreementId } = signedAgreements[index];
    const payload = yield signAgreementWorker(apiSecureRequest, {
      agreementId,
    });
    result[agreementId] = payload;
  }
  return result;
}

function* signupWorker({ authToken, token, form, invProviderId }) {
  try {
    const apiSecureRequest = createApiSecureRequest({ token: authToken });
    yield checkAgreementsRelevantWorker(apiSecureRequest, {
      form,
      invProviderId,
    });
    const userPayload = yield updateUserInfoWorker(apiSecureRequest, {
      form,
      token,
    });
    const requestPayload = yield confirmRequestWorker(apiSecureRequest, {
      form,
      token,
    });
    const agreementsPayload = yield signAgreementsWorker(apiSecureRequest, {
      form,
    });
    const passwordPayload = yield setUserPasswordWorker(apiSecureRequest, {
      form,
      token,
    });
    const payload = {
      userPayload,
      requestPayload,
      agreementsPayload,
      passwordPayload,
    };
    yield put(onSignupSuccess({ payload }));
    return payload;
  } catch (error) {
    yield put(onSignupFailure({ error }));
    return false;
  }
}

function* signupSaga() {
  yield takeLatest(SIGNUP_REQUEST, signupWorker);
}

function* getSignupDataWorker(payload) {
  const { data } = payload;
  const { token, recaptcha, captchaDisabled } = data;
  try {
    const authToken = yield getExchangeToken({
      token,
      recaptcha,
      captchaDisabled,
    });
    const apiSecureRequest = createApiSecureRequest({ token: authToken });
    const url = `/user-profile/info`;
    const options = { method: 'GET' };
    const userInfo = yield apiSecureRequest(url, options);
    const userEmail = userInfo.user.email;
    const providerId = userInfo.patientInvitations.find(
      (pi) => pi.invitation.token === token
    ).providerId;
    const providerName = userInfo.providers.find((p) => p.id === providerId)
      .name;
    const payload = { authToken, providerName, providerId, userEmail };
    yield put(onGetSignupDataSuccess({ payload }));
    return payload;
  } catch (error) {
    return false;
  }
}

function* getSignupDataSaga() {
  yield takeLatest(GET_SIGNUP_DATA_REQUEST, getSignupDataWorker);
}

export default combineSagas([signupSaga, getSignupDataSaga]);
