import React from 'react';
import { Helmet } from 'react-helmet';

import MainLayout from 'layouts/MainLayout';

import PendingRequestBlock from './PendingRequestBlock';
import UserInfoForm from './UserInfoForm';

import styles from './styles.module.scss';

const headerProps = { includePatientsDropdown: false };

class ProfilePage extends React.PureComponent {
  render() {
    return (
      <>
        <Helmet>
          <title>{`therapyBOSS | My profile`}</title>
        </Helmet>
        <MainLayout headerProps={headerProps}>
          <div className={styles.wrapper}>
            <UserInfoForm className={styles.left} />
            <PendingRequestBlock className={styles.right} />
          </div>
        </MainLayout>
      </>
    );
  }
}

export default ProfilePage;
