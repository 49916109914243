import { connect } from 'react-redux';

import { isPageOverflowShownSelector } from 'redux/selectors/ui';
import { isDialogShownSelector } from 'redux/selectors/dialog';

import PageLayout from './PageLayout';

const connectStore = connect((store) => ({
  pageOverflowShown: isPageOverflowShownSelector(store),
  dialogShown: isDialogShownSelector(store),
}));

export default connectStore(PageLayout);
