import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

class FormWrapper extends React.PureComponent {
  render() {
    const { children } = this.props;
    return (
      <div className={styles.wrapper}>
        <div>{children}</div>
      </div>
    );
  }
}

FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormWrapper;
