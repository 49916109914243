export const PAY_BILL_REQUEST = 'PAY_BILL_REQUEST';
export const PAY_BILL_SUCCESS = 'PAY_BILL_SUCCESS';
export const PAY_BILL_FAILURE = 'PAY_BILL_FAILURE';

export const PAY_BILL_NOW_REQUEST = 'PAY_BILL_NOW_REQUEST';
export const PAY_BILL_NOW_SUCCESS = 'PAY_BILL_NOW_SUCCESS';

export const GET_PAYMENT_METHOD_REQUEST = 'GET_PAYMENT_METHOD_REQUEST';
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS';

export const DELETE_PAYMENT_METHOD_REQUEST = 'DELETE_PAYMENT_METHOD_REQUEST';

export const CREATE_SETUP_INTENT_REQUEST = 'CREATE_SETUP_INTENT_REQUEST';
export const CREATE_SETUP_INTENT_SUCCESS = 'CREATE_SETUP_INTENT_SUCCESS';
export const CANCEL_SETUP_INTENT_REQUEST = 'CANCEL_SETUP_INTENT_REQUEST';

export const CREATE_PAYMENT_INTENT_REQUEST = 'CREATE_PAYMENT_INTENT_REQUEST';
export const CREATE_PAYMENT_INTENT_SUCCESS = 'CREATE_PAYMENT_INTENT_SUCCESS';

export const GET_PAYMENT_COMPLETED_REQUEST = 'GET_PAYMENT_COMPLETED_REQUEST';
export const GET_PAYMENT_COMPLETED_SUCCESS = 'GET_PAYMENT_COMPLETED_SUCCESS';