import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

class Step4 extends React.PureComponent {
  render() {
    const { email } = this.props;
    return (
      <>
        <div className={styles.title}>All set!</div>
        <div className={styles.note}>
          {`Information has been forwarded to the healthcare provider. You'll be
          notified at ${email} when patient access is granted.`}
        </div>
      </>
    );
  }
}

Step4.propTypes = {
  email: PropTypes.string.isRequired,
};

export default Step4;
