import ChangePasswordDialog from 'dialogs/ChangePasswordDialog';
import CancelRequestDialog from 'dialogs/CancelRequestDialog';
import ConfirmRequestDialog from 'dialogs/ConfirmRequestDialog';
import CancelChangesDialog from 'dialogs/CancelChangesDialog';
import AgreementsDialog from 'dialogs/AgreementsDialog';
import AlertDialog from 'dialogs/AlertDialog';
import StripeCheckoutDialog from 'dialogs/StripeCheckoutDialog';
import ElectronicallyPaymentDialog from 'dialogs/ElectronicallyPaymentDialog';
import ElectronicalyPaymentIssueDialog from 'dialogs/ElectronicalyPaymentIssueDialog';
import ConfirmationDialog from 'dialogs/ConfirmationDialog';

import {
  CHANGE_PASSWORD_DIALOG,
  CANCEL_REQUEST_DIALOG,
  CANCEL_CHANGES_DIALOG,
  CONFIRM_REQUEST_DIALOG,
  AGREEMENTS_DIALOG,
  INFO_DIALOG,
  STRIPE_CHECKOUT_DIALOG,
  ELECTRONICALY_PAYMENT_DIALOG,
  ELECTRONICALY_PAYMENT_ISSUE_DIALOG,
  CONFIRMATION_DIALOG,
} from './consts';

const routes = {
  [CHANGE_PASSWORD_DIALOG]: {
    component: ChangePasswordDialog,
    title: `Change password`,
  },
  [CANCEL_REQUEST_DIALOG]: {
    component: CancelRequestDialog,
    title: `Cancel access request`,
  },
  [CONFIRM_REQUEST_DIALOG]: {
    component: ConfirmRequestDialog,
    title: `Confirm information`,
  },
  [CANCEL_CHANGES_DIALOG]: {
    component: CancelChangesDialog,
  },
  [AGREEMENTS_DIALOG]: {
    component: AgreementsDialog,
  },
  [INFO_DIALOG]: {
    component: AlertDialog,
  },
  [INFO_DIALOG]: {
    component: AlertDialog,
  },
  [STRIPE_CHECKOUT_DIALOG]: {
    component: StripeCheckoutDialog,
  },
  [ELECTRONICALY_PAYMENT_DIALOG]: {
    component: ElectronicallyPaymentDialog,
  },
  [ELECTRONICALY_PAYMENT_ISSUE_DIALOG]: {
    component: ElectronicalyPaymentIssueDialog,
    title: `Pay bill`,
  },
  [CONFIRMATION_DIALOG]: { component: ConfirmationDialog },
};

export default routes;
