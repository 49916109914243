import { combineSagas } from 'redux/sagas/utils';

import auth from './auth';
import mfa from './mfa';
import checkAgreement from './check-agreement';
import logout from './logout';
import initialData from './auth/initialData';

export { getToken, getExchangeToken } from './auth';

export default combineSagas([auth, mfa, checkAgreement, initialData, logout]);
