import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';

import ENotePage from './ENotePage';

function ENotePageContainer(props) {
  const { location } = props;
  const { noteTitle, ...noteParams } = parse(location.search);
  return <ENotePage noteParams={noteParams} noteTitle={noteTitle} />;
}

ENotePageContainer.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
};

export default withRouter(React.memo(ENotePageContainer));
