import { SELECT_PATIENT_PROVIDER_ID } from 'redux/consts/patientProviders';
import { INITIAL_DATA_SUCCESS } from 'redux/consts/initialData';
import { extractPatientProviders } from 'redux/reducers/utils';

const initialState = {};

const byUserIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_PATIENT_PROVIDER_ID: {
      const { loggedUserId, id: selectedPatientProviderId } = action;
      return {
        ...state,
        [loggedUserId]: {
          ...state[loggedUserId],
          selectedPatientProviderId,
        },
      };
    }

    case INITIAL_DATA_SUCCESS: {
      const { payload } = action;
      const loggedUserId = payload.user.id;
      const patientProviderIds = extractPatientProviders(payload).map(
        (p) => p.patientProviderId
      );
      const selectedPatientProviderId = (state[loggedUserId] || {})
        .selectedPatientProviderId;
      if (!patientProviderIds.includes(selectedPatientProviderId)) {
        return {
          ...state,
          [loggedUserId]: {
            ...state[loggedUserId],
            selectedPatientProviderId: patientProviderIds[0],
          },
        };
      }
      return state;
    }

    default:
      return state;
  }
};

export default byUserIdReducer;
