import 'whatwg-fetch';

function checkContentType(response, type) {
  const contentType = response.headers.get('content-type');
  return contentType && contentType.startsWith(type);
}

function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  if (checkContentType(response, 'application/json')) {
    return response.json();
  }
  if (checkContentType(response, 'text/plain')) {
    return response.text();
  }
  if (checkContentType(response, 'application/pdf')) {
    return response.blob();
  }
  return null;
}

function checkStatus(response) {
  window.documentLoading = false;
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if ([403, 405].includes(response.status)) {
    const error = {
      statusCode: response.status,
      message: {
        403: `Action forbidden. Please refresh and try again`,
        405: `Action not allowed. Please refresh and try again.`,
      }[response.status],
    };
    throw error;
  }
  if (checkContentType(response, 'application/json')) {
    return response.json().then((errorData) => {
      const error = { statusCode: response.status, ...errorData };
      throw error;
    });
  }
  if (checkContentType(response, 'text')) {
    return response.text().then((errorText) => {
      const error = { statusCode: response.status, message: errorText };
      throw error;
    });
  }
  const error = {
    statusCode: response.status,
    message: `Action can't be performed due to a system problem. Please try again later.`,
  };
  throw error;
}

const request = (url, params) => {
  window.documentLoading = true;
  return fetch(url, params)
    .then(checkStatus)
    .then(parseJSON);
};

export default request;
