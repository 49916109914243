import React from 'react';
import PropTypes from 'prop-types';

import Calendar from 'components/Calendar';
import PatientSchedules from 'components/PatientSchedules';

import styles from './styles.module.scss';

function CalendarCard({
  isMobile,
  sortedDocs,
  selectedDays,
  showDay,
  schedules,
  onScheduleChange,
}) {
  return (
    <div className={styles.asideWrapper}>
      {sortedDocs.length > 0 && (
        <div className={styles.calendar}>
          <Calendar
            docs={sortedDocs}
            selectedDays={selectedDays}
            onDayClick={showDay}
          />
        </div>
      )}
      {schedules.length !== 0 && (
        <div className={styles.schedule}>
          <PatientSchedules
            isMobile={isMobile}
            onScheduleChange={onScheduleChange}
            schedules={schedules}
          />
        </div>
      )}
    </div>
  );
}

CalendarCard.propTypes = {
  schedules: PropTypes.arrayOf(PropTypes.object),
  onScheduleChange: PropTypes.func.isRequired,
  showDay: PropTypes.func.isRequired,
  sortedDocs: PropTypes.array.isRequired,
  selectedDays: PropTypes.object,
  isMobile: PropTypes.bool,
};

export default React.memo(CalendarCard);
