import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { hideDialog } from 'redux/actions/dialog';
import { dialogSelector } from 'redux/selectors/dialog';
import DialogLayout from 'layouts/DialogLayout';

import routes from './routes';

class Dialog extends React.PureComponent {
  state = {
    config: {},
  };
  render() {
    const {
      data: { route, props = {} },
      onDialogClose,
    } = this.props;
    const cfg = routes[route];
    const Component = cfg.component;

    const scrollToTop = () => {
      const dialogContainer = document.getElementById('dialog-container');
      dialogContainer.scrollTop = 0;
    };

    const checkConfig = (config) => config && this.setState({ config });

    return (
      <DialogLayout
        title={cfg.title || props.title || this.state.title}
        config={this.state.config}
      >
        <Component
          {...props}
          checkConfig={checkConfig}
          onDialogClose={onDialogClose}
          scrollToTop={scrollToTop}
        />
      </DialogLayout>
    );
  }
}

Dialog.propTypes = {
  data: PropTypes.shape({
    route: PropTypes.string.isRequired,
    props: PropTypes.object,
  }).isRequired,
  onDialogClose: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  onDialogClose: () => dispatch(hideDialog()),
});
const mapStateToProps = (state) => ({
  data: dialogSelector(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dialog);
