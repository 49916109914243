import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'components/TextField';

import styles from '../styles.module.scss';

class Step2 extends React.PureComponent {
  render() {
    const { form, errors, onInputChange } = this.props;
    const { dob, zip, relationType, clinicianName } = form;
    return (
      <>
        <TextField
          name="dob"
          value={dob}
          onChange={onInputChange('dob')}
          error={errors['dob']}
          placeholder="MM/DD/YYYY"
          type="text"
          autoComplete="on"
          mask="99/99/9999"
          label="Patient's DOB"
          className={styles.input}
        />
        <TextField
          name="zip"
          value={zip}
          onChange={onInputChange('zip')}
          mode="integer"
          error={errors['zip']}
          placeholder="Enter here"
          type="number"
          autoComplete="on"
          label="Patient's zip code"
          className={styles.input}
          maxLength={5}
        />
        <TextField
          name="relationType"
          value={relationType}
          onChange={onInputChange('relationType')}
          error={errors['relationType']}
          placeholder="Enter here"
          type="text"
          autoComplete="on"
          label="Your relationship to patient"
          className={styles.input}
          maxLength="25"
        />
        <TextField
          name="clinicianName"
          value={clinicianName}
          onChange={onInputChange('clinicianName')}
          error={errors['clinicianName']}
          placeholder="Enter here"
          type="text"
          autoComplete="on"
          label="Treating clinician's first name"
          className={styles.input}
          maxLength="25"
        />
      </>
    );
  }
}

Step2.propTypes = {
  form: PropTypes.shape({
    dob: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
    relationType: PropTypes.string.isRequired,
    clinicianName: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default Step2;
