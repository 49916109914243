import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Title = forwardRef(function TitleWithRef(props, ref) {
  const { title } = props;
  return (
    <div ref={ref} className={styles.wrapper}>
      {title}
    </div>
  );
});

Title.propTypes = {
  title: PropTypes.string.isRequired,
};

export default React.memo(Title);
