import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import LoadingContainer from 'components/LoadingContainer';
import { getBills } from 'redux/actions/bills';
import { billsSelector } from 'redux/selectors/bills';
import NoAvailableData from 'components/NoAvailableData';
import { waitPaymentMethodStatus, waitPayment } from 'utils/stripeHandler.js'

import BillsList from './BillsList';
import FilteringBar from './FilteringBar';
import styles from './styles.module.scss';
import img from './img/no_bills.png';

const BillsContainer = () => {
  const [filterCfg, setFilterCfg] = useState([]);
  const [bills, setBills] = useState();

  const { viewed } = useLocation();
  const dispatch = useDispatch();

  const queryParams = new URLSearchParams(window.location.search);
  const patientId = queryParams.get('patientId');
  const invoiceId = queryParams.get('invoiceId');
  const setupIntentId = queryParams.get('setup_intent');
  const paymentIntentId = queryParams.get('payment_intent');

  const loadBills = useCallback(() => {
    return dispatch(getBills()).then(filterBills);
  }, [dispatch]);

  useEffect(() => {
    loadBills();

    const params = { patientId, invoiceId, setupIntentId, paymentIntentId };
    if (!!setupIntentId) {
      waitPaymentMethodStatus(dispatch, params, loadBills);
    } else if (!!paymentIntentId) {
      waitPayment(dispatch, params, loadBills);
    }
  }, [loadBills]); // eslint-disable-line

  const initBills = useSelector(billsSelector, shallowEqual);
  const uniqueYears = [
    ...new Set(initBills.map((b) => moment(b.createdOn).year()))
  ];

  const filterBills = (bills, filterCfg = []) => {
    const filteredBills = bills.filter((bill) => {
      for (let key in filterCfg) {
        const item =
          key === 'invoicePaidFlag'
            ? bill[key]
            : moment(bill[key]).format('YYYY');
        if (filterCfg[key].length && !filterCfg[key].includes(item))
          return false;
      }
      return true;
    });
    setBills(filteredBills);
  };

  const onChange = (value) => {
    const cfg = { ...filterCfg, ...value };
    setFilterCfg(cfg);
    filterBills(initBills, cfg);
  };

  if (typeof bills === 'undefined') {
    return <LoadingContainer />;
  }
  return (
    <div className={styles.wrapper}>
      <FilteringBar onChange={onChange} years={uniqueYears} />
      {bills.length ? (
        <BillsList bills={bills} viewed={viewed} onRefresh={loadBills} />
      ) : (
        <NoAvailableData
          title={`No bills`}
          note={`There are no bills to show.`}
          alt={`No bills`}
          img={img}
          height={50}
        />
      )}
    </div>
  );
};

export default BillsContainer;
