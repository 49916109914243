import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

class LoadingIndicator extends React.PureComponent {
  render() {
    const { className } = this.props;
    return (
      <div className={cn(styles.wrapper, className && className)}>
        <div />
        <div />
        <div />
        <div />
      </div>
    );
  }
}

LoadingIndicator.propTypes = {
    className: PropTypes.string,
};

export default LoadingIndicator;
