import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

const GroupedLinkButtons = ({ linkName, linkTo, buttons = [] }) => {
  return (
    <div
      className={styles.buttonsWrapper}
      style={buttons.length > 1 ? { justifyContent: 'space-between' } : {}}
    >
      <Link className={cn(styles.actionBtn, styles.back)} to={linkTo}>
        {linkName}
      </Link>
      <div className={styles.groupedButtons}>
        {buttons.map(({ label, className, onClick }, idx) => (
          <React.Fragment key={label}>
            {buttons.length === 1 && (
              <span className={styles.btnSeparator}>|</span>
            )}
            <button
              className={cn(styles.actionBtn, className)}
              onClick={onClick}
            >
              {label}
            </button>
            {buttons.length > 1 && idx !== buttons.length - 1 && (
              <span className={styles.btnSeparator}>|</span>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

GroupedLinkButtons.propTypes = {
  linkName: PropTypes.string.isRequired,
  linkTo: PropTypes.object.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      className: PropTypes.string,
      onClick: PropTypes.func,
    }).isRequired
  ),
};

export default GroupedLinkButtons;
