import { select, call } from 'redux-saga/effects';

import { tokenSelector, temporaryTokenSelector } from 'redux/selectors/auth';
import { tokenizeRequestParams } from 'utils';

import { rawApiRequest } from './apiRequest';
import refreshToken from './refreshToken';
import wrapWithDefaultHandlers from './wrapWithDefaultHandlers';

function* abstractApiSecureRequest(token, tryRefresh, ...args) {
  const tokenizer = tokenizeRequestParams(token);
  const params = tokenizer(...args);
  try {
    const payload = yield call(rawApiRequest, ...params);
    return payload;
  } catch (error) {
    if (tryRefresh && error && error.statusCode === 401) {
      const refreshedToken = yield call(refreshToken);
      if (refreshedToken) {
        const payload = yield call(
          abstractApiSecureRequest,
          refreshedToken,
          false,
          ...args
        );
        return payload;
      } else {
        const error = { message: 'Could not refresh token.' };
        throw error;
      }
    } else {
      throw error;
    }
  }
}

export function createApiSecureRequest(settings) {
  const { token, tryRefresh } = settings;
  return function(...args) {
    const apiSecureRequest = wrapWithDefaultHandlers(abstractApiSecureRequest);
    return apiSecureRequest(token, tryRefresh, ...args);
  };
}

function* defaultApiSecureRequest(...args) {
  const token = yield select(tokenSelector);
  const apiSecureRequest = createApiSecureRequest({
    token,
    tryRefresh: true,
  });
  const payload = yield call(apiSecureRequest, ...args);
  return payload;
}

export function* apiTemporarySecureRequest(...args) {
  const token = yield select(temporaryTokenSelector);
  const apiTemporarySecureRequest = createApiSecureRequest({
    token,
    tryRefresh: true,
  });
  const payload = yield call(apiTemporarySecureRequest, ...args);
  return payload;
}

export default defaultApiSecureRequest;
