import { combineReducers } from 'redux';

import dialogReducer from './dialog';
import overflowReducer from './overflow';
import popupReducer from './popup';

export default combineReducers({
  popup: popupReducer,
  dialog: dialogReducer,
  overflow: overflowReducer,
});
