import { connect } from 'react-redux';

import { hideDialog } from 'redux/actions/dialog';

import DialogLayout from './DialogLayout';

const connectStore = connect(
  null,
  { onClose: hideDialog }
);

export default connectStore(DialogLayout);
