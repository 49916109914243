import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'components/TextField';
import Button from 'components/Button';
import { scrollToError } from 'utils';

import styles from './styles.module.scss';

const getFormErrors = ({ oldPassword, newPassword }) => {
  const result = {};
  if (!oldPassword) {
    result['oldPassword'] = 'Current password is required';
  }
  if (newPassword.length === 0) {
    result['newPassword'] = 'Password is required';
  } else if (newPassword.search(/\s/) !== -1) {
    result['newPassword'] = 'Password cannot have spaces';
  } else if (newPassword.length < 8) {
    result['newPassword'] = 'Password must be between 8 and 25 characters';
  } else if (newPassword.search(/[a-zA-Z]/) === -1) {
    result['newPassword'] = 'Password must have at least one letter';
  } else if (newPassword.search(/\d/) === -1) {
    result['newPassword'] = 'Password must have at least one number';
  }
  return Object.keys(result).length === 0 ? undefined : result;
};

class ChangePasswordDialog extends React.Component {
  state = {
    form: {
      oldPassword: '',
      newPassword: '',
    },
    loading: false,
    errors: {},
  };

  addError = (name, message) =>
    this.setState((state) => ({
      ...state,
      errors: { ...state.errors, [name]: message },
    }));

  onInputChange = (name) => ({ target: { value } }) =>
    this.setState((state) => ({
      ...state,
      form: { ...state.form, [name]: value },
      errors: { ...state.errors, [name]: undefined },
    }));

  onSaveChanges = () => {
    const { onChangeUserPassword, onDialogClose } = this.props;
    const { form } = this.state;
    const errors = getFormErrors(form);
    if (errors) {
      const [firstError] = Object.keys(errors);
      scrollToError(firstError);
      return this.setState({ errors });
    }
    this.setState({ errors: {}, loading: true });
    return onChangeUserPassword(form)
      .then(() => onDialogClose())
      .catch(() => {
        scrollToError('oldPassword');
        this.addError('oldPassword', 'Current password is invalid');
      })
      .finally(() => this.setState({ loading: false }));
  };

  render() {
    const { onDialogClose } = this.props;
    const { form, loading, errors } = this.state;
    const { oldPassword, newPassword } = form;
    return (
      <>
        <TextField
          name="oldPassword"
          value={oldPassword}
          onChange={this.onInputChange('oldPassword')}
          error={errors['oldPassword']}
          label="Current password"
          placeholder="Type your current password"
          className={styles.input}
          type="password"
          maxLength={25}
        />
        <TextField
          name="newPassword"
          value={newPassword}
          onChange={this.onInputChange('newPassword')}
          error={errors['newPassword']}
          label="New password"
          placeholder="Type your desired password"
          className={styles.input}
          type="password"
          maxLength={25}
        />
        <div className={styles.buttons}>
          <Button
            text="Cancel"
            secondary={true}
            className={styles.closeButton}
            onClick={onDialogClose}
          />
          <Button
            text="Save changes"
            loading={loading}
            className={styles.saveButton}
            onClick={this.onSaveChanges}
          />
        </div>
      </>
    );
  }
}

ChangePasswordDialog.propTypes = {
  onDialogClose: PropTypes.func.isRequired,
  checkConfig: PropTypes.func.isRequired,
  onChangeUserPassword: PropTypes.func.isRequired,
};

export default ChangePasswordDialog;
