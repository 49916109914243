import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from 'components/Button';

import styles from './styles.module.scss';

class CancelChangesDialog extends React.PureComponent {
  componentDidMount() {
    const { checkConfig } = this.props;
    checkConfig({ removeClose: true });
  }

  onCancelChanges = () => {
    const { onDialogClose, yesFn } = this.props;
    yesFn();
    onDialogClose();
  };

  render() {
    const { onDialogClose } = this.props;

    return (
      <>
        <div className={styles.note}>Discard your changes?</div>
        <div className={styles.buttons}>
          <Button
            text="No, leave it"
            secondary={true}
            className={styles.button}
            onClick={onDialogClose}
          />
          <Button
            text="Yes, discard"
            className={cn(styles.button, styles.cancelButton)}
            onClick={this.onCancelChanges}
          />
        </div>
      </>
    );
  }
}

CancelChangesDialog.propTypes = {
  onDialogClose: PropTypes.func.isRequired,
  checkConfig: PropTypes.func.isRequired,
  yesFn: PropTypes.func.isRequired,
};

export default CancelChangesDialog;
