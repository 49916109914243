import React from 'react';
import PropTypes from 'prop-types';
// import { Base64 } from 'js-base64';

import Tab from './Tab';

import styles from './styles.module.scss';

class Tabs extends React.PureComponent {
  render() {
    const { items, selectedKey, onChange, hideTabs } = this.props;
    const activeTab = items.find((item) => item.key === selectedKey);
    //TBPP-15 (text codding issue)
    // const contentHtml = Base64.decode(activeTab.content);
    const contentHtml = activeTab.content;

    return (
      <div className={styles.tabs}>
        {!hideTabs && (
          <ol className={styles.tabList}>
            {items.map((item) => (
              <Tab
                key={item.key}
                title={item.title}
                isActive={selectedKey === item.key}
                onClick={() => onChange(item.key)}
              />
            ))}
          </ol>
        )}

        <div
          className={styles.tabContent}
          dangerouslySetInnerHTML={{ __html: contentHtml }}
        />
      </div>
    );
  }
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.string,
      name: PropTypes.string,
    }).isRequired
  ),
  selectedKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hideTabs: PropTypes.bool,
};

export default Tabs;
