import { createSelector } from 'reselect';

import { isDialogShownSelector } from './dialog';

const isPopupShownSelector = ({ memoryData: { overflow } }) =>
  Boolean(overflow.shown);

export const isPageOverflowShownSelector = createSelector(
  [isDialogShownSelector, isPopupShownSelector],
  (dialogShown, overflowShown) => dialogShown || overflowShown
);
