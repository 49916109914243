import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';

import { SYSTEM_ERROR_MSG, IS_RECAPTCHA_OPTIONAL } from 'consts';
import { invProviderIdSelector } from 'redux/selectors/auth';
import { backLogin } from 'redux/actions/mfa';
import { signup, getSignupData } from 'redux/actions/signup';

import SignupPage from './SignupPage';

function SignupPageContainer({ token }) {
  const [tokenData, setTokenData] = useState({});
  const [recaptcha, setRecaptcha] = useState();
  const [error, setError] = useState();

  const { search } = useLocation();
  const dispatch = useDispatch();
  const invProviderId = useSelector((state) => invProviderIdSelector(state));
  const onVerifyCaptcha = (recaptcha) => setRecaptcha(recaptcha);

  const { captchaDisabled } = parse(search);
  const recaptchaDisabled =
    captchaDisabled && JSON.parse(captchaDisabled) ? true : false;
  const isCaptchaDisabled = Boolean(recaptchaDisabled && IS_RECAPTCHA_OPTIONAL);

  useEffect(() => {
    (recaptcha || isCaptchaDisabled) &&
      dispatch(
        getSignupData({
          token,
          captchaDisabled: isCaptchaDisabled,
          recaptcha,
        })
      )
        .then((data) => {
          setTokenData(data);
          return data;
        })
        .catch(({ details: { userStatus, tokenStatus } }) => {
          if (userStatus === 'REGISTERED') {
            return dispatch(backLogin({ prevPath: '/registration' }));
          }
          const tokenError = {
            EXPIRED: `Request expired. Contact the healthcare provider.`,
            INVALID: `Invalid request. Contact the healthcare provider.`,
            CANCELED: `Request was canceled by the healthcare provider.`,
          }[tokenStatus];
          if (tokenError) {
            return setError(tokenError);
          }
          setError(SYSTEM_ERROR_MSG);
        });
  }, [dispatch, token, recaptcha, isCaptchaDisabled]);

  const onSubmit = (...args) => dispatch(signup(...args));
  const disabled = Boolean(error);
  const userEmail = tokenData ? tokenData.userEmail : '';

  return (
    <SignupPage
      email={userEmail}
      providerName={tokenData.providerName}
      invProviderId={invProviderId}
      authToken={tokenData.authToken}
      token={token}
      error={error}
      disabled={disabled}
      isCaptchaDisabled={isCaptchaDisabled}
      onSubmit={onSubmit}
      onVerifyCaptcha={onVerifyCaptcha}
    />
  );
}

SignupPageContainer.propTypes = {
  token: PropTypes.string.isRequired,
};

export default SignupPageContainer;
