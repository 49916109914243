export const getElementsOptions = ({ clientSecret }) => {
  return {
    clientSecret: clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        fontFamily: 'Arial, Helvetica, sans-serif'
      },
      rules: {
        '.Tab--selected': {borderColor: '#FF9A1F', boxShadow: 'none'},
        '.Tab--selected:hover': {borderColor: '#FF9A1F', boxShadow: 'none'},
        '.Tab--selected:focus': {borderColor: '#FF9A1F', boxShadow: 'none'},
        '.Label': {},
        '.Input': {borderColor: ' #9AB7BA'},
        '.p-TermsText': {borderColor: ' #9AB7BA'}
      }
    }
  }
};

export const paymentElementOptions = {
  layout: {
    type: 'tabs',
    defaultCollapsed: false,
  },
  terms: {
    card: 'never',
    usBankAccount: 'never',
  }
};