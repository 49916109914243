import { createSelector } from 'reselect';

// const statusFilter = (pi) =>
//   ['NEW', 'PENDING', 'APPROVED'].includes(pi.invitation.status);

const statusFilter = (pi) => ['NEW', 'PENDING'].includes(pi.invitation.status);

export const pendingRequestsSelector = createSelector(
  [({ sessionData: { patientInvitations } }) => patientInvitations],
  (patientInvitations) => patientInvitations.filter(statusFilter)
);

export const hasPendingRequestsSelector = createSelector(
  [pendingRequestsSelector],
  (patientInvitations) => Boolean(patientInvitations.length)
);
