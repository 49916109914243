import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { BILL } from 'redux/consts/downloadPdf';
import useGeneratePdf from 'utils/useGeneratePdf';
import GroupedLinkButtons from 'components/GroupedLinkButtons';
import MainLayout from 'layouts/MainLayout';
import SafesrcDocIframe from 'components/SafesrcDocIframe';
import { loadBill } from 'redux/actions/bills';

import styles from './styles.module.scss';

const headerProps = { includePatientsDropdown: false };

function EBillPage(props) {
  const [html, setHtml] = useState();
  const dispatch = useDispatch();
  const { invoiceId, billNumber } = props;

  const handlePdfDownload = useGeneratePdf(BILL, html, `Bill ${billNumber}`);

  useEffect(() => {
    dispatch(loadBill(props)).then((html) => {
      setHtml(html);
    });
  }, [props, dispatch]);

  return (
    <>
      <MainLayout headerProps={headerProps}>
        <div className={styles.wrapper}>
          <GroupedLinkButtons
            linkName={`Back to bills`}
            linkTo={{
              pathname: `/bills`,
              search: `highlighted=${invoiceId}`,
              viewed: invoiceId,
            }}
            buttons={[{ label: 'Download', onClick: handlePdfDownload }]}
          />

          {Boolean(html) && (
            <SafesrcDocIframe
              dataTestName={'billContent'}
              width={1035}
              srcDoc={html}
            />
          )}
        </div>
      </MainLayout>
    </>
  );
}

EBillPage.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  billNumber: PropTypes.string.isRequired,
};

export default React.memo(EBillPage);
