import { createSelector } from 'reselect';

import { loggedUserSelector } from './loggedUser';
import { byUserIdSelector } from './byUserId';
import { providersSelector } from './providerInfo';

export const patientProvidersSelector = ({
  sessionData: { patientProviders },
}) => Object.values(patientProviders);

export const hasConnectedPatientsSelector = ({
  sessionData: { patientProviders },
}) => Object.keys(patientProviders).length > 0;

export const patientProviderByIdSelector = (
  { sessionData: { patientProviders } },
  id
) => patientProviders[id];

export const currentPatientProviderIdSelector = createSelector(
  [loggedUserSelector, byUserIdSelector],
  (loggedUserId, byUserId) => {
    const userSetttings = byUserId[loggedUserId] || {};
    return userSetttings.selectedPatientProviderId;
  }
);

export const currentPatientProviderSelector = createSelector(
  [currentPatientProviderIdSelector, patientProvidersSelector],
  (patientProviderId, patientProviders) => {
    return patientProviders.find(
      (pp) => pp.patientProviderId === patientProviderId
    );
  }
);

export const currentProviderSelector = createSelector(
  [currentPatientProviderSelector, providersSelector],
  (patientProvider = {}, providers) => {
    return providers[patientProvider.providerId];
  }
);
