import { WAIT_FOR_ACTION } from 'redux-wait-for-action';

import {
  INITIAL_DATA_REQUEST,
  INITIAL_DATA_SUCCESS,
} from 'redux/consts/initialData';

export function loadInitialData(authToken) {
  return {
    type: INITIAL_DATA_REQUEST,
    authToken,
    [WAIT_FOR_ACTION]: INITIAL_DATA_SUCCESS,
  };
}

export function onInitialDataSuccess({ payload }) {
  return {
    type: INITIAL_DATA_SUCCESS,
    payload,
  };
}
