import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from '../styles.module.scss';

function SingleSelectContainer({ options, value, onChange }) {
  return (
    <>
      {options.map((option) => {
        return (
          <span
            key={option.key}
            className={cn(
              styles.popupItem,
              value.includes(option.key) && styles.selected
            )}
            onClick={() => onChange(option)}
          >
            {option.key}
          </span>
        );
      })}
    </>
  );
}

SingleSelectContainer.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default React.memo(SingleSelectContainer);
