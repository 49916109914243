import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import moment from 'moment';

import { docsByPatientProviderIdSelector } from 'redux/selectors/docs';
import { currentPatientProviderSelector } from 'redux/selectors/patientProviders';
import { getDocs } from 'redux/actions/docs';
import { selectDay } from 'redux/actions/selectedDay';
import LoadingContainer from 'components/LoadingContainer';
import { getSchedules } from 'redux/actions/schedules';

import PatientDocumentation from './PatientDocumentation';
import NoDocumentation from './NoDocumentation';

function PatientDocumentationContainer() {
  const { patientProviderId } = useSelector(
    currentPatientProviderSelector,
    shallowEqual
  );
  const isMobile = useMediaQuery({ maxWidth: 779 });
  const { viewed } = useLocation();

  const [schedules, setSchedules] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDocs(patientProviderId));
    dispatch(getSchedules(patientProviderId)).then(initSchedules);
  }, [patientProviderId, dispatch]);

  const docs = useSelector(
    (state) => docsByPatientProviderIdSelector(state, patientProviderId),
    shallowEqual
  );

  const showDay = (day) => {
    dispatch(selectDay({ patientProviderId, selectedDay: day }));
  };

  const initSchedules = (data) => {
    const today = moment().startOf('day');
    const result = data
      .filter((s) => {
        const dayDiff = moment(s.scheduleDate).diff(today, 'days', true);
        return dayDiff >= 0 && dayDiff < 8; // today + 7 days
      })
      .sort(function(a, b) {
        return (
          new Date(a.scheduleDate).getTime() -
          new Date(b.scheduleDate).getTime()
        );
      });
    const groupedResult = result.reduce((res, s) => {
      const group = res.find((r) =>
        moment(s.scheduleDate).isSame(r.date, 'day')
      );
      const groupToChange = group || { date: s.scheduleDate, items: [] };
      groupToChange.items.push(s);
      if (group) {
        return res.map((g) => {
          if (g.date === groupToChange.date) {
            return groupToChange;
          }
          return g;
        });
      }
      return [...res, groupToChange];
    }, []);
    setSchedules(groupedResult);
  };

  const handleAcceptAppointment = (scheduleId) =>
    setSchedules(
      schedules.map(({ date, items }) => {
        const mappedItems = items.map((item) =>
          item.scheduleId === scheduleId
            ? { ...item, confirmedFlag: true }
            : item
        );
        return { date, items: mappedItems };
      })
    );

  if (typeof docs === 'undefined') {
    return <LoadingContainer />;
  }

  return docs.length > 0 ? (
    <PatientDocumentation
      key={patientProviderId}
      documentation={docs}
      schedules={schedules}
      onScheduleChange={handleAcceptAppointment}
      showDay={showDay}
      isMobile={isMobile}
      patientProviderId={patientProviderId}
      viewed={viewed}
    />
  ) : (
    <NoDocumentation
      schedules={schedules}
      onScheduleChange={handleAcceptAppointment}
      isMobile={isMobile}
    />
  );
}

export default React.memo(PatientDocumentationContainer);
