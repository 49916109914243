import React from 'react';
import PropTypes from 'prop-types';

import UserIcon from './UserIcon';

import styles from './styles.module.scss';

class UserPreview extends React.PureComponent {
  render() {
    const { user, ...rest } = this.props;
    return (
      <div className={styles.wrapper} {...rest}>
        <UserIcon className={styles.icon} user={user} />
      </div>
    );
  }
}

UserPreview.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default UserPreview;
