import React from 'react';
import PropTypes from 'prop-types';

import ShowDialogButton from 'components/ShowDialogButton';
import { AGREEMENTS_DIALOG } from 'config/Dialog/consts';
import { SYSTEM_ERROR_MSG } from 'consts';

class ShowTermsButton extends React.PureComponent {
  onAgree = (signedAgreements) => {
    const { onChange } = this.props;
    onChange(signedAgreements);
  };

  onDecline = () => {
    const { onChange } = this.props;
    onChange(undefined);
  };

  render() {
    const {
      loadAgreements,
      onTermsError,
      filterAgreements,
      ...rest
    } = this.props;
    return (
      <ShowDialogButton
        route={AGREEMENTS_DIALOG}
        routeProps={{
          title: `Terms of Use`,
          signFn: this.onAgree,
          declineFn: this.onDecline,
        }}
        loadInitialData={loadAgreements}
        filterInitialData={filterAgreements}
        initialDataKey="agreements"
        errorMsg={SYSTEM_ERROR_MSG}
        onLoad={onTermsError}
        {...rest}
      >
        Terms of Use
      </ShowDialogButton>
    );
  }
}

ShowTermsButton.propTypes = {
  loadAgreements: PropTypes.func.isRequired,
  filterAgreements: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onTermsError: PropTypes.func.isRequired,
};

export default ShowTermsButton;
