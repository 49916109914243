import { combineSagas } from './utils';

import authentication from './authentication';
import signup from './signup';
import resetPassword from './resetPassword';
import providersRequests from './providersRequests';
import agreements from './agreements';
import updateUserData from './updateUserData';
import changeUserPassword from './changeUserPassword';
import docs from './docs';
import bills from './bills';
import billsPayment from './billsPayment';
import schedules from './schedules';
import downloadPdf from './downloadPdf';

export default combineSagas([
  authentication,
  signup,
  resetPassword,
  providersRequests,
  agreements,
  updateUserData,
  changeUserPassword,
  docs,
  bills,
  billsPayment,
  schedules,
  downloadPdf,
]);
