import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ErrorBar from 'components/ErrorBar';
import AuthLayout from 'layouts/AuthLayout';
import MultiTabsFormProgress from 'components/MultiTabsFormProgress';
import Button from 'components/Button';
import Recaptcha from 'components/Recaptcha';
import { scrollToElement, scrollToError } from 'utils';

import Step1 from './SignupSteps/Step1';
import Step2 from './SignupSteps/Step2';
import Step3 from './SignupSteps/Step3';
import Step4 from './SignupSteps/Step4';

import styles from './styles.module.scss';

const stepCfg = {
  1: Step1,
  2: Step2,
  3: Step3,
  4: Step4,
};

const getStep1Errors = ({ firstName, lastName, phone }) => {
  const result = {};
  if (!firstName) {
    result['firstName'] = 'First name is required';
  }
  if (!lastName) {
    result['lastName'] = 'Last name is required';
  }
  if (!phone) {
    result['phone'] = 'Mobile number is required';
  } else if (phone.includes('_')) {
    result['phone'] = 'Mobile number is invalid';
  }
  return Object.keys(result).length === 0 ? undefined : result;
};

const getStep2Errors = ({ dob, zip, relationType, clinicianName }) => {
  const today = moment();
  const yearOfBirth = parseInt(dob.split('/')[2]);
  const result = {};
  if (!dob) {
    result['dob'] = `Patient's DOB is required`;
  } else if (!moment(dob, 'MM/DD/YYYY', true).isValid() || yearOfBirth < 1900) {
    result['dob'] = `Patient's DOB is invalid`;
  } else if (moment(dob).isAfter(today)) {
    result['dob'] = `Patient's DOB can't be in future`;
  }
  if (!zip) {
    result['zip'] = `Patient's zip code is required`;
  } else if (zip.length !== 5) {
    result['zip'] = `Patient's zip code is invalid`;
  }
  if (!relationType) {
    result['relationType'] = 'Your relationship to patient is required';
  }
  if (!clinicianName) {
    result['clinicianName'] = `Any treating clinician's first name is required`;
  }
  return Object.keys(result).length === 0 ? undefined : result;
};

const getStep3Errors = ({ password, signedAgreements }) => {
  const result = {};
  if (!password) {
    result['password'] = 'Password is required';
  } else if (password.search(/\s/) >= 0) {
    result['password'] = 'Password cannot have spaces';
  } else if (password.length < 8) {
    result['password'] = 'Password must be between 8 and 25 characters';
  } else if (password.search(/[a-zA-Z]/) === -1) {
    result['password'] = 'Password must have at least one letter';
  } else if (password.search(/\d/) === -1) {
    result['password'] = 'Password must have at least one number';
  }
  if (!signedAgreements) {
    result['signedAgreements'] = `Press on "Terms of Use" to agree`;
  }
  return Object.keys(result).length === 0 ? undefined : result;
};

class SignupPage extends React.PureComponent {
  state = {
    currentStep: 1,
    form: {
      //first step
      firstName: '',
      lastName: '',
      phone: '',

      //second step
      dob: '',
      zip: '',
      relationType: '',
      clinicianName: '',

      //third step
      password: '',
      signedAgreements: undefined,
      termsError: '',
    },
    captchaVerified: false,
    loading: false,
    errors: { form: this.props.error || '' },
  };

  recaptchaRef = React.createRef();
  progressRef = React.createRef();

  scrollToProgress = () =>
    scrollToElement(this.progressRef.current, { duration: 100 });

  addError = (name, message) =>
    this.setState((state) => ({
      ...state,
      errors: { ...state.errors, [name]: message },
    }));

  onInputChange = (name) => ({ target: { value } }) => {
    if (['password'].includes(name) && value.match(/\s/)) return;
    this.setState((state) => ({
      ...state,
      form: { ...state.form, [name]: value },
      errors:
        name !== 'signedAgreements'
          ? { ...state.errors, [name]: undefined }
          : {},
    }));
  };

  trimFormFields = (names) => {
    const state = this.state;
    const result = {
      ...state,
      form: {
        ...state.form,
        ...names.reduce(
          (acc, name) => ({ ...acc, [name]: state.form[name].trim() }),
          {}
        ),
      },
    };
    return result;
  };

  moveForward = () => {
    const state = this.trimFormFields([
      'firstName',
      'lastName',
      'relationType',
      'clinicianName',
    ]);
    const { currentStep, form, errors } = state;
    const setState = (changes) => this.setState({ ...state, ...changes });
    if (currentStep === 1) {
      const stepErrors = getStep1Errors(form);
      if (stepErrors) {
        const [firstError] = Object.keys(stepErrors);
        scrollToError(firstError);
        return setState({ errors: stepErrors });
      }
      setState({ currentStep: 2, errors: {} });
      return this.scrollToProgress();
    }
    if (currentStep === 2) {
      const stepErrors = getStep2Errors(form);
      if (stepErrors) {
        const [firstError] = Object.keys(stepErrors);
        scrollToError(firstError);
        return setState({ errors: stepErrors });
      }
      setState({ currentStep: 3, errors: {} });
      return this.scrollToProgress();
    }
    if (currentStep === 3) {
      if (errors.form) return;
      const stepErrors = getStep3Errors(form);
      if (stepErrors) {
        const [firstError] = Object.keys(stepErrors);
        scrollToError(firstError);
        return setState({ errors: stepErrors });
      }
      this.confirmSignUp();
    }
  };

  onTermsError = (error) => {
    this.setState({
      errors: { form: error },
      form: { ...this.state.form, termsError: error ? true : false },
    });
  };

  confirmSignUp = () => {
    this.setState({ loading: true });
    const { onSubmit, authToken, token, invProviderId } = this.props;
    const { form } = this.state;
    return onSubmit({ form, authToken, token, invProviderId })
      .then((data) => {
        this.setState({ currentStep: 4, loading: false });
        this.scrollToProgress();
        return data;
      })
      .catch((e) => {
        if (this.recaptchaRef.current) {
          this.recaptchaRef.current.reset();
        }
        this.setState(
          {
            loading: false,
            form: { ...form, signedAgreements: undefined },
            errors: {
              ...this.state.errors,
              form:
                e.cause && e.cause === 'signAgreement'
                  ? { signAgreement: e.message }
                  : e.message,
            },
          },
          () => {
            return scrollToError('form');
          }
        );
      });
  };

  onCaptchaLoad = () =>
    setTimeout(() => this.recaptchaRef.current.execute(), 500);

  onCaptchaVerify = (recaptcha) => {
    const { onVerifyCaptcha } = this.props;
    this.setState({ captchaVerified: Boolean(recaptcha) });
    onVerifyCaptcha(recaptcha);
  };

  moveBack = () => {
    const { currentStep } = this.state;
    if (currentStep === 2) {
      this.setState({ currentStep: 1, errors: {} });
      return this.scrollToProgress();
    }
    if (currentStep === 3) {
      this.setState({ currentStep: 2, errors: {} });
      return this.scrollToProgress();
    }
  };

  render() {
    const {
      disabled,
      email,
      authToken,
      providerName,
      error,
      isCaptchaDisabled,
      onVerifyCaptcha,
    } = this.props;
    const { form, errors, currentStep, loading } = this.state;
    const StepComponent = stepCfg[currentStep];
    const infoNote = {
      1: `Please enter a few details about yourself. Make sure your email address and mobile phone are correct!`,
      2: `Please enter the patient's information to help confirm your request for access.`,
      3: `Choose a password and agree to the Terms of Use.`,
    }[currentStep];
    const signedAgreementError =
      Boolean(errors.form) && errors.form.signAgreement;
    return (
      <AuthLayout
        title="Registration"
        note="Sign up for your free therapyBOSS patient portal account."
        infoNote={infoNote}
        providerName={providerName}
      >
        <div className={styles.wrapper}>
          <div ref={this.progressRef}>
            <MultiTabsFormProgress
              activeKey={currentStep}
              tabs={[
                { key: 1, hint: 'Your information' },
                { key: 2, hint: 'Patient information' },
                { key: 3, hint: 'Create account' },
                { key: 4, hint: 'Success' },
              ]}
            />
          </div>
          <div className={styles.form}>
            {(Boolean(errors.form) || Boolean(error)) && currentStep !== 3 && (
              <ErrorBar
                name="form"
                className={styles.errorBar}
                error={errors.form || error}
              />
            )}
            <div className={styles.step}>
              <div>
                <StepComponent
                  disabled={disabled}
                  email={email}
                  authToken={authToken}
                  form={form}
                  errors={signedAgreementError ? {} : errors}
                  onInputChange={this.onInputChange}
                  {...([3].includes(currentStep)
                    ? { onTermsError: this.onTermsError, signedAgreementError }
                    : {})}
                />
              </div>
            </div>
            {signedAgreementError && (
              <ErrorBar
                name="form"
                className={styles.signAgreementsError}
                error={signedAgreementError}
              />
            )}
          </div>
          <div className={styles.buttons}>
            {[2, 3].includes(currentStep) && (
              <Button
                text="Back"
                icon="back"
                secondary={true}
                disabled={disabled}
                onClick={this.moveBack}
                className={styles.prevBtn}
              />
            )}
            {[1, 2, 3].includes(currentStep) && (
              <Button
                text="Continue"
                icon="forward"
                disabled={disabled}
                loading={loading}
                onClick={this.moveForward}
                className={styles.nextBtn}
              />
            )}
            {[4].includes(currentStep) && (
              <Button
                text="Done"
                disabled={disabled}
                to="/login"
                className={styles.doneBtn}
              />
            )}
          </div>
        </div>
        {[1].includes(currentStep) && !isCaptchaDisabled && (
          <Recaptcha
            ref={this.recaptchaRef}
            onLoad={this.onCaptchaLoad}
            onVerify={onVerifyCaptcha}
          />
        )}
      </AuthLayout>
    );
  }
}

SignupPage.propTypes = {
  isCaptchaDisabled: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
  email: PropTypes.string,
  providerName: PropTypes.string,
  authToken: PropTypes.shape({}),
  invProviderId: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onVerifyCaptcha: PropTypes.func.isRequired,
};

export default SignupPage;
