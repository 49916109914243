import { takeLatest, put } from 'redux-saga/effects';
import {
  PAY_BILL_REQUEST,
  PAY_BILL_NOW_REQUEST,
  GET_PAYMENT_METHOD_REQUEST,
  DELETE_PAYMENT_METHOD_REQUEST,
  CREATE_SETUP_INTENT_REQUEST,
  CANCEL_SETUP_INTENT_REQUEST,
  CREATE_PAYMENT_INTENT_REQUEST,
  GET_PAYMENT_COMPLETED_REQUEST,
} from 'redux/consts/billsPayment';

import {
  onPayBillSuccess,
  onPayBillFailure,
  onPayBillNowSuccess,
  onLoadPaymentMethodSuccess,
  onCreatePaymentIntentSuccess,
  onCreateSetupIntentSuccess,
  onRetrievePaymentCompletedSuccess,
} from 'redux/actions/billsPayment';

import { updateHasPaymentMethod } from 'redux/actions/loggedUser';


import { apiSecureRequest, combineSagas } from './utils';

function* payBillSagaWorker({ params }) {
  const { patientId, invoiceId } = params;
  const url = `/user-bills/payment/pay?patientId=${patientId}&providerInvoiceId=${invoiceId}`;
  const options = {
    method: 'GET',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    yield put(onPayBillSuccess({ payload }));
    return payload;
  } catch (error) {
    yield put(onPayBillFailure({ error }));
    return false;
  }
}

function* payBillNowSagaWorker({ params }) {
  const { patientId, invoiceId } = params;
  const url = `/user-bills/payment/payNow?patientId=${patientId}&providerInvoiceId=${invoiceId}`;
  const options = {
    method: 'POST',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    yield put(onPayBillNowSuccess({ payload }));
    return payload;
  } catch (error) {
    return null;
  }
}

export function* loadPaymentMethodSagaWorker() {
  const url = `/user-bills/payment/paymentMethod`;
  const options = {
    method: 'GET',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    if (payload) {
      yield put(updateHasPaymentMethod(true));
    }
    yield put(onLoadPaymentMethodSuccess({ payload }));
    return payload;
  } catch (error) {
    return null;
  }
}

function* deletePaymentMethodSagaWorker({ params }) {
  const { paymentMethodId } = params;
  const url = `/user-bills/payment/paymentMethod?paymentMethodId=${paymentMethodId}`;
  const options = {
    method: 'DELETE',
  };
  try {
    yield put(updateHasPaymentMethod(false));
    const payload = yield apiSecureRequest(url, options);
    return payload;
  } catch (error) {
    return null;
  }
}

function* createSetupIntentSagaWorker() {
  const url = `/user-bills/payment/createSetupIntent`;
  const options = {
    method: 'POST',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    yield put(onCreateSetupIntentSuccess({ payload }));
    return payload;
  } catch (error) {
    return null;
  }
}

function* cancelSetupIntentSagaWorker({ params }) {
  const { setupIntentId } = params;
  const url = `/user-bills/payment/cancelSetupIntent?setupIntentId=${setupIntentId}`;
  const options = {
    method: 'PUT',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    return payload;
  } catch (error) {
    return null;
  }
}

function* createPaymentIntentSagaWorker({ params }) {
  const { patientId, invoiceId } = params;
  const url = `/user-bills/payment/createPaymentIntent?patientId=${patientId}&providerInvoiceId=${invoiceId}`;
  const options = {
    method: 'POST',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    yield put(onCreatePaymentIntentSuccess({ payload }));
    return payload;
  } catch (error) {
    return null;
  }
}

function* retrievePaymentCompletedSagaWorker({ params }) {
  const { patientId, invoiceId, paymentIntentId } = params;
  const url = `/user-bills/payment/retrievePaymentCompleted?patientId=${patientId}&providerInvoiceId=${invoiceId}&paymentIntentId=${paymentIntentId}`;
  const options = {
    method: 'GET',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    yield put(onRetrievePaymentCompletedSuccess({ payload }));
    return payload;
  } catch (error) {
    return null;
  }
}

function* payBillSaga() {
  yield takeLatest(PAY_BILL_REQUEST, payBillSagaWorker);
}

function* payBillNowSaga() {
    yield takeLatest(PAY_BILL_NOW_REQUEST, payBillNowSagaWorker);
}

function* loadPaymentMethodSaga() {
  yield takeLatest(GET_PAYMENT_METHOD_REQUEST, loadPaymentMethodSagaWorker);
}

function* deletePaymentMethodSaga() {
  yield takeLatest(DELETE_PAYMENT_METHOD_REQUEST, deletePaymentMethodSagaWorker);
}

function* createSetupIntentSaga() {
  yield takeLatest(CREATE_SETUP_INTENT_REQUEST, createSetupIntentSagaWorker);
}

function* cancelSetupIntentSaga() {
  yield takeLatest(CANCEL_SETUP_INTENT_REQUEST, cancelSetupIntentSagaWorker);
}

function* createPaymentIntentSaga() {
  yield takeLatest(CREATE_PAYMENT_INTENT_REQUEST, createPaymentIntentSagaWorker);
}

function* retrievePaymentCompletedSaga() {
  yield takeLatest(GET_PAYMENT_COMPLETED_REQUEST, retrievePaymentCompletedSagaWorker);
}

export default combineSagas([
  payBillSaga,
  payBillNowSaga,
  loadPaymentMethodSaga,
  deletePaymentMethodSaga,
  createSetupIntentSaga,
  cancelSetupIntentSaga,
  createPaymentIntentSaga,
  retrievePaymentCompletedSaga,
]);