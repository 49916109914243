import { useState, useCallback, useEffect } from 'react';

import { scrollToElement } from 'utils';

export const TRIGGER_RANGE = 3;

const useInfinityScroll = (params) => {
  const { limit, selected, onGetData } = params;
  const initialNext = limit - TRIGGER_RANGE;
  const [next, setNext] = useState(initialNext);

  const handleGetData = useCallback(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onGetData();
          observer.unobserve(entry.target);
          setNext(next + limit);
        }
      });
    },
    [limit, next, onGetData]
  );

  useEffect(() => {
    if (selected) {
      const { dataScrollId, ...params } = selected;
      scrollToElement(dataScrollId, params);
    }
    const target = document.querySelector(`[data-scroll-id='${next}']`);
    const observer = new IntersectionObserver(handleGetData, {
      rootMargin: '0px',
      threshold: 1.0,
    });
    target && observer.observe(target);
    return () => target && observer.unobserve(target);
  }, [next, selected, initialNext, handleGetData]);
};

export default useInfinityScroll;
