import React from 'react';
import PropTypes from 'prop-types';
import equals from 'deep-equal';
import cn from 'classnames';

import ErrorBar from 'components/ErrorBar';
import LoadingContainer from 'components/LoadingContainer';
import TextField from 'components/TextField';
import ReadonlyField from 'components/ReadonlyField';
import Button from 'components/Button';
import ShowDialogButton from 'components/ShowDialogButton';
import { CHANGE_PASSWORD_DIALOG } from 'config/Dialog/consts';
import { pick } from 'utils/object';
import { scrollToElement, scrollToError } from 'utils';
import { SYSTEM_ERROR_MSG } from 'consts';
import PaymentMethod from 'components/PaymentMethod';

import Title from '../Title';

import styles from './styles.module.scss';

const getFormErrors = ({ firstName, lastName, phone, currentPassword }) => {
  const result = {};
  if (!firstName) {
    result['firstName'] = 'First name is required';
  }
  if (!lastName) {
    result['lastName'] = 'Last name is required';
  }
  if (!phone) {
    result['phone'] = 'Mobile number is required';
  } else if (phone.includes('_')) {
    result['phone'] = 'Mobile number is invalid';
  }
  if (!currentPassword) {
    result['currentPassword'] = 'Password is required';
  }
  return Object.keys(result).length === 0 ? undefined : result;
};

const formByProps = (props) => ({
  firstName: props.userData.firstName || '',
  lastName: props.userData.lastName || '',
  phone: props.userData.phone || '',
  currentPassword: '',
});

class UserInfoForm extends React.PureComponent {
  state = {
    form: formByProps(this.props),
    errors: {},
    loading: false,
    editMode: false,
    paymentMethod: null,
    loadingPayment: false,
    loadingPaymentError: null,
    hasPaymentMethod: this.props.userData.hasPaymentMethod || false,
  };

  componentDidMount() {
    const { hasPaymentMethod } = this.state;
    const { loadPaymentMethodData } = this.props;
    if (hasPaymentMethod) {
      this.setState({ loadingPayment: true });
      loadPaymentMethodData()
        .then((data) => {
          this.setState({ paymentMethod: data });
        })
        .catch((e) => {
          let errorMsg = e.message || SYSTEM_ERROR_MSG;
          this.setState({ loadingPaymentError: errorMsg });
        })
        .finally(() => {
          this.setState({ loadingPayment: false });
        });
    }
  }

  titleRef = React.createRef();

  addError = (name, message) =>
    this.setState((state) => ({
      ...state,
      errors: { ...state.errors, [name]: message },
    }));

  onInputChange = (name) => ({ target: { value } }) => {
    if (['currentPassword'].includes(name)) {
      if (value.length > 25) return;
    }
    this.setState((state) => ({
      ...state,
      form: { ...state.form, [name]: value },
      errors: { ...state.errors, [name]: undefined },
    }));
  };

  onSaveChanges = () => {
    const { onUpdateUserData } = this.props;
    const { form } = this.state;
    const trimmedForm = Object.keys(form).reduce(
      (acc, prop) => ({ ...acc, [prop]: form[prop].trim() }),
      {}
    );
    this.setState({ form: trimmedForm });
    const errors = getFormErrors(trimmedForm);
    if (errors) {
      const [firstError] = Object.keys(errors);
      scrollToError(firstError);
      return this.setState({ errors });
    }
    this.setState({ errors: {}, loading: true });
    return onUpdateUserData(form)
      .then((data) => {
        this.setState({
          editMode: false,
          form: { ...this.state.form, currentPassword: '' },
        });
        scrollToElement(this.titleRef.current);
        return data;
      })
      .catch((error) => {
        if (error.details) {
          for (const [key, value] of Object.entries(error.details)) {
            scrollToError(key);
            this.addError(key, value);
          }
        }
      })
      .finally(() => this.setState({ loading: false }));
  };

  onDeletePaymentMethodHandler = () => {
    const { onDeletePaymentMethod } = this.props;
    const { paymentMethod: { id } } = this.state;

    this.setState((state) => ({
      ...state,
      paymentMethod: undefined,
      hasPaymentMethod: false,
    }));
    return onDeletePaymentMethod({ paymentMethodId: id });
  };

  restoreFormByProps = (callback) => {
    const form = formByProps(this.props);
    this.setState({ form, errors: {} }, callback);
  };

  changeEditMode = (editMode) => {
    if (!editMode) {
      const namesToCompare = ['firstName', 'lastName', 'phone'];
      const newData = pick(this.state.form, namesToCompare);
      const oldData = pick(this.props.userData, namesToCompare);
      if (!equals(newData, oldData)) {
        return this.props.showCancelChangesDialog(() =>
          this.restoreFormByProps(() => this.setState({ editMode: false }))
        );
      }
    } else {
      scrollToElement(this.titleRef.current);
    }
    return this.setState({ editMode, errors: {} });
  };

  render() {
    const { className, userData } = this.props;
    const { email } = userData;
    const { form, paymentMethod, errors, loading, loadingPayment, loadingPaymentError, hasPaymentMethod, editMode } = this.state;
    const { firstName, lastName, phone, currentPassword } = form;

    return (
      <div className={cn(styles.wrapper, className)}>
        <Title title="My information" ref={this.titleRef} />
        {editMode && (
          <>
            <TextField
              name="firstName"
              value={firstName}
              onChange={this.onInputChange('firstName')}
              error={errors['firstName']}
              label="First Name"
              placeholder="Enter here"
              className={styles.input}
              type="text"
              maxLength={25}
            />
            <TextField
              name="lastName"
              value={lastName}
              onChange={this.onInputChange('lastName')}
              error={errors['lastName']}
              label="Last Name"
              placeholder="Enter here"
              className={styles.input}
              type="text"
              maxLength={25}
            />
            <TextField
              name="email"
              value={email}
              readOnly={true}
              label="Email"
              placeholder="Enter here"
              className={styles.input}
              type="text"
              maxLength={50}
            />
            <TextField
              name="phone"
              value={phone}
              onChange={this.onInputChange('phone')}
              error={errors['phone']}
              label="Mobile phone"
              placeholder="999-999-9999"
              className={styles.input}
              type="text"
              mask="999-999-9999"
            />
            <TextField
              name="currentPassword"
              value={currentPassword}
              onChange={this.onInputChange('currentPassword')}
              error={errors['currentPassword']}
              label="To save changes enter your current password"
              placeholder="Enter here"
              className={styles.input}
              type="password"
            />
          </>
        )}
        {!editMode && (
          <>
            <ReadonlyField title="First Name" text={firstName} />
            <ReadonlyField title="Last Name" text={lastName} />
            <ReadonlyField title="Email" text={email} />
            <ReadonlyField title="Mobile phone" text={phone} />
          </>
        )}
        <button
          className={cn('link', styles.toggleEditMode)}
          onClick={() => this.changeEditMode(!editMode)}
        >
          {editMode ? `` : `Change my information`}
        </button>
        {editMode && (
          <div className={styles.buttonWrapper}>
            <Button
              text="Cancel"
              secondary={true}
              className={styles.cancelBtn}
              onClick={() => this.changeEditMode(!editMode)}
            />
            <Button
              text="Save changes"
              loading={loading}
              className={styles.saveBtn}
              onClick={this.onSaveChanges}
            />
          </div>
        )}
        {hasPaymentMethod && (
          <div className={styles.paymentBlock}>
            <Title title="Payment method" />
            {loadingPayment &&
              <LoadingContainer className={styles.loader} />
            }
            {loadingPaymentError && (
              <ErrorBar error={loadingPaymentError} />
            )}
            {paymentMethod &&
              <PaymentMethod
                payment={paymentMethod}
                onDelete={this.onDeletePaymentMethodHandler}
            />}
          </div>
        )}
        <div className={styles.passwordBlock}>
          <Title title="Change password" />
          <ShowDialogButton route={CHANGE_PASSWORD_DIALOG} className="link">
            Change password
          </ShowDialogButton>
        </div>
      </div>
    );
  }
}

UserInfoForm.defaultProps = {
  userData: {},
};

UserInfoForm.propTypes = {
  className: PropTypes.string,
  userData: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    hasPaymentMethod: PropTypes.bool.isRequired,
  }).isRequired,
  loadPaymentMethodData: PropTypes.func.isRequired,
  onDeletePaymentMethod: PropTypes.func.isRequired,
  onUpdateUserData: PropTypes.func.isRequired,
  showCancelChangesDialog: PropTypes.func.isRequired,
};

export default UserInfoForm;
