export const CHANGE_PASSWORD_DIALOG = 'CHANGE_PASSWORD_DIALOG';
export const CANCEL_REQUEST_DIALOG = 'CANCEL_REQUEST_DIALOG';
export const CANCEL_CHANGES_DIALOG = 'CANCEL_CHANGES_DIALOG';
export const CONFIRM_REQUEST_DIALOG = 'CONFIRM_REQUEST_DIALOG';
export const AGREEMENTS_DIALOG = 'AGREEMENTS_DIALOG';
export const INFO_DIALOG = 'INFO_DIALOG';
export const STRIPE_CHECKOUT_DIALOG = 'STRIPE_CHECKOUT_DIALOG';
export const ELECTRONICALY_PAYMENT_DIALOG ='ELECTRONICALY_PAYMENT_DIALOG';
export const ELECTRONICALY_PAYMENT_ISSUE_DIALOG ='ELECTRONICALY_PAYMENT_ISSUE_DIALOG';
export const CONFIRMATION_DIALOG = 'CONFIRMATION_DIALOG';
