import { SHOW_OVERFLOW, HIDE_OVERFLOW } from 'redux/consts/overflow';

export function showOverflow() {
  return {
    type: SHOW_OVERFLOW,
  };
}

export function hideOverflow() {
  return {
    type: HIDE_OVERFLOW,
  };
}
