import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Button from 'components/Button';

import styles from './styles.module.scss';

class ElectronicalyPaymentIssueDialog extends React.PureComponent {
  componentDidMount() {
    const { checkConfig } = this.props;
    checkConfig({ style: { padding: '20px 20px 0' }, removeClose: true });
  }

  render() {
    const { message, onDialogClose } = this.props;
    return (
      <>
        <div
          className={cn(styles.message, styles.info)}
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <div className={styles.buttons}>
          <Button text="Ok" className={styles.button} onClick={onDialogClose} />
        </div>
      </>
    );
  }
}

ElectronicalyPaymentIssueDialog.propTypes = {
  onDialogClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  checkConfig: PropTypes.func.isRequired,
};

export default ElectronicalyPaymentIssueDialog;
