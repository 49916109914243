import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

class Tab extends React.PureComponent {
  render() {
    const { onClick, title, isActive } = this.props;
    return (
      <li
        className={cn(isActive && styles.tabListActive, styles.tabListItem)}
        onClick={onClick}
      >
        {title}
      </li>
    );
  }
}

Tab.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default Tab;
