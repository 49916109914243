export const SIGN_AGREEMENT_REQUEST = 'SIGN_AGREEMENT_REQUEST';
export const SIGN_AGREEMENT_SUCCESS = 'SIGN_AGREEMENT_SUCCESS';
export const SIGN_AGREEMENT_FAILURE = 'SIGN_AGREEMENT_FAILURE';

export const GET_TERMS_REQUEST = 'GET_TERMS_REQUEST';
export const GET_TERMS_SUCCESS = 'GET_TERMS_SUCCESS';
export const GET_TERMS_FAILURE = 'GET_TERMS_FAILURE';

export const SIGN_TB_AGREEMENT_REQUEST = 'SIGN_TB_AGREEMENT_REQUEST';
export const SIGN_TB_AGREEMENT_SUCCESS = 'SIGN_TB_AGREEMENT_SUCCESS';
export const SIGN_TB_AGREEMENT_FAILURE = 'SIGN_TB_AGREEMENT_FAILURE';

export const CHECK_PROVIDER_AGREEMENT_REQUEST =
  'CHECK_PROVIDER_AGREEMENT_REQUEST';
export const CHECK_PROVIDER_AGREEMENT_SUCCESS =
  'CHECK_PROVIDER_AGREEMENT_SUCCESS';
export const CHECK_PROVIDER_AGREEMENT_FAILURE =
  'CHECK_PROVIDER_AGREEMENT_FAILURE';
