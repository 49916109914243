import { stringify } from 'query-string';
import { push } from 'connected-react-router';

import { select, takeLatest, put } from 'redux-saga/effects';

import {
  SEND_MFA_CODE_REQUEST,
  CONFIRM_MFA_CODE_REQUEST,
  BACK_LOGIN,
} from 'redux/consts/mfa';
import {
  onSendMFACodeSuccess,
  onSendMFACodeFailure,
  onConfirmMFACodeSuccess,
  onConfirmMFACodeFailure,
} from 'redux/actions/mfa';
import { getDeviceId } from 'redux/actions/settings';
import { temporaryToken } from 'redux/actions/auth';
import { temporaryTokenSelector } from 'redux/selectors/auth';
import { apiTemporarySecureRequest, combineSagas } from 'redux/sagas/utils';
import { SYSTEM_ERROR_MSG } from 'consts';

import completeAuth from './auth/completeAuth';
import { checkTBAgreementWorker } from './check-agreement';

export function* checkMFAWorker(authToken) {
  if (authToken.mfa) {
    yield put(temporaryToken({ payload: authToken }));
    yield put(push('/mfa'));
    throw new Error('MFA is required.');
  }
}

function* sendMFACodeWorker() {
  const { deviceId } = yield put(getDeviceId());
  const params = { deviceId };
  const url = `/oauth/mfa?${stringify(params)}`;
  const options = {
    method: 'GET',
  };
  try {
    const payload = yield apiTemporarySecureRequest(url, options);
    yield put(onSendMFACodeSuccess({ payload }));
    return payload;
  } catch {
    const error = new Error(SYSTEM_ERROR_MSG);
    yield put(onSendMFACodeFailure({ error }));
    return null;
  }
}

function* sendMFACodeSaga() {
  yield takeLatest(SEND_MFA_CODE_REQUEST, sendMFACodeWorker);
}

function* confirmMFACodeWorker({ code }) {
  const { deviceId } = yield put(getDeviceId());
  const params = { deviceId, verificationCode: code };
  const url = `/oauth/mfa?${stringify(params)}`;
  const options = {
    method: 'POST',
  };
  try {
    const payload = yield apiTemporarySecureRequest(url, options);
    const authToken = yield select(temporaryTokenSelector);
    yield checkTBAgreementWorker(authToken);
    yield completeAuth({ authToken });
    yield put(onConfirmMFACodeSuccess({ payload }));
    return payload;
  } catch (error) {
    yield put(
      onConfirmMFACodeFailure({
        ...error,
        message:
          error.details && error.details.cause
            ? error.message
            : SYSTEM_ERROR_MSG,
      })
    );
    return null;
  }
}

function* confirmMFACodeSaga() {
  yield takeLatest(CONFIRM_MFA_CODE_REQUEST, confirmMFACodeWorker);
}

function* backLoginWorker(params) {
  const { prevPath, unblock, message } = params;
  yield put(push({ pathname: '/login', prevPath, unblock, message }));
}

function* backLoginSaga() {
  yield takeLatest(BACK_LOGIN, backLoginWorker);
}

export default combineSagas([
  sendMFACodeSaga,
  confirmMFACodeSaga,
  backLoginSaga,
]);
