import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';

import {
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_FAILURE,
  TEMPORARY_TOKEN,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  CLIENT_SIDE_LOGOUT,
} from 'redux/consts/auth';

export function auth({ username, password, rememberMe, ...rest }) {
  return {
    type: AUTH_REQUEST,
    username,
    password,
    rememberMe,
    ...rest,
    [WAIT_FOR_ACTION]: AUTH_SUCCESS,
    [ERROR_ACTION]: AUTH_FAILURE,
  };
}

export function onAuthSuccess({ authToken }) {
  return {
    type: AUTH_SUCCESS,
    authToken,
  };
}

export function onAuthFailure({ error }) {
  return {
    type: AUTH_FAILURE,
    error,
  };
}

export function refreshToken() {
  return {
    type: REFRESH_TOKEN_REQUEST,
    [WAIT_FOR_ACTION]: REFRESH_TOKEN_SUCCESS,
    [ERROR_ACTION]: REFRESH_TOKEN_FAILURE,
  };
}

export function onRefreshTokenSuccess({ payload, isTemporary }) {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload,
    isTemporary,
  };
}

export function onRefreshTokenFailure({ error }) {
  return {
    type: REFRESH_TOKEN_FAILURE,
    error,
  };
}

export function temporaryToken({ payload }) {
  return {
    type: TEMPORARY_TOKEN,
    payload,
  };
}

export function logout() {
  return {
    type: LOGOUT_REQUEST,
    [WAIT_FOR_ACTION]: LOGOUT_SUCCESS,
    [ERROR_ACTION]: LOGOUT_FAILURE,
  };
}
export function onLogoutSuccess({ payload }) {
  return {
    type: LOGOUT_SUCCESS,
    payload,
  };
}

export function onLogoutFailure({ error }) {
  return {
    type: LOGOUT_FAILURE,
    error,
  };
}

export function onClientSideLogout() {
  return {
    type: CLIENT_SIDE_LOGOUT,
  };
}
