import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import cn from 'classnames';

import ProviderPopup from 'components/ProviderPopup';

import styles from './styles.module.scss';

class ProviderPreview extends React.PureComponent {
  state = { popupShown: false };

  toggleMenu = (e) => {
    e.stopPropagation();
    this.setState((state) => ({ popupShown: !state.popupShown }));
  };

  hideMenu = () => this.setState({ popupShown: false });

  render() {
    const { popupShown } = this.state;
    const { provider, customCutInfoStyle } = this.props;
    return (
      <div className={styles.provider}>
        <div
          className={cn(
            styles.providerName,
            styles.cutInfo,
            customCutInfoStyle
          )}
          onClick={this.toggleMenu}
        >
          {provider.name}
        </div>
        <button
          type="button"
          className={styles.icon}
          onClick={this.toggleMenu}
        />
        {popupShown && (
          <ProviderPopup provider={provider} onClose={this.hideMenu} />
        )}
      </div>
    );
  }
}

ProviderPreview.propTypes = {
  provider: PropTypes.shape().isRequired,
  customCutInfoStyle: PropTypes.string,
};

export default onClickOutside(ProviderPreview, {
  handleClickOutside: (instance) => instance.hideMenu,
});
