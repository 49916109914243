import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';

import {
  BACK_LOGIN,
  SEND_MFA_CODE_REQUEST,
  SEND_MFA_CODE_SUCCESS,
  SEND_MFA_CODE_FAILURE,
  CONFIRM_MFA_CODE_REQUEST,
  CONFIRM_MFA_CODE_SUCCESS,
  CONFIRM_MFA_CODE_FAILURE,
} from 'redux/consts/mfa';

export function sendMFACode() {
  return {
    type: SEND_MFA_CODE_REQUEST,
    [WAIT_FOR_ACTION]: SEND_MFA_CODE_SUCCESS,
    [ERROR_ACTION]: SEND_MFA_CODE_FAILURE,
  };
}

export function onSendMFACodeSuccess({ payload }) {
  return {
    type: SEND_MFA_CODE_SUCCESS,
    payload,
  };
}

export function onSendMFACodeFailure({ error }) {
  return {
    type: SEND_MFA_CODE_FAILURE,
    error,
  };
}

export function confirmMFACode({ code }) {
  return {
    type: CONFIRM_MFA_CODE_REQUEST,
    code,
    [WAIT_FOR_ACTION]: CONFIRM_MFA_CODE_SUCCESS,
    [ERROR_ACTION]: CONFIRM_MFA_CODE_FAILURE,
  };
}

export function onConfirmMFACodeSuccess({ payload }) {
  return {
    type: CONFIRM_MFA_CODE_SUCCESS,
    payload,
  };
}

export function onConfirmMFACodeFailure(error) {
  return {
    type: CONFIRM_MFA_CODE_FAILURE,
    error,
  };
}

export function backLogin(params) {
  return {
    type: BACK_LOGIN,
    ...(params ? params : {}),
  };
}
