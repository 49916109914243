import { useDispatch } from 'react-redux';

import { showPopup } from 'redux/actions/popup';

export default ({ popup }) => {
  const dispatch = useDispatch();
  return (props) => {
    dispatch(showPopup(popup, props));
  };
};
