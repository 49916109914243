import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import disableScroll from 'disable-scroll';
import cn from 'classnames';

import styles from './styles.module.scss';

const disableWindowScroll = () => {
  const x = window.scrollX;
  const y = window.scrollY;
  window.onscroll = function() {
    window.scrollTo(x, y);
  };
};

const enableWindowScroll = () => {
  window.onscroll = function() {};
};

class PageLayout extends React.PureComponent {
  componentDidMount = () => {
    window.documentLoading = false;
    this.scrollHandler();
  };
  componentDidUpdate = () => {
    window.documentLoading = false;
    this.scrollHandler();
  };

  layout = React.createRef();

  scrollHandler() {
    const { pageOverflowShown } = this.props;
    const element = ReactDOM.findDOMNode(this.layout.current);
    if (pageOverflowShown) {
      disableScroll.on(element, { disableWheel: false, disableKeys: false });
      disableWindowScroll();
    } else {
      disableScroll.off(element);
      enableWindowScroll();
    }
  }

  render() {
    const { children, dialogShown } = this.props;
    return (
      <div
        ref={this.layout}
        className={cn(styles.wrapper, dialogShown && styles.blurred)}
      >
        {children}
      </div>
    );
  }
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,

  /** container */
  pageOverflowShown: PropTypes.bool.isRequired,
  dialogShown: PropTypes.bool.isRequired,
};

export default PageLayout;
