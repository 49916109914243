import React from 'react';
import PropTypes from 'prop-types';

import NameIcon from 'components/NameIcon';

const colorCfg = {
  A: '#C52827',
  B: '#EB407A',
  C: '#AC1457',
  D: '#AA47BC',
  E: '#6A1B9A',
  F: '#4528A0',
  G: '#00636D',
  H: '#F89782',
  I: '#00786A',
  J: '#4CB6AC',
  K: '#398D3F',
  L: '#7DB343',
  M: '#807615',
  N: '#C0CA33',
  O: '#6E4C40',
  P: '#303E9F',
  Q: '#1976D3',
  R: '#04A9F5',
  S: '#01BCD5',
  T: '#FAC02E',
  U: '#FF8F00',
  V: '#EE6D00',
  W: '#BF360C',
  X: '#E64A19',
  Y: '#9E9E9E',
  Z: '#596C7A',
};

function NameColorIcon({ text, ...rest }) {
  const color = (text && colorCfg[text[0].toUpperCase()]) || undefined;
  const style = color
    ? { backgroundColor: color, backgroundImage: 'none' }
    : {};
  return <NameIcon style={style} text={text} {...rest} />;
}

NameColorIcon.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

export default NameColorIcon;
