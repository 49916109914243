import React from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { connect } from 'react-redux';

import { logout } from 'redux/actions/auth';

const TIMEOUT = 1000 * 60 * 60; // Time when user becomes inactive -> 60 minutes.

class InactivityTracker extends React.PureComponent {
  constructor(props) {
    super(props);
    this.idleTimer = null;
  }
  render() {
    const { onIdle } = this.props;
    return (
      <IdleTimer
        ref={(ref) => {
          this.idleTimer = ref;
        }}
        onIdle={onIdle}
        timeout={TIMEOUT}
      />
    );
  }
}

InactivityTracker.propTypes = {
  onIdle: PropTypes.func.isRequired,
};

const connectStore = connect(
  null,
  { onIdle: logout }
);

export default connectStore(InactivityTracker);
