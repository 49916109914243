import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';

import {
  CANCEL_PENDING_PROVIDERS_REQUEST,
  CANCEL_PENDING_PROVIDERS_SUCCESS,
  CANCEL_PENDING_PROVIDERS_FAILURE,
  CONFIRM_INFORMATION_REQUEST,
  CONFIRM_INFORMATION_SUCCESS,
  CONFIRM_INFORMATION_FAILURE,
} from 'redux/consts/providersRequests';

export function cancelPendingProvidersRequest(token) {
  return {
    type: CANCEL_PENDING_PROVIDERS_REQUEST,
    token,
    [WAIT_FOR_ACTION]: CANCEL_PENDING_PROVIDERS_SUCCESS,
    [ERROR_ACTION]: CANCEL_PENDING_PROVIDERS_FAILURE,
  };
}

export function onCancelPendingProvidersSuccess({ payload, token }) {
  return {
    type: CANCEL_PENDING_PROVIDERS_SUCCESS,
    payload,
    token,
  };
}

export function onCancelPendingProvidersFailure({ error, token }) {
  return {
    type: CANCEL_PENDING_PROVIDERS_FAILURE,
    error,
    token,
  };
}

export function confirmInformationRequest(form, token, providerId) {
  return {
    type: CONFIRM_INFORMATION_REQUEST,
    form,
    token,
    providerId,
    [WAIT_FOR_ACTION]: CONFIRM_INFORMATION_SUCCESS,
    [ERROR_ACTION]: CONFIRM_INFORMATION_FAILURE,
  };
}

export function onConfirmInformationSuccess({ payload, token }) {
  return {
    type: CONFIRM_INFORMATION_SUCCESS,
    payload,
    token,
  };
}

export function onConfirmInformationFailiure({ error }) {
  return {
    type: CONFIRM_INFORMATION_FAILURE,
    error,
  };
}
