import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { refreshToken } from 'redux/actions/auth';
import InactivityTracker from 'config/InactivityTracker';
import {
  isAuthenticatedSelector,
  tokenTimeSelector,
  tokenSelector,
} from 'redux/selectors/auth';

const getExpTime = (token, timeStamp) => {
  if (!token) return;
  return timeStamp
    ? Math.trunc((token.expires_in - (Date.now() - timeStamp) / 1000) * 1000)
    : token.expires_in * 1000;
};

const SecureRoute = (props) => {
  const isLogged = useSelector(isAuthenticatedSelector, shallowEqual);
  const token = useSelector(tokenSelector);
  const tokenTime = useSelector(tokenTimeSelector);
  const time = getExpTime(token, tokenTime);

  const dispatch = useDispatch();
  const refreshTokenIniciator = () =>
    dispatch(refreshToken()).then(initNewRefresh);

  const doRefresh = (fn, time) => setTimeout(fn, time);

  useEffect(() => {
    doRefresh(refreshTokenIniciator, time);
  }, []); // eslint-disable-line

  const initNewRefresh = (refreshedToken) => {
    const newTime = getExpTime(refreshedToken);
    doRefresh(refreshTokenIniciator, newTime);
  };

  if (!isLogged) {
    return <Redirect to="/login" />;
  }
  return (
    <>
      <InactivityTracker />
      <Route {...props} />
    </>
  );
};

SecureRoute.propTypes = {
  isLogged: PropTypes.bool,
};

export default SecureRoute;
