import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import {
  patientProvidersSelector,
  currentPatientProviderSelector,
} from 'redux/selectors/patientProviders';
import { providersSelector } from 'redux/selectors/providerInfo';
import { selectPatientProviderId } from 'redux/actions/patientProviders';

import PatientProviderItem from './PatientProviderItem';
import PatientProvidersDropdown from './PatientProvidersDropdown';

function PatientProvidersSectionContainer(props) {
  const patientProviders = useSelector(patientProvidersSelector, shallowEqual);
  const providers = useSelector(providersSelector, shallowEqual);
  const patientProvidersWithName = patientProviders.map((p) => ({
    ...p,
    providerName: providers[p.providerId].name,
  }));
  const selectedPatientProvider = useSelector(
    currentPatientProviderSelector,
    shallowEqual
  );

  const dispatch = useDispatch();
  const onSelectPatientProviderId = (...args) =>
    dispatch(selectPatientProviderId(...args));

  if (patientProviders.length === 0) {
    return null;
  }

  if (patientProviders.length === 1) {
    return (
      <PatientProviderItem
        patientProvider={selectedPatientProvider}
        {...props}
      />
    );
  }

  return (
    <PatientProvidersDropdown
      patientProviders={patientProvidersWithName}
      selectedPatientProvider={selectedPatientProvider}
      onSelectPatientProviderId={onSelectPatientProviderId}
      {...props}
    />
  );
}

export default React.memo(PatientProvidersSectionContainer);
