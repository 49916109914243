import { LOCATION_CHANGE } from 'connected-react-router';

import { SHOW_POPUP, HIDE_POPUP } from 'redux/consts/popup';

const activePopupIndex = 0;

const initialState = {
  activePopupIndex,
  [activePopupIndex]: { route: null, props: null },
};

const popupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_POPUP: {
      const { route, props } = action;
      return {
        ...state,
        activePopupIndex: state.activePopupIndex + 1,
        [state.activePopupIndex + 1]: { route, props },
      };
    }

    case LOCATION_CHANGE: {
      return initialState;
    }

    case HIDE_POPUP: {
      const { closeAll } = action;
      if (closeAll) return initialState;
      let { activePopupIndex, ...popups } = state;
      const filteredPopups = Object.fromEntries(
        Object.entries(popups).filter(
          ([idx]) => Number(idx) !== activePopupIndex
        )
      );
      return { activePopupIndex: activePopupIndex - 1, ...filteredPopups };
    }

    default:
      return state;
  }
};

export default popupReducer;
