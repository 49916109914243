import moment from 'moment';

import { takeLatest, take, put } from 'redux-saga/effects';
import {
  CANCEL_PENDING_PROVIDERS_REQUEST,
  CONFIRM_INFORMATION_REQUEST,
} from 'redux/consts/providersRequests';
import {
  onCancelPendingProvidersSuccess,
  onCancelPendingProvidersFailure,
  onConfirmInformationSuccess,
  onConfirmInformationFailiure,
} from 'redux/actions/providersRequests';
import { showDialog } from 'redux/actions/dialog';
import { AGREEMENTS_DIALOG } from 'config/Dialog/consts';
import { SIGN_AGREEMENT_SUCCESS } from 'redux/consts/agreements';

import { apiSecureRequest, combineSagas } from './utils';
import { checkProviderAgreementWorker } from './agreements';

function* cancelProvidersRequestWorker({ token }) {
  const url = `/invitation/cancel?token=${token}`;
  const options = {
    method: 'POST',
  };
  try {
    const payload = yield apiSecureRequest(url, options);
    yield put(onCancelPendingProvidersSuccess({ payload, token }));
    return payload;
  } catch (error) {
    yield put(onCancelPendingProvidersFailure({ error, token }));
    return null;
  }
}

function* cancelProvidersRequestSaga() {
  yield takeLatest(
    CANCEL_PENDING_PROVIDERS_REQUEST,
    cancelProvidersRequestWorker
  );
}

function* confirmProvidersRequestWorker({ form, token, providerId }) {
  const { dob, zip, relationType, clinicianName } = form;
  const url = `/invitation/confirm`;
  const options = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      token,
      dob: moment(dob).format('YYYY-MM-DD'),
      zip,
      relationType,
      clinicianName,
    }),
  };
  try {
    const providerAgreement = yield checkProviderAgreementWorker({
      providerId,
    });
    if (providerAgreement) {
      yield put(
        showDialog(AGREEMENTS_DIALOG, {
          title: `Terms of Use`,
          hideTabs: true,
          agreements: [providerAgreement],
          hasClose: true,
        })
      );
      yield take(SIGN_AGREEMENT_SUCCESS);
    }
    const payload = yield apiSecureRequest(url, options);
    yield put(onConfirmInformationSuccess({ payload, token }));
    return payload;
  } catch (error) {
    yield put(onConfirmInformationFailiure({ error }));
    return null;
  }
}

function* confirmProvidersRequestSaga() {
  yield takeLatest(CONFIRM_INFORMATION_REQUEST, confirmProvidersRequestWorker);
}

export default combineSagas([
  cancelProvidersRequestSaga,
  confirmProvidersRequestSaga,
]);
