import { takeLatest, put, call } from 'redux-saga/effects';
import { UPDATE_USER_DATA_REQUEST } from 'redux/consts/loggedUser';
import { REFRESH_TOKEN_REQUEST } from 'redux/consts/auth';
import { onUpdateUserDataSuccess } from 'redux/actions/loggedUser';

import refreshToken from '../sagas/utils/api/refreshToken';

import { apiSecureRequest, combineSagas } from './utils';

function* updateUserDataWorker({
  firstName,
  lastName,
  phone,
  currentPassword,
}) {
  const url = `/user-profile/account`;
  const options = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      firstName,
      lastName,
      phone,
      currentPassword,
    }),
  };
  try {
    yield apiSecureRequest(url, options);
    const payload = { firstName, lastName, phone };
    yield put(onUpdateUserDataSuccess({ payload }));
    return payload;
  } catch (error) {
    return false;
  }
}

function* refreshTokenWorker() {
  const refreshedToken = yield call(refreshToken);
  return refreshedToken;
}

function* updateUserDataSaga() {
  yield takeLatest(UPDATE_USER_DATA_REQUEST, updateUserDataWorker);
}

function* refreshTokenSaga() {
  yield takeLatest(REFRESH_TOKEN_REQUEST, refreshTokenWorker);
}

export default combineSagas([updateUserDataSaga, refreshTokenSaga]);
