import { SHOW_DIALOG, HIDE_DIALOG } from 'redux/consts/dialog';

export function showDialog(route, props) {
  return {
    type: SHOW_DIALOG,
    route,
    props,
  };
}

export function hideDialog() {
  return {
    type: HIDE_DIALOG,
  };
}
