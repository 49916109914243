import { INITIAL_DATA_SUCCESS } from 'redux/consts/initialData';
import { extractPatientProviders } from 'redux/reducers/utils';

const initialState = [];

/**
 *
 * @param {*} state
 * @param {*} action
 * @returns {{[patientProviderId:string] : patientProvider[]}
 */
const patientProvidersReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_DATA_SUCCESS: {
      const { payload } = action;
      const patientProviders = extractPatientProviders(payload);
      return patientProviders.reduce(
        (acc, pp) => ({ ...acc, [pp.patientProviderId]: pp }),
        {}
      );
    }

    default:
      return state;
  }
};

export default patientProvidersReducer;
