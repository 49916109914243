import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

class Popup extends React.PureComponent {
  render() {
    const { items, className, ...rest } = this.props;
    return (
      <div className={cn(styles.wrapper, className)} {...rest}>
        {items.map(({ key, title, checked, separator, ...rest }) => {
          const Component = rest.to ? Link : React.createElement('button').type;
          return (
            <Component
              key={key}
              className={cn(
                styles.item,
                separator && styles.separatedItem,
                styles[key] && `${styles[key]}`
              )}
              {...rest}
            >
              <span className={cn(styles.title, styles.cutInfo)}>{title}</span>
              {checked && <span className={styles.checkmark} />}
            </Component>
          );
        })}
      </div>
    );
  }
}

Popup.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      to: PropTypes.oneOfType([Link.propTypes.to]),
      onClick: PropTypes.func,
      checked: PropTypes.bool,
    })
  ).isRequired,
  className: PropTypes.string,
};

export default Popup;
