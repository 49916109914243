import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useOnclickOutside from 'react-cool-onclickoutside';

import Popup from 'components/Popup';

import styles from './styles.module.scss';

const QuickLinks = ({ items }) => {
  const [popupShown, setPopupShown] = useState(false);

  const toggleMenu = () => setPopupShown(!popupShown);
  const hideMenu = () => setPopupShown(false);

  const ref = useOnclickOutside(() => hideMenu());

  return (
    <div className={styles.wrapper}>
      {items.map(({ icon, name, items, ...rest }) => {
        const Component = rest.to ? Link : React.createElement('button').type;
        const isButton = Component === 'button';
        return (
          <Component
            ref={ref}
            key={name}
            className={styles.container}
            onClick={isButton ? toggleMenu : () => {}}
            {...rest}
          >
            <img src={icon} alt={name} />
            {items?.length > 0 && popupShown && (
              <Popup
                className={styles.popup}
                items={items}
                onClick={hideMenu}
              />
            )}
          </Component>
        );
      })}
    </div>
  );
};

QuickLinks.propTypes = {
  items: PropTypes.array.isRequired,
};

export default QuickLinks;
