import React from 'react';
import PropTypes from 'prop-types';
import { StickyContainer, Sticky } from 'react-sticky';

import CalendarCard from '../CalendarCard';

import styles from './styles.module.scss';

const AsideCalendarPanel = ({
  isMobile,
  sortedDocs,
  selectedDays,
  showDay,
  schedules,
  onScheduleChange,
}) => {
  return (
    <>
      {isMobile ? (
        <aside className={styles.rightBlock}>
          <CalendarCard
            isMobile={true}
            schedules={schedules}
            onScheduleChange={onScheduleChange}
            sortedDocs={sortedDocs}
            selectedDays={selectedDays}
            showDay={showDay}
          />
        </aside>
      ) : (
        <StickyContainer className={styles.rightBlock}>
          <Sticky>
            {({ style, distanceFromTop }) => (
              <aside
                style={{
                  ...style,
                  ...(distanceFromTop <= 100
                    ? {
                        transform: `translateY(${
                          distanceFromTop > 0
                            ? 100 - distanceFromTop
                            : style.top < 0
                            ? style.top * 1.1
                            : 100
                        }px)`,
                      }
                    : {}),
                }}
              >
                <CalendarCard
                  isMobile={isMobile}
                  schedules={schedules}
                  onScheduleChange={onScheduleChange}
                  sortedDocs={sortedDocs}
                  selectedDays={selectedDays}
                  showDay={showDay}
                />
              </aside>
            )}
          </Sticky>
        </StickyContainer>
      )}
    </>
  );
};

AsideCalendarPanel.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  schedules: PropTypes.arrayOf(PropTypes.object),
  onScheduleChange: PropTypes.func.isRequired,
  sortedDocs: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showDay: PropTypes.func.isRequired,
  selectedDays: PropTypes.object,
};

export default React.memo(AsideCalendarPanel);
