import { HTTP_REQUEST_SUCCESS, HTTP_REQUEST_ERROR } from 'redux/consts/global';

export function httpRequestSuccess({ url, options, payload }) {
  return {
    type: HTTP_REQUEST_SUCCESS,
    url,
    options,
    payload,
  };
}

export function httpRequestError({ url, options, error }) {
  return {
    type: HTTP_REQUEST_ERROR,
    url,
    options,
    error,
  };
}
