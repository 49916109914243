import { INITIAL_DATA_SUCCESS } from 'redux/consts/initialData';
import { UPDATE_USER_DATA_SUCCESS, UPDATE_HAS_PAYMENT_METHOD } from 'redux/consts/loggedUser';

const initialState = null;

const loggedUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIAL_DATA_SUCCESS:
      return {...action.payload.user, hasPaymentMethod: action.payload.hasPaymentMethod };

    case UPDATE_USER_DATA_SUCCESS: {
      return { ...state, ...action.payload };
    }

    case UPDATE_HAS_PAYMENT_METHOD: {
      return { ...state, hasPaymentMethod: action.hasPaymentMethod };
    }

    default:
      return state;
  }
};

export default loggedUserReducer;
