import { useEffect } from 'react';

const useOnClickOutside = (ref, handler, forceClose) => {
  useEffect(() => {
    if (forceClose) {
      return handler();
    }
    const listener = (event) => {
      const clickedOnSameTarget =
        !ref.current || ref.current.contains(event.target);
      if (clickedOnSameTarget) return;
      handler(event);
    };
    document.addEventListener('click', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('click', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, forceClose]);
};

export default useOnClickOutside;
