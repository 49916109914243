import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './styles.module.scss';

const PopupContent = ({ clinicianFirstName, clinicianLastName, clinicianDisciplineCode }) => {
  return (
    <div className={styles.contentWrapper}>
      <div className={cn(styles.blockWithImg, styles.contact)}>
        <div>{clinicianFirstName} {clinicianLastName} {clinicianDisciplineCode}</div>
      </div>
    </div>
  );
};

PopupContent.propTypes = {
  clinicianFirstName: PropTypes.string.isRequired,
  clinicianLastName: PropTypes.string.isRequired,
  clinicianDisciplineCode: PropTypes.string.isRequired,
};

export default PopupContent;
