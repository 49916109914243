import { hideDialog } from 'redux/actions/dialog';
import { loadStripe } from '@stripe/stripe-js';
import { BASE_URL, STRIPE_KEY, SYSTEM_ERROR_MSG, PAYMENT_COMPLETED_EVENT } from 'consts';
import { publish } from './event.js';
import {
  loadPaymentMethod,
  retrievePaymentCompleted,
  createPaymentIntent,
} from 'redux/actions/billsPayment';

import { showSuccessToast, showErrorToast } from 'components/Toaster';

const MAX_ATTEMPTS = 10;

export const initStripe = (accountId) => {
    const options = accountId ? { stripeAccount: accountId } : undefined;
    return loadStripe(STRIPE_KEY, options);
};

export const waitPaymentMethodStatus = (dispatch, params, onRefresh) => {
  checkPaymentMethodRecursively(dispatch, params, onRefresh);
};

export const waitPayment = (dispatch, params, onRefresh) => {
  checkPaymentRecursively(dispatch, params, onRefresh);
};

export const createAndConfirmPaymentIntent = (dispatch, params, onRefresh) => {
  dispatch(createPaymentIntent(params))
    .then((data) => {
      const { clientSecret, accountId, message, status, details } = data; // eslint-disable-line

      initStripe(accountId)
        .then((stripe) => {
          const { patientId, invoiceId } = params;
          stripe.confirmPayment({
            clientSecret,
            confirmParams: {
              return_url: `${BASE_URL}/bills?invoiceId=${invoiceId}&patientId=${patientId}`,
            }
          })
          .then(({ error }) => {
            handleStripeResponseError(error, dispatch, onRefresh);
          })
          .catch(({ error }) => {
            handleStripeResponseError(error, dispatch, onRefresh);
          })
        })
        .catch((error) => {
          showDefaultError(dispatch);
        });
    })
    .catch((error) => {
      showDefaultError(dispatch);
    });
};

const handlePaymentMethodStatusResponse = (status, dispatch, params, onRefresh) => {
  switch (status) {
    case 'SUCCEEDED': {
      continuePayment(dispatch, params, onRefresh);
      break;
    }
    case 'REQUIRES_ACTION': {
      onRefresh();
      clearCurrentUrlQuery();
      publish(PAYMENT_COMPLETED_EVENT);
      showSuccessToast({ content: 'New payment method is pending your verification.' });
      break;
    }
    default: {
      window.location = `${BASE_URL}/bills`;
    }
  }
};

const handleWaitPaymentResponse = (status, onRefresh) => {
  switch (status) {
    case 'SUCCEEDED': {
      onRefresh();
      clearCurrentUrlQuery();
      publish(PAYMENT_COMPLETED_EVENT);
      showSuccessToast({ content: 'Transaction was approved.' });
      break;
    }
    case 'PROCESSING': {
      onRefresh();
      clearCurrentUrlQuery();
      publish(PAYMENT_COMPLETED_EVENT);
      showSuccessToast({ content:'Transaction is processing' });
      break;
    }
    default: {
      window.location = `${BASE_URL}/bills`;
    }
  }
};

const showDefaultError = (dispatch) => {
  dispatch(hideDialog());
  clearCurrentUrlQuery();
  publish(PAYMENT_COMPLETED_EVENT);
  showErrorToast({ content: SYSTEM_ERROR_MSG });
};

const handleStripeResponseError = (error, dispatch, onRefresh) => {
  if (error) {
    if (['card_error', 'validation_error'].includes(error.type)) {
      dispatch(hideDialog());
      clearCurrentUrlQuery();
      publish(PAYMENT_COMPLETED_EVENT);
      setTimeout(onRefresh, 1000);
      showErrorToast({ content: error.message });
    } else {
      showDefaultError(dispatch);
    }
  }
};

const clearCurrentUrlQuery = () => {
    let url = window.location.href.split('?')[0];
    window.history.pushState({}, document.title, url);
    window.history.replaceState({}, document.title, url);
};

const continuePayment = (dispatch, params, onRefresh) => {
  const { invoiceId, setupIntentId } = params;
  if (!!invoiceId && !!setupIntentId) {
    return createAndConfirmPaymentIntent(dispatch, params, onRefresh);
  }
};

const checkPaymentMethodRecursively = (dispatch, params, onRefresh, attemptCount = 0) => {
    if(attemptCount > MAX_ATTEMPTS) {
      showDefaultError(dispatch);
    } else {
      dispatch(loadPaymentMethod())
        .then(({ status }) => {
          if (['SUCCEEDED', 'REQUIRES_ACTION'].includes(status)) {
            handlePaymentMethodStatusResponse(status, dispatch, params, onRefresh);
          } else {
            setTimeout(() => checkPaymentMethodRecursively(dispatch, params, onRefresh, ++attemptCount), 1000);
          }
        }).catch((error) => {
          showDefaultError(dispatch);
        })
    }
};

const checkPaymentRecursively = (dispatch, params, onRefresh, attemptCount = 0) => {
    if(attemptCount > MAX_ATTEMPTS) {
      showDefaultError(dispatch);
    } else {
      dispatch(retrievePaymentCompleted(params))
        .then(({ status }) => {
          if (['PROCESSING', 'SUCCEEDED', 'CANCELED', 'PAYMENT_FAILED', 'INTERNAL_ERROR'].includes(status)) {
            handleWaitPaymentResponse(status, onRefresh);
          } else {
            setTimeout(() => checkPaymentRecursively(dispatch, params, onRefresh, ++attemptCount), 1000);
          }
        }).catch((error) => {
          showDefaultError(dispatch);
        })
    }
};


