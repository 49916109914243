import React from 'react';
import { Helmet } from 'react-helmet';

import MainLayout from 'layouts/MainLayout';

import BillsContainer from './BillsContainer';
import styles from './styles.module.scss';

const headerProps = { includePatientsDropdown: false };

const BillsPage = () => {
  return (
    <>
      <Helmet>
        <title>{`therapyBOSS | Provider bills`}</title>
      </Helmet>
      <MainLayout headerProps={headerProps}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <div className={styles.title}>Provider bills</div>
            <BillsContainer />
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default React.memo(BillsPage);
