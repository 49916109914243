import { takeLatest, put } from 'redux-saga/effects';

import { INITIAL_DATA_REQUEST } from 'redux/consts/initialData';
import { onInitialDataSuccess } from 'redux/actions/initialData';
import { createApiSecureRequest, combineSagas } from 'redux/sagas/utils';

export function* getInitialDataWorker({ authToken }) {
  const apiSecureRequest = createApiSecureRequest({
    token: authToken,
    tryRefresh: true,
  });
  const url = `/user-profile/info`;
  const options = { method: 'GET' };
  try {
    const payload = yield apiSecureRequest(url, options);
    yield put(onInitialDataSuccess({ payload }));
    return payload;
  } catch (error) {
    return null;
  }
}

function* getInitialDataSaga() {
  yield takeLatest(INITIAL_DATA_REQUEST, getInitialDataWorker);
}

export default combineSagas([getInitialDataSaga]);
