import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

function NoAvailableData({ title, note, img, alt, height = 80 }) {
  return (
    <div className={styles.noDataWrapper} style={{ height: `${height}vh` }}>
      <div className={styles.dataWrapper}>
        <img src={img} className={styles.img} alt={alt} />
        <div className={styles.title}>{title}</div>
        <div className={styles.note}>{note}</div>
      </div>
    </div>
  );
}

NoAvailableData.propTypes = {
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  note: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  alt: PropTypes.string.isRequired,
  height: PropTypes.number,
};

export default React.memo(NoAvailableData);
