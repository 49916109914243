import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';

import {
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_FAILURE,
  GET_SIGNUP_DATA_REQUEST,
  GET_SIGNUP_DATA_SUCCESS,
} from 'redux/consts/signup';

export function signup({ authToken, token, form, invProviderId }) {
  return {
    type: SIGNUP_REQUEST,
    authToken,
    token,
    form,
    invProviderId,
    [WAIT_FOR_ACTION]: SIGNUP_SUCCESS,
    [ERROR_ACTION]: SIGNUP_FAILURE,
  };
}

export function onSignupSuccess({ payload }) {
  return {
    type: SIGNUP_SUCCESS,
    payload,
  };
}

export function onSignupFailure({ error }) {
  return {
    type: SIGNUP_FAILURE,
    error,
  };
}

export function getSignupData(data) {
  return {
    type: GET_SIGNUP_DATA_REQUEST,
    data,
    [WAIT_FOR_ACTION]: GET_SIGNUP_DATA_SUCCESS,
  };
}

export function onGetSignupDataSuccess({ payload }) {
  return {
    type: GET_SIGNUP_DATA_SUCCESS,
    payload,
  };
}
