import { put } from 'redux-saga/effects';
import { onAuthSuccess, onAuthFailure } from 'redux/actions/auth';

import { getInitialDataWorker } from './initialData';

export default function* completeAuth({ authToken }) {
  try {
    yield getInitialDataWorker({ authToken });
    yield put(onAuthSuccess({ authToken }));
    return authToken;
  } catch (error) {
    yield put(onAuthFailure({ error }));
    return null;
  }
}
