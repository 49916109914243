import { parse } from 'query-string';
import { connect } from 'react-redux';

import { IS_RECAPTCHA_OPTIONAL } from 'consts';
import { onRecoveryPasswordRequest } from 'redux/actions/resetPassword';
import { backLogin } from 'redux/actions/mfa';

import ResetPasswordPage from './ResetPasswordPage';

const mapStateToProps = ({ router }) => {
  const { email, recoveryToken, captchaDisabled } = parse(
    router.location.search
  );
  const disabled =
    captchaDisabled && JSON.parse(captchaDisabled) ? true : false;
  return {
    email,
    recoveryToken,
    isDisabled: Boolean(disabled && IS_RECAPTCHA_OPTIONAL),
  };
};

const mapDispatchToProps = {
  onRecoveryPassword: onRecoveryPasswordRequest,
  backLogin,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordPage);
