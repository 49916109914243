import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import Navbar from 'react-day-picker/build/Navbar';
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';

import Day from './Day';
import styles from './styles.module.scss';

const dayOfWeekEnum = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
};

const localeUtils = {
  ...MomentLocaleUtils,
  formatMonthTitle: (d) => moment(d).format('MMMM, YYYY'),
  formatWeekdayShort: (i) => dayOfWeekEnum[i],
};

function Calendar({ docs, onDayClick, selectedDays, ...props }) {
  const dates = docs.map((d) => ({
    date: new Date(d.noteDate),
    viewed: d.viewed,
  }));
  const monthes = dates.reduce((acc, item) => {
    if (acc.some((d) => moment(d).isSame(item.date, 'month'))) {
      return acc;
    }
    return [
      ...acc,
      moment(item.date)
        .startOf('month')
        .toDate(),
    ];
  }, []);

  const isDateDisabled = (day) =>
    dates.every((d) => !moment(d.date).isSame(day, 'day'));

  const initialMonth = moment(selectedDays || new Date())
    .startOf('month')
    .toDate();

  const toMonth = monthes.length > 0 ? monthes[0] : new Date();
  const fromMonth =
    monthes.length > 0 ? monthes[monthes.length - 1] : new Date();

  const [month, setMonth] = useState(initialMonth);

  const onPreviousClick = () => {
    const currentIndex = monthes.findIndex(
      (m) => m.getTime() === month.getTime()
    );
    const nextMonth = monthes[Math.min(currentIndex + 1, monthes.length - 1)];
    setMonth(nextMonth);
  };

  const onNextClick = () => {
    const currentIndex = monthes.findIndex(
      (m) => m.getTime() === month.getTime()
    );
    const prevMonth = monthes[Math.max(currentIndex - 1, 0)];
    setMonth(prevMonth);
  };

  const customOnDayClick = (day) => {
    if (!isDateDisabled(day)) {
      return onDayClick(day);
    }
  };

  return (
    <div className={styles.mainWrapper}>
      <DayPicker
        classNames={styles}
        renderDay={(day, modifiers) => (
          <Day dates={dates} day={day} modifiers={modifiers} />
        )}
        navbarElement={(props) => (
          <Navbar
            {...props}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
          />
        )}
        localeUtils={localeUtils}
        fromMonth={fromMonth}
        toMonth={toMonth}
        month={month}
        disabledDays={isDateDisabled}
        onDayClick={customOnDayClick}
        selectedDays={selectedDays}
        {...props}
      />
      <ul className={styles.legends}>
        <li className={styles.viewedLegend}>Notes viewed</li>
        <li>Not viewed yet</li>
      </ul>
    </div>
  );
}

Calendar.propTypes = {
  docs: PropTypes.arrayOf(
    PropTypes.shape({
      noteDate: PropTypes.string.isRequired,
      viewed: PropTypes.bool,
    })
  ).isRequired,
  selectedDays: PropTypes.object,
  onDayClick: PropTypes.func.isRequired,
};

export default React.memo(Calendar);
