import React from 'react';
import PropTypes from 'prop-types';

import TextField from 'components/TextField';

import styles from '../styles.module.scss';

class Step1 extends React.PureComponent {
  render() {
    const { form, errors, email, onInputChange, disabled } = this.props;
    const { firstName, lastName, phone } = form;
    return (
      <>
        <TextField
          name="firstName"
          value={firstName}
          onChange={onInputChange('firstName')}
          error={errors['firstName']}
          disabled={disabled}
          placeholder="Enter here"
          type="text"
          label="First name"
          className={styles.input}
          maxLength={25}
        />
        <TextField
          name="lastName"
          value={lastName}
          onChange={onInputChange('lastName')}
          error={errors['lastName']}
          disabled={disabled}
          placeholder="Enter here"
          type="text"
          autoComplete="on"
          label="Last name"
          className={styles.input}
          maxLength={25}
        />
        <TextField
          name="email"
          value={email || ''}
          readOnly={true}
          disabled={disabled}
          placeholder="Enter here"
          type="email"
          autoComplete="on"
          label="Email"
          className={styles.input}
        />
        <TextField
          name="phone"
          value={phone}
          onChange={onInputChange('phone')}
          error={errors['phone']}
          disabled={disabled}
          placeholder="999-999-9999"
          type="text"
          autoComplete="on"
          label="Mobile phone"
          mask="999-999-9999"
          className={styles.input}
        />
      </>
    );
  }
}

Step1.propTypes = {
  disabled: PropTypes.bool,
  email: PropTypes.string,
  form: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
  }).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onInputChange: PropTypes.func.isRequired,
};

export default Step1;
