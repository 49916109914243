import { WAIT_FOR_ACTION, ERROR_ACTION } from 'redux-wait-for-action';

import { HTTP_REQUEST_ERROR } from 'redux/consts/global';

export const patchActionWithErrorHandler = (action) => {
  if (action[WAIT_FOR_ACTION] && !action[ERROR_ACTION]) {
    const newAction = { ...action, [ERROR_ACTION]: HTTP_REQUEST_ERROR };
    return newAction;
  }
  return action;
};

export default () => (next) => (action) =>
  next(patchActionWithErrorHandler(action));
