import React from 'react';
import PropTypes from 'prop-types';

import PopupContent from './PopupContent';

import styles from './styles.module.scss';

const ClinicianInfoPopup = (props) => {
  return (
    <div className={styles.wrapper}>
      <PopupContent {...props} />
    </div>
  );
};

ClinicianInfoPopup.propTypes = {
  clinician: PropTypes.shape().isRequired,
};

export default React.memo(ClinicianInfoPopup);
