import React from 'react';
import PropTypes from 'prop-types';
import disableScroll from 'disable-scroll';

import styles from './styles.module.scss';

class PopupLayout extends React.PureComponent {
  componentDidMount() {
    this.targetElement = this.targetRef.current;
    window.addEventListener('resize', this.documentHeight);
    this.documentHeight();
    disableScroll.on(this.targetElement, {
      disableWheel: false,
      disableKeys: false,
    });
  }
  componentWillUnmount() {
    disableScroll.off(this.targetElement);
    window.removeEventListener('resize', this.documentHeight);
  }
  targetRef = React.createRef();
  documentHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
  };

  render() {
    const { children, onOutsideClick } = this.props;
    return (
      <div ref={this.targetRef} className={styles.container}>
        <div className={styles.shade} onClick={onOutsideClick} />
        {children}
      </div>
    );
  }
}

PopupLayout.propTypes = {
  children: PropTypes.node.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
};

export default PopupLayout;
