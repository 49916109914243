import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { tokenSelector } from 'redux/selectors/auth';
import { pendingRequestsSelector } from 'redux/selectors/patientInvitations';
import { loadInitialData } from 'redux/actions/initialData';

import PendingRequestBlock from './PendingRequestBlock';

function PendingRequestBlockContainer(props) {
  const dispatch = useDispatch();
  const authToken = useSelector(tokenSelector);

  useEffect(() => {
    dispatch(loadInitialData(authToken));
  }, []); // eslint-disable-line
  const pendingRequests = useSelector(pendingRequestsSelector, shallowEqual);

  return <PendingRequestBlock {...props} pendingRequests={pendingRequests} />;
}

export default React.memo(PendingRequestBlockContainer);
