import React from 'react';
import PropTypes from 'prop-types';

import PatientProviderPreview from 'components/PatientProviderPreview';

function PatientProviderItem(props) {
  const { patientProvider, ...rest } = props;
  return (
    <PatientProviderPreview
      providerId={patientProvider.providerId}
      patient={patientProvider.patient}
      {...rest}
    />
  );
}

PatientProviderItem.propTypes = {
  patientProvider: PropTypes.shape({}).isRequired,
};

export default React.memo(PatientProviderItem);
