import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';

import { useMediaQuery } from 'react-responsive';

import visaImg from './img/visa64.png';
import masterCardImg from './img/mastercard64.png';
import amExImg from './img/amex64.png';
import discoverImg from './img/discover64.png';
import bankTransfer from './img/banktransfer64.png';
import unionpayImg from './img/unionpay.svg';
import jcbImg from './img/jcb.svg';
import dinersImg from './img/diners.svg';

import styles from './styles.module.scss';

const cardsCfg = {
  amex: amExImg,
  discover: discoverImg,
  mastercard: masterCardImg,
  visa: visaImg,
  unionpay: unionpayImg,
  jcb: jcbImg,
  diners: dinersImg,
  //   cartes_bancaires: null,
};

const PaymentMethod = ({ payment, className, onDelete }) => {
  const { type, status, bankAccount, card } = payment;
  const isMobile = useMediaQuery({ maxWidth: 490 });
  return (
    <div className={cn(styles.wrapper, className && className)}>
      {isMobile && status === 'REQUIRES_ACTION' && (
          <div className={styles.status} >Pending your confirmation</div>
      )}
      <div className={styles.paymentWrapper}>
        {!isMobile && status === 'REQUIRES_ACTION' && (
          <div>Pending your confirmation</div>
        )}
        {bankAccount && (
          <>
            <img className={styles.cardImg} src={bankTransfer} alt={type} />
            <div className={styles.cardNumber}>{`****${bankAccount.last4}`}</div>
          </>
        )}
        {card && (
          <>
            {cardsCfg[card.brand] && (
              <img className={styles.cardImg} src={cardsCfg[card.brand]} alt={card.brand} />
            )}
            <div className={styles.cardNumber}>{`****${card.last4}`}</div>
            <div className={styles.expirationDate}>{`${card.expMonth}/${moment(
              card.expYear,
              'YYYY'
            ).format('YY')}`}</div>
          </>
        )}
      </div>
      {onDelete && (
        <button className={styles.remove} onClick={onDelete}>
          Remove
        </button>
      )}
    </div>
  );
};

PaymentMethod.propTypes = {
  payment: PropTypes.object.isRequired,
  className: PropTypes.string,
  onDelete: PropTypes.func,
};
export default PaymentMethod;
