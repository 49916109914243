export const popupSelector = ({ memoryData: { popup } }) => {
  const activePopup = popup.activePopupIndex;
  if (activePopup > 0)
    return {
      [activePopup]: {
        route: popup[activePopup].route,
        props: popup[activePopup].props,
      },
    };
};

export const popupsSelector = ({ memoryData: { popup } }) => {
  const { activePopupIndex, ...popups } = popup;
  const openedPopups = Object.fromEntries(
    Object.entries(popups).filter(([idx]) => idx > 0)
  );
  if (activePopupIndex > 0) return [...Object.values(openedPopups)];
};
